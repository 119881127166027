import gql from "graphql-tag";

// 패스워드 초기화 메일 발송
export const PASSWORD_CHANGE_FOR_ANON = gql`
  mutation ($account: String!, $brandName: String!) {
    passwordChangeForAnon(account: $account, brandName: $brandName)
  }
`;

// 패스워드 초기화
export const PASSWORD_CHANGE = gql`
  mutation ($key: String, $password: String!) {
    passwordChange(key: $key, password: $password)
  }
`;

export const CHANGE_EDIT_PRODUCT = gql`
  mutation ChangeEditProduct($docId: String!) {
    changeEditProduct(docId: $docId)
  }
`;

export const CANCEL_REQUEST_STATUS = gql`
  mutation CancelRequestStatus($docId: String!) {
    cancelRequestStatus(docId: $docId)
  }
`;

export const SYNC_BRAND = gql`
  mutation syncBrands {
    syncBrands
  }
`;

export const CANCEL_EDIT_STATUS = gql`
  mutation CancelEditStatus($docId: String!) {
    cancelEditStatus(docId: $docId)
  }
`;

export const SET_BRAND_ITEM = gql`
  mutation SetBrand(
    $brandId: Int!
    $koName: String
    $enName: String
    $cnName: String
    $jpName: String
  ) {
    setBrand(
      brandId: $brandId
      koName: $koName
      enName: $enName
      cnName: $cnName
      jpName: $jpName
    )
  }
`;

export const DELETE_BRAND_ITEM = gql`
  mutation DeleteBrand($brandId: Int!) {
    deleteBrand(brandId: $brandId)
  }
`;

export const SYNC_MAINGROUP = gql`
  mutation syncMainGroups {
    syncMainGroups
  }
`;

export const SET_MAINGROUP_GOODS = gql`
  mutation uploadGoodsAtMainGroupBatch($data: [MainGroupGoods!]!) {
    uploadGoodsAtMainGroupBatch(data: $data)
  }
`;

export const SET_CATEGORY_GOODS = gql`
  mutation uploadGoodsAtCategoryBatch($data: [CategoryGoods!]!) {
    uploadGoodsAtCategoryBatch(data: $data)
  }
`;

export const SET_MAINGROUP_ITEM = gql`
  mutation SetMainGroup(
    $mainGroupId: Int!
    $koName: String
    $enName: String
    $cnName: String
    $jpName: String
  ) {
    setMainGroup(
      mainGroupId: $mainGroupId
      koName: $koName
      enName: $enName
      cnName: $cnName
      jpName: $jpName
    )
  }
`;

export const DELETE_MAINGROUP_ITEM = gql`
  mutation DeleteMainGroup($mainGroupId: Int!) {
    deleteMainGroup(mainGroupId: $mainGroupId)
  }
`;

export const REQUEST_PROD_ITEM = gql`
  mutation requestProduct($docId: String!, $lang: String!) {
    requestProduct(docId: $docId, lang: $lang)
  }
`;

// 제품 항목 삭제하기
export const DELETE_PROD_ITEM = gql`
  mutation DeleteProduct($docId: String!) {
    deleteProduct(docId: $docId)
  }
`;

export const SET_PROD_SENTENCE = gql`
  mutation SetProductSentence(
    $beforeSentence: String!
    $afterSentence: String!
  ) {
    setProductSentence(
      beforeSentence: $beforeSentence
      afterSentence: $afterSentence
    )
  }
`;

export const SET_OVERWRITE_PROD_ITEM = gql`
  mutation SetOverwriteProduct(
    $docId: String!
    $standardLang: String!
    $targetLangs: [String!]
    $isOverwrite: Boolean!
  ) {
    setOverwriteProduct(
      docId: $docId
      standardLang: $standardLang
      targetLangs: $targetLangs
      isOverwrite: $isOverwrite
    )
  }
`;

export const UPLOAD_GODO_GOODS_IMAGE = gql`
  mutation UploadGodoGoodsImage(
    $goodsNo: Float!
    $detailKey: String!
    $listKey: String!
    $mainKey: String!
  ) {
    uploadGodoGoodsImage(
      goodsNo: $goodsNo
      detailKey: $detailKey
      listKey: $listKey
      mainKey: $mainKey
    )
  }
`;

export const CREATE_TRANSLATE = gql`
  mutation CreateTranslate($docId: String!) {
    createTranslate(docId: $docId)
  }
`;

export const SEND_MAIL_PRODUCT = gql`
  mutation SendMailProduct($docId: String!, $lang: String!) {
    sendMailProduct(docId: $docId, lang: $lang)
  }
`;

// 혜택 부담도 항목 설정하기
export const SET_BENEFIT_BURDEN_ITEM = gql`
  mutation SetBenefitBurdenItem($name: String!, $burden: Int!) {
    setBenefitBurdenItem(name: $name, burden: $burden)
  }
`;
// 쿠폰 부담도 항목 설정하기
export const SET_COUPON_BURDEN_ITEM = gql`
  mutation SetCouponBurdenItem($sno: Int!, $burden: Int!) {
    setCouponBurdenItem(sno: $sno, burden: $burden)
  }
`;

// 회원가입
export const CREATE_USER = gql`
  mutation createUser(
    $user: UserInput!
    $brand: CreateBrandInput!
    $file: UserFileInput
  ) {
    createUser(user: $user, brand: $brand, file: $file)
  }
`;

// 유저정보 업데이트
export const UPDATE_USER = gql`
  mutation updateUser($id: Float, $user: UserUpdateInput!) {
    updateUser(id: $id, user: $user)
  }
`;

// 알림 읽음 처리
export const READ_NOTIFICATION = gql`
  mutation ($type: String) {
    readNotification(type: $type)
  }
`;

// 파트너십 생성
export const CREATE_USER_PLAN = gql`
  mutation ($type: Float!, $isExclusive: Boolean!) {
    createUserPlan(type: $type, isExclusive: $isExclusive)
  }
`;

// 회원가입
export const SAVE_FILE = gql`
  mutation saveFile($directory: String!, $key: String!, $name: String!) {
    saveFile(directory: $directory, key: $key, name: $name)
  }
`;

// 배너 항목 데이터 생성
export const CREATE_BANNER = gql`
  mutation (
    $type: Int
    $language: Int
    $text: String
    $imageKeys: ImageKeys
    $startDate: DateTime
    $endDate: DateTime
    $href: String
    $selectedProductsId: [Int!]
    $showOnlyForSelectedProducts: Boolean
  ) {
    createBanner(
      typeId: $type
      langId: $language
      text: $text
      imageKeys: $imageKeys
      startDate: $startDate
      endDate: $endDate
      href: $href
      selectedProductsId: $selectedProductsId
      showOnlyForSelectedProducts: $showOnlyForSelectedProducts
    ) {
      id
    }
  }
`;

// 배너 항목 데이터 수정
export const UPDATE_BANNER = gql`
  mutation (
    $id: Int!
    $type: Int
    $language: Int
    $text: String
    $imageKeys: ImageKeys
    $startDate: DateTime
    $endDate: DateTime
    $href: String
    $selectedProductsId: [Int!]
    $showOnlyForSelectedProducts: Boolean
  ) {
    updateBanner(
      id: $id
      typeId: $type
      langId: $language
      text: $text
      imageKeys: $imageKeys
      startDate: $startDate
      endDate: $endDate
      href: $href
      selectedProductsId: $selectedProductsId
      showOnlyForSelectedProducts: $showOnlyForSelectedProducts
    ) {
      id
    }
  }
`;

// 배너 항목 데이터 삭제
export const DELETE_BANNER = gql`
  mutation ($id: Int!) {
    deleteBanner(id: $id)
  }
`;

// 띠 배너 항목 데이터 생성
export const CREATE_BAND_BANNER = gql`
  mutation (
    $type: Int
    $language: Int
    $text: String
    $backgroundColor: String
    $closeColor: String
    $href: String
    $isBlankHref: Boolean
    $startDate: DateTime
    $endDate: DateTime
    $isClosedShowId: Int
  ) {
    createBandBanner(
      typeId: $type
      langId: $language
      text: $text
      backgroundColor: $backgroundColor
      closeColor: $closeColor
      href: $href
      isBlankHref: $isBlankHref
      startDate: $startDate
      endDate: $endDate
      isClosedShowId: $isClosedShowId
    ) {
      id
    }
  }
`;

// 띠 배너 항목 데이터 생성
export const UPDATE_BAND_BANNER = gql`
  mutation (
    $id: Int!
    $type: Int
    $language: Int
    $text: String
    $backgroundColor: String
    $closeColor: String
    $href: String
    $isBlankHref: Boolean
    $startDate: DateTime
    $endDate: DateTime
    $isClosedShowId: Int
  ) {
    updateBandBanner(
      id: $id
      typeId: $type
      langId: $language
      text: $text
      backgroundColor: $backgroundColor
      closeColor: $closeColor
      href: $href
      isBlankHref: $isBlankHref
      startDate: $startDate
      endDate: $endDate
      isClosedShowId: $isClosedShowId
    ) {
      id
    }
  }
`;

// 배너 항목 데이터 삭제
export const DELETE_BAND_BANNER = gql`
  mutation ($id: Int!) {
    deleteBandBanner(id: $id)
  }
`;

// 모델 구성 생성하기
export const CREATE_MODEL_CONFIG = gql`
  mutation createModelConfig(
    $koName: String!
    $enName: String
    $cnName: String
    $jpName: String
  ) {
    createModelConfig(
      koName: $koName
      enName: $enName
      cnName: $cnName
      jpName: $jpName
    )
  }
`;

export const DELETE_MODEL_CONFIG = gql`
  mutation deleteModelConfig($id: Int!) {
    deleteModelConfig(id: $id)
  }
`;

export const UPDATE_MODEL_CONFIG = gql`
  mutation updateModelConfig(
    $id: Int!
    $koName: String!
    $enName: String
    $cnName: String
    $jpName: String
  ) {
    updateModelConfig(
      id: $id
      koName: $koName
      enName: $enName
      cnName: $cnName
      jpName: $jpName
    )
  }
`;

// 공지사항 항목 데이터 생성
export const CREATE_NOTICE = gql`
  mutation ($type: Int, $language: Int, $title: String, $body: String) {
    createNotice(typeId: $type, langId: $language, title: $title, body: $body) {
      id
    }
  }
`;

// 공지사항 항목 데이터 삭제
export const DELETE_NOTICE = gql`
  mutation ($id: Int!) {
    deleteNotice(id: $id)
  }
`;

// 공지사항 항목 데이터 생성
export const UPDATE_NOTICE = gql`
  mutation (
    $id: Int!
    $type: Int
    $language: Int
    $title: String
    $body: String
  ) {
    updateNotice(
      id: $id
      typeId: $type
      langId: $language
      title: $title
      body: $body
    ) {
      id
    }
  }
`;

// 공지사항 항목 데이터 생성
export const CREATE_FAQ = gql`
  mutation ($type: Int, $language: Int, $title: String, $body: String) {
    createFaq(typeId: $type, langId: $language, title: $title, body: $body) {
      id
    }
  }
`;

// 공지사항 항목 데이터 삭제
export const DELETE_FAQ = gql`
  mutation ($id: Int!) {
    deleteFaq(id: $id)
  }
`;

// 공지사항 항목 데이터 생성
export const UPDATE_FAQ = gql`
  mutation (
    $id: Int!
    $type: Int
    $language: Int
    $title: String
    $body: String
  ) {
    updateFaq(
      id: $id
      typeId: $type
      langId: $language
      title: $title
      body: $body
    ) {
      id
    }
  }
`;

// 공지사항 항목 데이터 생성
export const CREATE_POPUP = gql`
  mutation (
    $language: Int
    $title: String
    $body: String
    $startDate: DateTime
    $endDate: DateTime
    $location: [String!]
    $allowHide: Boolean
  ) {
    createPopup(
      langId: $language
      title: $title
      body: $body
      startDate: $startDate
      endDate: $endDate
      location: $location
      allowHide: $allowHide
    ) {
      id
    }
  }
`;

// 공지사항 항목 데이터 삭제
export const DELETE_POPUP = gql`
  mutation ($id: Int!) {
    deletePopup(id: $id)
  }
`;

// 공지사항 항목 데이터 생성
export const UPDATE_POPUP = gql`
  mutation (
    $id: Int!
    $language: Int
    $title: String
    $body: String
    $startDate: DateTime
    $endDate: DateTime
    $location: [String!]
    $allowHide: Boolean
  ) {
    updatePopup(
      id: $id
      langId: $language
      title: $title
      body: $body
      startDate: $startDate
      endDate: $endDate
      location: $location
      allowHide: $allowHide
    ) {
      id
    }
  }
`;

// 공지사항 항목 고정글 설정
export const FIX_NOTICE = gql`
  mutation ($id: Int!, $fixed: Int) {
    fixNotice(id: $id, fixed: $fixed) {
      id
      fixed
    }
  }
`;

// Faq 항목 고정글 설정
export const FIX_FAQ = gql`
  mutation ($id: Int!, $fixed: Int) {
    fixFaq(id: $id, fixed: $fixed) {
      id
      fixed
    }
  }
`;

// 에이콘 FAQ 항목 데이터 생성
export const CREATE_FAQ_ACON = gql`
  mutation (
    $typeId: Int!
    $lang: String!
    $title: String!
    $subtitle: String!
    $body: String
  ) {
    createFaqAcon(
      typeId: $typeId
      lang: $lang
      title: $title
      subtitle: $subtitle
      body: $body
    ) {
      id
    }
  }
`;

// 에이콘 FAQ 항목 데이터 생성
export const CREATE_FAQ_CREATOR = gql`
  mutation (
    $typeId: Int!
    $lang: String!
    $title: String!
    $subtitle: String!
    $body: String
  ) {
    createFaqCreator(
      typeId: $typeId
      lang: $lang
      title: $title
      subtitle: $subtitle
      body: $body
    ) {
      id
    }
  }
`;

// 에이콘 FAQ 항목 데이터 삭제
export const DELETE_FAQ_ACON = gql`
  mutation ($id: Int!) {
    deleteFaqAcon(id: $id)
  }
`;

// 에이콘 FAQ 항목 데이터 삭제
export const DELETE_FAQ_CREATOR = gql`
  mutation ($id: Int!) {
    deleteFaqCreator(id: $id)
  }
`;

export const UPDATE_FAQ_ACON = gql`
  mutation (
    $id: Int!
    $typeId: Int!
    $lang: String!
    $title: String!
    $subtitle: String!
    $body: String
  ) {
    updateFaqAcon(
      id: $id
      typeId: $typeId
      lang: $lang
      title: $title
      subtitle: $subtitle
      body: $body
    ) {
      id
    }
  }
`;

export const UPDATE_FAQ_CREATOR = gql`
  mutation (
    $id: Int!
    $typeId: Int!
    $lang: String!
    $title: String!
    $subtitle: String!
    $body: String
  ) {
    updateFaqCreator(
      id: $id
      typeId: $typeId
      lang: $lang
      title: $title
      subtitle: $subtitle
      body: $body
    ) {
      id
    }
  }
`;

// 에이콘 FAQ 순서 바꾸기
export const REORDER_FAQ_ACON = gql`
  mutation (
    $id: Int!
    $typeId: Int
    $lang: String
    $oldOrder: Int!
    $newOrder: Int!
  ) {
    reorderFaqAcon(
      id: $id
      typeId: $typeId
      lang: $lang
      oldOrder: $oldOrder
      newOrder: $newOrder
    ) {
      id
    }
  }
`;

// 에이콘 FAQ 순서 바꾸기
export const REORDER_FAQ_CREATOR = gql`
  mutation (
    $id: Int!
    $typeId: Int
    $lang: String
    $oldOrder: Int!
    $newOrder: Int!
  ) {
    reorderFaqCreator(
      id: $id
      typeId: $typeId
      lang: $lang
      oldOrder: $oldOrder
      newOrder: $newOrder
    ) {
      id
    }
  }
`;

// 프로모션 항목을 저장하는 쿼리입니다.
export const SAVE_PROMOTION = gql`
  mutation (
    $id: Int
    $type: String
    $godoGoodsNo: Int!
    $isAutoCreateReviewBenefit: Boolean
    $reserveDate: DateTime!
    $originImgKeys: [String!]!
    $imgCropInfos: [ImgCropType!]!
    $reserveOriginImgKey: String!
    $reserveImgCropInfo: ImgCropType!
    $languages: [String!]!
    $badgeList: [Badge!]!
  ) {
    savePromotion(
      id: $id
      type: $type
      godoGoodsNo: $godoGoodsNo
      isAutoCreateReviewBenefit: $isAutoCreateReviewBenefit
      reserveDate: $reserveDate
      originImgKeys: $originImgKeys
      reserveOriginImgKey: $reserveOriginImgKey
      reserveImgCropInfo: $reserveImgCropInfo
      imgCropInfos: $imgCropInfos
      languages: $languages
      badgeList: $badgeList
    ) {
      id
    }
  }
`;

// 프로모션 항목을 삭제하는 쿼리입니다.
export const REMOVE_PROMOTION = gql`
  mutation ($ids: [Int!]!) {
    removePromotions(ids: $ids)
  }
`;

export const DELETE_BENEFIT_LIMIT_ITEM = gql`
  mutation ($id: [Int!]!) {
    deletePointLimitPolicy(id: $id)
  }
`;
export const CREATE_BENEFIT_LIMIT_ITEM = gql`
  mutation (
    $type: String!
    $startDate: DateTime!
    $endDate: DateTime
    $brandId: Int
    $categoryId: Int
    $goodsNo: [String!]
  ) {
    createPointLimitPolicy(
      type: $type
      startDate: $startDate
      endDate: $endDate
      brandId: $brandId
      categoryId: $categoryId
      goodsNo: $goodsNo
    ) {
      id
    }
  }
`;

// 포인트 혜택 항목 데이터 생성
export const CREATE_POINT_BENEFIT = gql`
  mutation (
    $langId: Int
    $typeId: Int
    $pointUnit: String
    $payPoint: String
    $applyTypeId: Int
    $applyList: [String!]
    $startDate: DateTime
    $endDate: DateTime
    $quantity: String
    $description: String
    $defaultApply: Boolean
  ) {
    createPointBenefit(
      langId: $langId
      typeId: $typeId
      pointUnit: $pointUnit
      payPoint: $payPoint
      applyTypeId: $applyTypeId
      applyList: $applyList
      startDate: $startDate
      endDate: $endDate
      quantity: $quantity
      description: $description
      defaultApply: $defaultApply
    ) {
      id
    }
  }
`;

// 포인트 혜택 항목 데이터 저장
export const UPDATE_POINT_BENEFIT = gql`
  mutation (
    $id: Int!
    $langId: Int
    $typeId: Int
    $pointUnit: String
    $payPoint: String
    $applyTypeId: Int
    $applyList: [String!]
    $startDate: DateTime
    $endDate: DateTime
    $quantity: String
    $description: String
    $defaultApply: Boolean
    $koPointValue: String
    $enPointValue: String
    $cnPointValue: String
    $jpPointValue: String
  ) {
    updatePointBenefit(
      id: $id
      langId: $langId
      typeId: $typeId
      pointUnit: $pointUnit
      payPoint: $payPoint
      applyTypeId: $applyTypeId
      applyList: $applyList
      startDate: $startDate
      endDate: $endDate
      quantity: $quantity
      description: $description
      defaultApply: $defaultApply
      koPointValue: $koPointValue
      enPointValue: $enPointValue
      cnPointValue: $cnPointValue
      jpPointValue: $jpPointValue
    )
  }
`;

// 포인트 혜택 항목 데이터 삭제
export const DELETE_POINT_BENEFIT = gql`
  mutation ($id: Int!) {
    deletePointBenefit(id: $id)
  }
`;

// 쿠폰 혜택 항목 데이터 생성
export const CREATE_COUPON_BENEFIT = gql`
  mutation (
    $langId: Int
    $typeId: Int
    $godoCouponNo: String
    $applyTypeId: Int
    $applyList: [String!]
  ) {
    createCouponBenefit(
      langId: $langId
      typeId: $typeId
      godoCouponNo: $godoCouponNo
      applyTypeId: $applyTypeId
      applyList: $applyList
    ) {
      id
    }
  }
`;

// 쿠폰 혜택 항목 데이터 저장
export const UPDATE_COUPON_BENEFIT = gql`
  mutation (
    $id: Int!
    $langId: Int
    $typeId: Int
    $godoCouponNo: String
    $applyTypeId: Int
    $applyList: [String!]
  ) {
    updateCouponBenefit(
      id: $id
      langId: $langId
      typeId: $typeId
      godoCouponNo: $godoCouponNo
      applyTypeId: $applyTypeId
      applyList: $applyList
    ) {
      id
    }
  }
`;

// 쿠폰 혜택 항목 데이터 삭제
export const DELETE_COUPON_BENEFIT = gql`
  mutation ($id: Int!) {
    deleteCouponBenefit(id: $id)
  }
`;

// 혜택관 항목 생성
export const CREATE_BENEFIT_BANNER = gql`
  mutation (
    $langId: Int!
    $title: String!
    $conditionFirst: String
    $conditionSecond: String
    $couponNo: Int!
    $startDate: DateTime
    $endDate: DateTime
    $imageKey: ImageKey
  ) {
    createItem(
      langId: $langId
      title: $title
      conditionFirst: $conditionFirst
      conditionSecond: $conditionSecond
      couponNo: $couponNo
      startDate: $startDate
      endDate: $endDate
      imageKey: $imageKey
    ) {
      id
    }
  }
`;

// 혜택관 항목 저장
export const UPDATE_BENEFIT_BANNER = gql`
  mutation (
    $id: Int!
    $langId: Int!
    $title: String!
    $conditionFirst: String
    $conditionSecond: String
    $couponNo: Int!
    $startDate: DateTime
    $endDate: DateTime
    $imageKey: ImageKey
  ) {
    updateItem(
      id: $id
      langId: $langId
      title: $title
      conditionFirst: $conditionFirst
      conditionSecond: $conditionSecond
      couponNo: $couponNo
      startDate: $startDate
      endDate: $endDate
      imageKey: $imageKey
    )
  }
`;

// 혜택관 항목 삭제
export const DELETE_BENEFIT_BANNER = gql`
  mutation ($id: Int!) {
    deleteItem(id: $id)
  }
`;

// 이벤트 배너 생성
export const CREATE_EVENT_BANNER = gql`
  mutation (
    $langId: Int!
    $bannerTextFirst: String!
    $bannerTextSecond: String!
    $bannerTextThird: String!
    $settingLink: String!
    $imageKey: ImageKeys
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    createEventBanner(
      langId: $langId
      bannerTextFirst: $bannerTextFirst
      bannerTextSecond: $bannerTextSecond
      bannerTextThird: $bannerTextThird
      settingLink: $settingLink
      imageKey: $imageKey
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

// 이벤트 배너 수정
export const UPDATE_EVENT_BANNER = gql`
  mutation (
    $id: Int
    $langId: Int!
    $bannerTextFirst: String!
    $bannerTextSecond: String!
    $bannerTextThird: String!
    $settingLink: String!
    $imageKey: ImageKeys
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    updateEventBanner(
      id: $id
      langId: $langId
      bannerTextFirst: $bannerTextFirst
      bannerTextSecond: $bannerTextSecond
      bannerTextThird: $bannerTextThird
      settingLink: $settingLink
      imageKey: $imageKey
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

// 이벤트 배너 삭제
export const DELETE_EVENT_BANNER = gql`
  mutation ($id: Int!) {
    deleteEventBanner(id: $id)
  }
`;

// 이벤트 배너 순서 바꾸기
export const REORDER_EVENT_BANNER = gql`
  mutation ($id: Int!, $oldOrder: Int!, $newOrder: Int!) {
    reorderEventBanner(id: $id, oldOrder: $oldOrder, newOrder: $newOrder)
  }
`;

// 상품 배지 항목 수정
export const UPDATE_PRODUCT_BADGE = gql`
  mutation (
    $id: Float!
    $texts: [BadgeI18nInput!]!
    $startDate: DateTime!
    $endDate: DateTime!
    $godoGoodsNos: [String!]!
  ) {
    updateBadge(
      id: $id
      texts: $texts
      startDate: $startDate
      endDate: $endDate
      godoGoodsNos: $godoGoodsNos
    )
  }
`;

// 상품 배지 항목 생성
export const CREATE_PRODUCT_BADGE = gql`
  mutation (
    $texts: [BadgeI18nInput!]!
    $startDate: DateTime!
    $endDate: DateTime!
    $godoGoodsNos: [String!]!
  ) {
    createBadge(
      texts: $texts
      startDate: $startDate
      endDate: $endDate
      godoGoodsNos: $godoGoodsNos
    )
  }
`;

// 상품 배지 항목 수정
export const DELETE_PRODUCT_BADGE = gql`
  mutation ($id: Float!) {
    deleteBadge(id: $id)
  }
`;

// 다운로드 횟수 초기화
export const INCREASE_DOWNLOAD_COUNT = gql`
  mutation ($id: Int!) {
    increaseDownloadCount(id: $id) {
      id
      userType
      givenCount
      logs {
        userNo
        userId
        orderNo
        goodsNo
        requestIp
        created
      }
    }
  }
`;

export const SEND_AUTHENTICATION_CODE = gql`
  mutation ($email: String!, $lang: String!) {
    sendAuthenticationCode(email: $email, lang: $lang)
  }
`;

export const CHECK_AUTHENTICATION_CODE = gql`
  mutation ($email: String!, $code: String!) {
    authenticate(email: $email, code: $code)
  }
`;

// 유저정보 업데이트 - 글로벌
export const UPDATE_USER_V2 = gql`
  mutation updateUserV2($id: Float, $user: UserUpdateInput!) {
    updateUserV2(id: $id, user: $user)
  }
`;

export const UPDATE_EXTENSION_CONFIG = gql`
  mutation UpdateExtensionConfig(
    $UpdateExtensionConfigInput: UpdateExtensionConfigInput!
  ) {
    updateExtensionConfig(input: $UpdateExtensionConfigInput) {
      id
    }
  }
`;

export const CREATE_EXTENSION_CONFIG = gql`
  mutation CreateExtensionConfig(
    $CreateExtensionConfigInput: CreateExtensionConfigInput!
  ) {
    createExtensionConfig(input: $CreateExtensionConfigInput) {
      id
      name
    }
  }
`;

export const CREATE_APPLICATION = gql`
  mutation CreateApplication($CreateApplicationInput: CreateApplicationInput!) {
    createApplication(input: $CreateApplicationInput) {
      id
      name
    }
  }
`;

export const UPDATE_APPLICATION = gql`
  mutation UpdateApplication($UpdateApplicationInput: UpdateApplicationInput!) {
    updateApplication(input: $UpdateApplicationInput)
  }
`;

export const SAVE_PRODUCT_V3 = gql`
  mutation SaveProductV3(
    $docId: String
    $lang: String!
    $isOpen: Boolean!
    $title: String!
    $contents: String
    $author: Float
    $description: String
    $rejectComment: String
    $editMessage: String
    $editComment: String
    $mainImageKey: String
    $mainImageName: String
    $mainImageCropInfo: CropType
    $thumbnailImageKey: String
    $thumbnailImageName: String
    $thumbnailImageCropInfo: CropType
    $subImage1Key: String
    $subImage1Name: String
    $subImage1CropInfo: CropType
    $subImage2Key: String
    $subImage2Name: String
    $subImage2CropInfo: CropType
    $subImage3Key: String
    $subImage3Name: String
    $subImage3CropInfo: CropType
    $modelFileName: String
    $modelFileId: Int
    $modelKey: String
    $modelName: String
    $godoGoodsNo: Float
    $price: Float
    $salePrice: Float
    $commission: Float
    $categories: [Int!]
    $modelConfig: [ModelConfigItem!]
    $dynamicItem: String
    $copyright: String
    $extension: [Float!]
    $applications: [Int!]
    $fileConfig: [FileConfig!]
    $updateHistory: [UpdateHistory!]
    $keywords: String
    $related: [Float!]
  ) {
    saveProduct: saveProductV3(
      docId: $docId
      lang: $lang
      isOpen: $isOpen
      title: $title
      contents: $contents
      author: $author
      description: $description
      rejectComment: $rejectComment
      editMessage: $editMessage
      editComment: $editComment

      mainImageKey: $mainImageKey
      mainImageName: $mainImageName
      mainImageCropInfo: $mainImageCropInfo

      thumbnailImageKey: $thumbnailImageKey
      thumbnailImageName: $thumbnailImageName
      thumbnailImageCropInfo: $thumbnailImageCropInfo

      subImage1Key: $subImage1Key
      subImage1Name: $subImage1Name
      subImage1CropInfo: $subImage1CropInfo

      subImage2Key: $subImage2Key
      subImage2Name: $subImage2Name
      subImage2CropInfo: $subImage2CropInfo

      subImage3Key: $subImage3Key
      subImage3Name: $subImage3Name
      subImage3CropInfo: $subImage3CropInfo

      modelFileName: $modelFileName
      modelFileId: $modelFileId

      modelKey: $modelKey
      modelName: $modelName

      godoGoodsNo: $godoGoodsNo
      price: $price
      salePrice: $salePrice
      commission: $commission
      categories: $categories
      modelConfig: $modelConfig
      dynamicItem: $dynamicItem
      copyright: $copyright
      extension: $extension
      applications: $applications
      fileConfig: $fileConfig
      updateHistory: $updateHistory
      keywords: $keywords
      related: $related
    ) {
      id
      docId
      status
    }
  }
`;

export const CHANGE_PROD_STATUS_V2 = gql`
  mutation ChangeProductStatusV2(
    $docId: String!
    $lang: String!
    $isOpen: Boolean!
    $status: String!
    $message: String
  ) {
    changeProductStatusV2(
      docId: $docId
      lang: $lang
      isOpen: $isOpen
      status: $status
      message: $message
    )
  }
`;
