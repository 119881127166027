import dateFormat from 'dateformat';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from 'rsuite';
import { ListItem, NameField, NameInputField, DateField, Button } from '../style';

export default (({ item, setItem, onClick }) => {
    const nameTag = useRef(null);
    
    // 이름 
    const [name, setName] = useState('');

    // 편집모드 여부 
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (isEdit) {
            // 아이템 명 name 으로 지정 
            setName(item.name);

            // 이름 태그 포커싱 
            nameTag && nameTag.current && nameTag.current.focus();
        }
    }, [isEdit]);

    // 수정 버튼 클릭 이벤트 처리기 메소드 
    const onClickEdit = () => {
        // 편집모드 on 
        setIsEdit(true);
    };

    // 저장 버튼 클릭 이벤트 처리기 메소드 
    const onClickSave = () => {
        // 항목 설정하기 
        setItem(item, name);
        // 편집모드 on 
        setIsEdit(false);
    };

    return (
        <ListItem key={item.name}>
            <DateField>{dateFormat(item.date, 'yyyy-mm-dd')}</DateField>
            {!isEdit &&
                <>
                    <NameField>{item.name}</NameField>
                </>
            }

            {isEdit &&
                <>
                    <NameInputField ref={nameTag} value={name} onChange={e => setName(e.target.value)} />
                </>
            }

            {!isEdit && <Button onClick={onClickEdit}>
                <Icon icon="edit" />
            </Button>}

            {isEdit && <Button
                disabled={!name}
                onClick={onClickSave}>
                <Icon icon="save" />
            </Button>}

            <Button onClick={() => {
                isEdit ? setIsEdit(false) : onClick(item);
            }}>
                <Icon icon="close" />
            </Button>
        </ListItem>
    );
});