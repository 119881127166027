import { selector } from "recoil";
import { newStatusState } from "..";
import { ProductNewStatusType } from "../types";

const newStatusSelector = selector<ProductNewStatusType>({
  key: 'newStatusSelector',
  get: ({ get }) => get(newStatusState),
  set: ({ set }, val) => set(newStatusState, val),
});

export default newStatusSelector;
