import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';
import { Wrap, CheckboxText, Div, RequireText, Question, Ul, Li } from './style';
import { Radio, RadioGroup, Checkbox } from 'rsuite';
import { Input } from '../../style';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { copyrightSelector, displaySelector } from 'boards/DetailBoardWrite/recoil/selectors';

const Styled = {
    ValidationText: styled.div`
        font-size: 12px; 
        margin-top: 8px;
        color: rgb(243, 0, 186); 
        font-weight: 400; 
        line-height: 100%;
    `
}

interface Props {
    inputs?: { 
        warehouseInput: any;
        externalFreeSourceInput: any;
        externalPaySourceInput: any;
        agreeInput : any;
    }
}

export default (({ inputs }: Props) => {
    const { t } = useTranslation();
    const [display] = useRecoilState(displaySelector);
    const [copyright, setCopyright] = useRecoilState(copyrightSelector);

    useEffect(() => {
        if (copyright.copyright['3dWarehouse'] === 'off' &&
        copyright.copyright['freeExtraSource'] === 'off' &&
        copyright.copyright['payExtraSource'] === 'off') {
            setCopyright({
                ...copyright,
                copyright: {
                    ...copyright.copyright,
                    'diy': 'on',
                },
            })

        }
    }, [copyright.copyright['3dWarehouse'], copyright.copyright['freeExtraSource'], copyright.copyright['payExtraSource']]);

    return (
        <Wrap isDisp={display.isDisp}>
            {/* 본 모델의 소스는 직접 제작 하였습니다. ( 질문지 1번항목 )*/}
            <Ul>
                {/* 질문지 1번항목 - 제목 */}
                <Question>
                    {parse(t('diyText'))}
                </Question>
                {/* 질문지 1번항목 - 답변 */}
                <Question>
                    <RadioGroup
                        inline
                        name="diy"
                        value={copyright.copyright.diy}
                        onChange={value => {
                            setCopyright({
                                ...copyright,
                                copyright: {
                                    ...copyright.copyright,
                                    'diy': value,
                                    ...(value === 'off' && ({
                                        '3dWarehouse': 'on',
                                        '3dWarehouseList': '',
                                        'freeExtraSource': 'on',
                                        'freeExtraSourceList': '',
                                        'payExtraSource': 'on',
                                        'payExtraSourceList': '',
                                    }))
                                },
                            });
                        }}
                    >
                        <Radio value="on">{t("Yes.label")}</Radio>
                        <Radio value="off">{t("No.label")}</Radio>
                    </RadioGroup>
                </Question>

                <Li className={copyright.copyright.diy !== 'on' ? '' : 'hide'}>
                    {/* 3D웨어하우스 소스를 사용하였습니다.(질문지 1-1번 항목) */}
                    <Ul>
                        {/* 질문지 1-1번 항목 - 질문 */}
                        <Question>
                            {t("IUsed3DWarehouseSource.label")}
                        </Question>
                        {/* 질문지 1-1번 항목 - 답변 */}
                        <Question>
                            <RadioGroup
                                inline
                                name="3dWarehouse"
                                value={copyright.copyright['3dWarehouse']}
                                onChange={value => {
                                    setCopyright({
                                        ...copyright,
                                        copyright: {
                                            ...copyright.copyright,
                                            '3dWarehouse': value,
                                        },
                                    });
                                }}
                            >
                                <Radio value="on">{t("Yes.label")}</Radio>
                                <Radio value="off">{t("No.label")}</Radio>
                            </RadioGroup>
                        </Question>
                        <Li className={(copyright.copyright['3dWarehouse'] === 'on' ? '' : 'hide')}>
                            {/* 질문지 1-1-1번 항목 - 답변 */}
                            <Input
                                ref={inputs?.warehouseInput}
                                onChange={e => {
                                    const isError = e.target.value ? false : true;
                                    setCopyright({
                                        ...copyright,
                                        isErrorWarehouseCopyright: isError,
                                        copyright: {
                                            ...copyright.copyright,
                                            '3dWarehouseList': e.target.value,
                                        },
                                    });
                                }}
                                value={copyright.copyright['3dWarehouseList']}
                                placeholder={t("3dwarehouseListPlaceholder")}
                            />
                            {copyright.isErrorWarehouseCopyright && <Styled.ValidationText>{t('pleaseEnterOtherSource')}</Styled.ValidationText>}
                        </Li>
                    </Ul>

                    {/* 상업적으로 이용이 가능한 타 무료 외부 소스를 사용하였습니다. (질문지 1-2번 항목) */}
                    <Ul>
                        {/* 질문지 1-2번 항목 - 질문 */}
                        <Question>{parse(t('FreeExtraSourceText'))}</Question>
                        {/* 질문지 1-2번 항목 - 답변 */}
                        <Question>
                            <RadioGroup
                                inline
                                name="freeExtraSource"
                                value={copyright.copyright.freeExtraSource}
                                onChange={value => {
                                    setCopyright({
                                        ...copyright,
                                        copyright: {
                                            ...copyright.copyright,
                                            freeExtraSource: value,
                                        }
                                    });
                                }}
                            >
                                <Radio value="on">{t("Yes.label")}</Radio>
                                <Radio value="off">{t("No.label")}</Radio>
                            </RadioGroup>
                        </Question>
                        <Li className={(copyright.copyright['freeExtraSource'] === 'on' ? '' : 'hide')}>
                            {/* 질문지 1-2-1번 항목 */}
                            <Ul>
                                {/* 질문지 1-2-1번 항목 - 질문 */}
                                <Question>{parse(t('ExternalSourceList.label'))}</Question>
                                {/* 질문지 1-2-1번 항목 - 답변 */}
                                <Question>
                                    <Input
                                        ref={inputs?.externalFreeSourceInput}
                                        name="freeExtraSourceList"
                                        onChange={e => {
                                            const isError = e.target.value ? false : true;
                                            setCopyright({
                                                ...copyright,
                                                isErrorFreeCopyright: isError,
                                                copyright: {
                                                    ...copyright.copyright,
                                                    freeExtraSourceList: e.target.value,
                                                }
                                            });
                                        }}
                                        value={copyright.copyright['freeExtraSourceList']}
                                        placeholder={t("freeExtraSourcePlaceholder")}
                                    />
                                    {copyright.isErrorFreeCopyright && <Styled.ValidationText>{t('pleaseEnterOtherSource')}</Styled.ValidationText>}
                                </Question>
                            </Ul>
                        </Li>
                    </Ul>

                    {/* 상업적으로 이용이 가능한 타 무료 외부 소스를 사용하였습니다. (질문지 1-3번 항목) */}
                    <Ul>
                        {/* 질문지 1-3번 항목 - 질문 */}
                        <Question>{parse(t('PayExtraSourceText'))}</Question>
                        {/* 질문지 1-3번 항목 - 답변 */}
                        <Question>
                            <RadioGroup
                                inline
                                name="payExtraSource"
                                value={copyright.copyright.payExtraSource}
                                onChange={value => {
                                    setCopyright({
                                        ...copyright,
                                        copyright: {
                                            ...copyright.copyright,
                                            payExtraSource: value,
                                        }
                                    });
                                }}
                            >
                                <Radio value="on">{t("Yes.label")}</Radio>
                                <Radio value="off">{t("No.label")}</Radio>
                            </RadioGroup>
                        </Question>
                        <Li className={(copyright.copyright['payExtraSource'] === 'on' ? '' : 'hide')}>
                            {/* 질문지 1-3-1번 항목 */}
                            <Ul>
                                {/* 질문지 1-3-1번 항목 - 질문 */}
                                <Question>
                                    {parse(t('ExternalSourceList.label'))}
                                </Question>
                                {/* 질문지 1-3-1번 항목 - 답변 */}
                                <Question>
                                    <Input
                                        ref={inputs?.externalPaySourceInput}
                                        onChange={e => {
                                            const isError = e.target.value ? false : true;
                                            setCopyright({
                                                ...copyright,
                                                isErrorPayCopyright: isError,
                                                copyright: {
                                                    ...copyright.copyright,
                                                    payExtraSourceList: e.target.value,
                                                }
                                            });
                                        }}
                                        value={copyright.copyright['payExtraSourceList']}
                                        placeholder={t("payExtraSourcePlaceholder")}
                                    />
                                    {copyright.isErrorPayCopyright && <Styled.ValidationText>{t('pleaseEnterOtherSource')}</Styled.ValidationText>}
                                </Question>
                            </Ul>
                        </Li>
                    </Ul>
                </Li>
            </Ul>
            <Div>
                {/* 기존에 on / off로 되어있어 true false로 하기위해서는 마이그레이션 필요. TODO*/}
                <Checkbox
                    inputRef={inputs?.agreeInput}
                    checked={copyright.copyright.agreement === 'on'}
                    onChange={(value, checked) => {
                        setCopyright({
                            ...copyright,
                            isErrorAgree: !checked,
                            copyright: {
                                ...copyright.copyright,
                                agreement: checked ? 'on' : 'off'
                            }
                        });
                    }}
                >
                    <div>
                        <RequireText>
                            ({t('require')})
                        </RequireText>
                        <CheckboxText>
                            {t("agreeAllCopyright")}
                        </CheckboxText>
                    </div>
                </Checkbox>
                {copyright.isErrorAgree && <Styled.ValidationText>{t('checkCopyright')}</Styled.ValidationText>}
            </Div>
        </Wrap>
    );
});