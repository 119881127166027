import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Item, Title, Value } from "../../style";
import RcSelect from "react-select";
import { UnderButton } from "boards/DetailBoardWrite/component/form";
import Modal from "./modal";
import { GET_MODEL_CONFIG_ALL } from "api/quries";
import { useQuery } from "@apollo/client";
import { useRecoilState } from "recoil";
import { modelSelector } from "boards/DetailBoardWrite/recoil/selectors";

export default () => {
  // 번역도구
  const { t } = useTranslation();
  // 모달 표시여부
  const [isShowModal, setIsShowModal] = useState(false);
  // 상태
  const [model, setModel] = useRecoilState(modelSelector);

  // 현재 항목 데이터
  const { data, loading, refetch } = useQuery(GET_MODEL_CONFIG_ALL);

  // 모델 구성 항목들
  const modelConfigItems = (() => {
    if (loading) return [];

    return (
      data &&
      data.getModelConfigs.map((x) => {
        const item = x.i18n.find((y) => y.language.code === "ko");
        return {
          value: x.id,
          label: item?.name,
        };
      })
    );
  })();

  // 리스트 추가하기 버튼 클릭 이벤트 처리기 메소드
  const onClickAddListButtonTag = () => {
    // 모달창 표시하기
    setIsShowModal(true);
  };

  // 모델구성 select 태그 변경 이벤트 처리기 메소드
  const onChange = (itemObjArr) => {
    // 카테고리 저장
    setModel({ ...model, modelConfig: itemObjArr ? itemObjArr : [] });
  };

  return (
    <>
      <Item>
        <Modal
          items={data && data.getModelConfigs}
          show={isShowModal}
          onClose={() => {
            setIsShowModal(false);
          }}
          refetch={refetch}
        />
        <Title admin={true}>
          <span>{t("goods.modelConfigHistory")}</span>
          <UnderButton onClick={onClickAddListButtonTag}>
            {t("addListItem")}
          </UnderButton>
        </Title>
        <Value>
          <RcSelect
            {...{
              placeholder: t("goods.modelConfigHistory"),
              value: modelConfigItems.filter(
                (x) =>
                  model.modelConfig.filter((y) => x.value === y.value)
                    .length > 0
              ),
              isMulti: true,
              options: modelConfigItems,
              onChange,
            }}
          />
        </Value>
      </Item>
    </>
  );
};
