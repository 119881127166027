import React from 'react';
import ImageUpload from 'boards/DetailBoardWrite/component/imageUpload';
import { useRecoilState } from 'recoil';
import { subImageSelector } from 'boards/DetailBoardWrite/recoil/selectors';

export default (({
    imgType,
    setCallback,
    cropperTag,
    image,
    setImage,
    allImageEventHandler,
    disabled,
    isShow,
    isComplete,
    setComplete,
    setCompleteCallback,
    setFileCallback,
    message,
    showMessage,
    setCroppedImageBlob,
    setCroppedImageDataUrl,
}) => {
    // 파일 개수 제한 
    const limit = 3;
    // 확장자들 
    const extensions = ['image/jpg', 'image/png', 'image/jpeg'];
    // 파일 크기 
    const fileSize = [1000, 1000];

    const [subImage, setSubImage] = useRecoilState(subImageSelector);

    return (
        <ImageUpload
            showMessage={showMessage}
            setCallback={setCallback}
            cropperTag={cropperTag}
            image={image}
            setImage={setImage}
            allImageEventHandler={allImageEventHandler}
            setCompleteCallback={setCompleteCallback}
            setCroppedImageBlob={setCroppedImageBlob}
            setCroppedImageDataUrl={setCroppedImageDataUrl}
            isShow={isShow}
            disabled={disabled}
            isComplete={isComplete}
            setComplete={setComplete}
            maxWidth={fileSize[0]}
            maxHeight={fileSize[1]}
            extensions={extensions}
            sizeLimit={10}
            targetWidth={360}
            targetHeight={360}
            boxWidth={585}
            boxHeight={300}
            limit={limit}
            cropRatio={1}
            left={subImage[`${imgType}`]?.cropInfo?.imageOriginX}
            top={subImage[`${imgType}`]?.cropInfo?.imageOriginY}
            width={subImage[`${imgType}`]?.cropInfo?.imageWidth}
            height={subImage[`${imgType}`]?.cropInfo?.imageHeight}
            url={subImage[`${imgType}`]?.imagePath}
            setCropInfo={value => {
                setSubImage({ 
                    [`${imgType}`]: { 
                        cropInfo: value 
                    } 
                });
            }}
            setImageUrl={(key, name) => {
                setSubImage({ 
                    [`${imgType}`]: {
                        imagePath: key,
                        imageName: name
                    } 
                });
            }}
            setFileCallback={setFileCallback}
        />
    );
});