import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AdminMemo,
  Author,
  Category,
  Comment,
  Commission,
  Contents,
  Copyright,
  Dynamic,
  Extension,
  FileStructure,
  FixedMenu,
  GoodsNo,
  Keywords,
  MainImage,
  Menu,
  Model,
  ModelConfigHistory,
  ModelUpdateHistory,
  Packages,
  Price,
  Progress,
  Related,
  SalePrice,
  SubImage,
  Subject,
  Version,
} from "./value";
import { Box as MuiBox } from '@mui/material';
import { Box, Field, Title, Wrap } from "./style";
import { AppContext } from "app";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  STATUS_APPROVED,
  STATUS_DRAFT,
  STATUS_INPROGRESS,
  STATUS_IN_REVIEW_ON_OPEN,
  STATUS_OPEN,
  STATUS_REQUEST,
  STATUS_REQUEST_ON_OPEN,
  TranslateModalType,
} from "./constants";
import HTMLViewer from "boards/HTMLViewer";
import Loading from "../../common/editor/Loading";
import AconHelper from "lib/global";
import { SelectLanguageModal } from "./modal";
import { useCookies } from "react-cookie";
import Application from "./value/application";
import { useRecoilState } from "recoil";
import { productAllInfoSelector } from "./recoil/selectors";
import { LanguageEnum, PackageType, ProductAllInfoType, ProductNewStatusType } from "./recoil/types";
import { New_Product_Status, useGetHubWriteProductQuery } from "generated/graphql";
import convertLanguageCode from "utils/convertLanguageCode";
import ProductType from "./value/productType";
import Funding from "./value/funding";
import { BlackButton } from "./component/form";

export default () => {
  const { userInfo, showConfirmMessage } = useContext(AppContext);
  const [productData, setProductData] = useRecoilState(productAllInfoSelector);

  const [cookie, setCookie] = useCookies();
  // 관리자 여부
  const { isAdmin, isAccept } = userInfo;

  // 번역도구
  const { t, i18n } = useTranslation();
  // URL 파생 정보
  const { type, docId, lang } = useParams<{
    type: string;
    docId: string;
    lang: LanguageEnum;
  }>();
  // history
  const history = useHistory();

  // 진행 여부
  const [isInProgress, setIsInProgress] = useState(false);

  const warehouseInput = useRef(null);
  const externalFreeSourceInput = useRef(null);
  const externalPaySourceInput = useRef(null);
  const agreeInput = useRef(null);

  // 오픈 여부
  const isOpen = type === STATUS_OPEN;

  // 현재 항목 데이터
  const { data, loading, error, refetch } = useGetHubWriteProductQuery({
    variables: {
      docId,
      languageCode: convertLanguageCode(lang),
      isOpen,
    },
    fetchPolicy: "no-cache",
    // 문서 ID 또는 언어가 지정되지 않으면 항목을 가져오지 않음
    skip: !docId || !lang,
  });
  const isLoading = loading || error;

  useEffect(() => {
    /* 유효성 검증 */
    if (!isAdmin && isOpen) {
      history.push("/"); // 사용자가 오픈된 문서에 접근했을 경우 루트 페이지로 이동
    }
    if (
      // 작가의 경우, 문서값이 존재하지 않거나 쿠키가 존재하여 해당 쿠키값이 현재 문서언어와 다를경우
      !isAdmin &&
      !docId &&
      cookie.rememberLanguage &&
      cookie.rememberLanguage !== lang
    ) {
      history.push(`/goods/write/${cookie.rememberLanguage}`);
    }
    if (!data) {
      return; // 데이터가 존재하지 않으면 종료.
    }
    if (!data.hubWriteProduct) {
      return;
    }

    const {
      content: contents,
      copyright,
      essential: { status, newStatus, isEdit, hasOpened, isModified },
      file: {
        uploadFile,
        extensions,
        applications,
        components,
        updateHistories,
      },
      image: { main, subImage1, subImage2, subImage3, thumbnail },
      info: { brandId, godoGoodsNo, editorMemo },
      keywords,
      modelInfo: { categories, attributes, dynamicComponent: dynamicItem },
      note: { rejectComment, updateRequest, updateResponse },
      package: packageInfo,
      relatedProducts,
      sale: { price, salePrice, commission },
      title,
      funding,
    } = data.hubWriteProduct;

    // 로딩/에러가 아니고, 데이터가 정상적으로 확보가 된 경우
    if (data && !loading && !error) {
      const fileConfig = [];
      if (Array.isArray(components) && components.length > 0) {
        components.forEach((t) => {
          fileConfig.push({
            id: t.fileComponentId,
            name: t.fileComponentName,
            size: t.fileComponentSize,
          });
        });
      }
      const updateHistory = [];
      if (Array.isArray(updateHistories) && updateHistories.length > 0) {
        updateHistories.forEach((t) => {
          updateHistory.push({
            id: t.id,
            name: t.updateContent,
            date: t.updateDate,
          });
        });
      }

      const setDefaultCropInfo = (image, type) => {
        if (
          image &&
          image.cropInfo &&
          !image.cropInfo?.imageOriginX &&
          !image.cropInfo?.imageOriginY &&
          !image.cropInfo?.imageWidth &&
          !image.cropInfo?.imageHeight
        ) {
          let cropInfo = {
            imageOriginX: 172,
            imageOriginY: 30,
            imageWidth: 240,
            imageHeight: 240,
          };

          if (type === "main") {
            cropInfo = {
              imageOriginX: 80,
              imageOriginY: 40,
              imageWidth: 425,
              imageHeight: 220,
            };
          }
          if (type === "thumbnail") {
            cropInfo = {
              imageOriginX: 109,
              imageOriginY: 30,
              imageWidth: 367.2,
              imageHeight: 240,
            };
          }

          return {
            imagePath: image.imagePath,
            imageName: image.imageName,
            cropInfo,
          };
        }
        return image;
      };

      const parsedCopyright = JSON.parse(copyright);

      // 확보된 데이터 state에 저장
      const newData: ProductAllInfoType = {
        lang,
        // 표시여부 (사용자의 경우, SAVED 이외에는 전부 수정 불가 )
        isDisp: !isAdmin && status !== STATUS_DRAFT,
        hasOpened,
        isModified,
        isEdit: newStatus === STATUS_REQUEST_ON_OPEN
        || newStatus === STATUS_IN_REVIEW_ON_OPEN
        || newStatus === New_Product_Status.CompleteOnOpen
        || newStatus === New_Product_Status.DraftOnOpen
        || hasOpened,
        docId,
        title,
        contents,
        ...(status && { status }),
        ...(newStatus && { newStatus: newStatus as ProductNewStatusType }),
        copyright: {
          ...parsedCopyright,
          ...(!parsedCopyright.agreement && { agreement: "on" }),
        },
        brandId,
        dynamicItem,
        ...(uploadFile?.fileId && { modelFileId: uploadFile.fileId }),
        modelName: uploadFile?.fileName || "",
        modelKey: uploadFile?.filePath || "",

        ...(!productData?.mainImage?.imagePath && {
          mainImage: setDefaultCropInfo(main, "main"),
        }),
        ...(!productData?.thumbnail?.imagePath && {
          thumbnail: setDefaultCropInfo(thumbnail, "thumbnail"),
        }),
        ...(!productData?.subImage1?.imagePath && {
          subImage1: setDefaultCropInfo(subImage1, "sub"),
        }),
        ...(!productData?.subImage2?.imagePath && {
          subImage2: setDefaultCropInfo(subImage2, "sub"),
        }),
        ...(!productData?.subImage3?.imagePath && {
          subImage3: setDefaultCropInfo(subImage3, "sub"),
        }),

        // 이미지 종료
        godoGoodsNo,
        // 반려 사유
        rejectComment,
        // 수정 사항
        updateRequest,
        // 수정 의견
        updateResponse,
        description: editorMemo,
        originGodoGoodsNo: godoGoodsNo,
        price: AconHelper.AddCommas(price || 0),
        salePrice: AconHelper.AddCommas(salePrice || 0),
        isFree: salePrice === 0,
        commission: (commission || 0).toString(),
        category: categories,
        modelConfig: attributes.map((x) => {
          return { value: x.attributeId, label: x.attributeName };
        }),
        extension: extensions,
        fileConfig,
        updateHistory,
        keywords,
        related: relatedProducts,
        ...(packageInfo && { packageInfo: packageInfo as PackageType }),
        applications, // 응용프로그램: { id: number; name: string }[] (기본값 [])
        ...(funding?.goalAmount && { funding }),
      };

      setProductData(newData);
    }
  }, [isAdmin, data, lang]);

  useEffect(() => {
    // 관리자이고, 승인된 상태인 경우
    if (isAdmin && productData.status === STATUS_APPROVED) {
      // 이동 될 경로
      const toPushUrl = `/goods/write/${lang}/${docId}`;
      // 경로 변경
      history.push(toPushUrl);
      // 종료
      return;
    }

    // 일반 사용자가 문서 상태가 요청됨 인 경우
    if (
      !isAdmin &&
      (productData.status === STATUS_REQUEST ||
        productData.status === STATUS_INPROGRESS)
    ) {
      // 안내 메세지 표시
      showConfirmMessage(
        productData.isEdit
          ? t("requestReviewCompletedModalTitle3")
          : t("requestReviewCompletedModalTitle"),
        productData.isEdit
          ? t("requestReviewCompletedModalContents3")
          : t("requestReviewCompletedModalContents"),
        () => {
          // 메인 홈으로 이동
          history.push("/");
        },
        {
          cancelText: t("close2"),
          confirmText: t("goBack"),
        }
      );
      // 종료
    }
  }, [isAdmin, productData.status]);

  const [previewModal, setPreviewModal] = useState({
    title: "",
    contents: "",
    isShow: false,
  });
  // 번역 모달 타입: 컨텐츠 번역/파일 번역
  const [translateModalType, setTranslateModalType] = useState<TranslateModalType>(TranslateModalType.CONTENT);
  // 번역 생성 모달 표시여부
  const [isShowTranslateModal, setIsShowTranslateModal] = useState(false);

  // 미리보기 모달 표시여부 설정하기 메소드
  const setIsShowPreviewModal = (isShow) => {
    setPreviewModal({
      ...previewModal,
      isShow,
    });
  };

  // 로딩중인 경우
  if (loading && !data) return <Loading empty={true} />;

  return (
    <>
      {!isAdmin &&
        !docId &&
        i18n.language !== "ko" &&
        !cookie.rememberLanguage && (
          <SelectLanguageModal setCookie={setCookie} />
        )}
      {/* HTML 뷰어  */}
      <HTMLViewer
        show={previewModal.isShow}
        title={productData.title}
        contents={productData.contents}
        close={() => {
          setIsShowPreviewModal(false);
        }}
      />

      <Wrap isDisp={productData.isDisp}>
        <Progress 
          isEdit={productData.isEdit}
          status={productData.status} 
        />

        {/* 메뉴 표시 */}
        <Menu
          {...{
            isAdmin,
            refetch,
            showPreviewModal: () => {
              setIsShowPreviewModal(true);
            },
          }}
        />

        <FixedMenu
          {...{
            refetch,
            isInProgress: isInProgress || isLoading,
            translateModalType,
            isShowTranslateModal,
            onCloseTranslateModal: () => setIsShowTranslateModal(false),
            inputs: [
              warehouseInput,
              externalFreeSourceInput,
              externalPaySourceInput,
              agreeInput,
            ],
          }}
        />

        <Field>
          {(productData.hasOpened || (
            productData.isEdit 
            && productData.status !== STATUS_REQUEST 
            && productData.status !== STATUS_INPROGRESS
            )) && isAdmin && (
            <Version />
          )}
        </Field>
        {/* 의견 */}
        <Field>
          <Comment isAdmin={isAdmin} />
        </Field>

        {isAdmin && (
          <Field>
            <Title>{t("productInfo")}</Title>
            <Box height="400">
              <Author />
              <GoodsNo />
              <AdminMemo />
            </Box>
          </Field>
        )}

        {/** 상품유형 */}
        {isAdmin && (
          <Field>
            <Title>{t("productType")}</Title>
            <Box height="140">
              <ProductType disabled={productData.status === STATUS_APPROVED} />
            </Box>
          </Field>
        )}

        {/** 펀딩 설정 */}
        {isAdmin && productData.funding && (
          <Field>
            <Title>{t("productFunding")}</Title>
            <Box height="526">
              <Funding />
            </Box>
          </Field>
        )}

        {/* 제목 필드 */}
        <Subject 
          isInProgress={isInProgress || isLoading} 
          onClickTranslateButton={() => {
            setTranslateModalType(TranslateModalType.CONTENT);
            setIsShowTranslateModal(true);
          }} 
        />

        {/* 메인 이미지 */}
        <MainImage />

        {/* 서브 이미지 */}
        <SubImage />

        {/* 본문 */}
        <Contents />

        <Field>
          <Title>{t("goods.price")}</Title>
          <Box>
            {isAdmin && <Price />}
            <SalePrice isAdmin={isAdmin} />
            {isAdmin && <Commission />}
          </Box>
        </Field>

        <Field>
          <Title>{t("goods.modelInfo")}</Title>
          <Box>
            {isAdmin && <Category />}
            {isAdmin && <ModelConfigHistory />}
            <Dynamic />
          </Box>
        </Field>

        <Field>
          <Title>{t("goods.copyright")}</Title>
          <Box>
            <Copyright
              inputs={{
                warehouseInput,
                externalFreeSourceInput,
                externalPaySourceInput,
                agreeInput,
              }}
            />
          </Box>
        </Field>

        <Field>
          <MuiBox display="flex" alignItems="center">
            
            <Title>{t("goods.fileMng")}</Title>
            <MuiBox ml="auto">
                {isAdmin && 
                    <BlackButton 
                        disabled={!docId || productData.status !== STATUS_INPROGRESS || isInProgress} 
                        onClick={() => {
                          setTranslateModalType(TranslateModalType.FILE);
                          setIsShowTranslateModal(true);
                        }}
                        style={{
                          width: '100px',
                          height: '28px',
                          fontWeight: '400',
                        }}
                    >
                        파일정보 번역
                    </BlackButton>
                }
            </MuiBox>
          </MuiBox>
          <Box>
            {/* 작품 파일 */}
            <Model
              setIsInProgress={setIsInProgress}
              isAccept={isAccept}
              isAdmin={isAdmin}
            />
            {/* 확장자 */}
            {isAdmin && <Extension />}
            {/* 응용프그램 */}
            {isAdmin && <Application />}
            {/* 파일 구성 및 용량 */}
            {isAdmin && <FileStructure />}
            {/* 업데이트 내역 */}
            {isAdmin && <ModelUpdateHistory />}
          </Box>
        </Field>

        {isAdmin && (
          <Field>
            <Title>{t("goods.related")}</Title>
            <Box>
              <Related />
            </Box>
          </Field>
        )}

        <Field>
          <Title>{t("goods.searchMng")}</Title>
          <Box>
            <Keywords />
          </Box>
        </Field>

        {isAdmin && (
          <Field>
            <Packages />
          </Field>
        )}
      </Wrap>
    </>
  );
};
