import { selector } from "recoil";
import { titleState } from "..";

const titleSelector = selector<string>({
  key: 'titleSelector',
  get:({get}) => get(titleState),
  set: ({set}, val) => set(titleState, val),
});

export default titleSelector;
