import { useQuery } from '@apollo/client';
import { GET_BRANDS } from 'api/quries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RcSelect from 'react-select';
import { Item, Title } from '../../style';
import { Value } from './style';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { brandSelector } from 'boards/DetailBoardWrite/recoil/selectors';

export default (() => {
    // 번역도구 
    const { t } = useTranslation();
    const { data, loading } = useQuery(GET_BRANDS);
    const [atom, setAtom] = useRecoilState(brandSelector);

    const onChange = (itemObj) => {
        setAtom({
            brandId: itemObj.value,
            brandName: itemObj.label,
        });
    };

    useEffect(() => {
        if (data?.getBrands) {
            // 브랜드 정보가져왔을때
            const brand = data.getBrands.find(brand => brand.id === atom.brandId);
            
            if (brand) {
                setAtom({
                    ...atom,
                    brandName: brand.name,
                });
            }
        }
    }, [data]);

    // 로딩중 여부 
    const isLoading = !(!loading && data && data.getBrands);

    return (
        <Item>
            <Title admin={true}>{t("Author.label")}</Title>
            <Value>
                {!isLoading && (() => {
                    const brandItems = data.getBrands.map(x => {
                        return {
                            value: x.id,
                            label: x.name
                        }
                    });

                    const getBrandItem = (value) => {
                        return brandItems.find(x => x.value === value);
                    };

                    return (
                        <RcSelect
                            {...{
                                placeholder: '작가',
                                value: getBrandItem(atom.brandId),
                                isMulti: false,
                                options: brandItems,
                                onChange
                            }}
                        />
                    );
                })()}
            </Value>
        </Item>
    );
});