import React, { useEffect, useRef, useState } from "react";
import { Icon } from "rsuite";
import { ListItem, NameField, Button } from "../style";
import { NameInputField, ButtonWrapper, Span, ButtonWrapper2 } from "./style";

export default ({ appId, appName, onUpdate }) => {
  const nameTag = useRef(null);
  const [name, setName] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (isEditing) {
      setName(name);
      nameTag && nameTag.current && nameTag.current.focus(); // 이름 태그 포커싱
    }
  }, [isEditing]);

  // 수정 버튼 클릭
  const handleEditButtonClick = () => {
    setIsEditing(true); // 편집모드 키기
  };

  // 저장 버튼 클릭
  const handleSaveButtonClick = async (id, name) => {
    await onUpdate(id, name);
    setIsEditing(false); // 편집모드 끄기
  };

  return (
    <ListItem key={appId}>
      {isEditing ? (
        <NameInputField
          ref={nameTag}
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyPress={(e) => {
            // 엔터 쳐도 저장되도록
            if (e.key === "Enter") {
              handleSaveButtonClick(appId, name);
            }
          }}
        />
      ) : (
        <NameField>{appName}</NameField>
      )}

      {/* 우측 아이콘 */}
      {isEditing ? (
        <ButtonWrapper2>
          <Button
            disabled={!name}
            onClick={() => {
              handleSaveButtonClick(appId, name);
            }}
          >
            <Span>저장</Span>
            <Icon icon="save" />
          </Button>
        </ButtonWrapper2>
      ) : (
        <ButtonWrapper>
          <Button onClick={handleEditButtonClick}>
            <Span>수정</Span>
            <Icon icon="edit" />
          </Button>
        </ButtonWrapper>
      )}
    </ListItem>
  );
};
