import React from "react";
import { List } from "./style";
import Item from "./item";

export default ({ items, onDelete, onUpdate }) => {
  return (
    items &&
    items.length > 0 && (
      <List>
        {items &&
          items.map((x) => (
            <Item key={x.id} item={x} onDelete={onDelete} onUpdate={onUpdate} />
          ))}
      </List>
    )
  );
};
