import { InitCopyrightType } from "./types";

export const initCopyright: InitCopyrightType = {
  // 본 모델의 소스는 직접 제작하였습니다. (On / Off)
  'diy': 'off',

  // 3D웨어하우스 소스를 사용하였습니다 (On / Off)
  '3dWarehouse': 'on',
  // 3D웨어하우스 소스 목록 
  '3dWarehouseList': '',

  // 상업적으로 이용 가능한 타 무료 외부소스를 사용하였습니다 (On / Off)
  'freeExtraSource': 'on',
  // 타 무료 외부소스 목록 
  'freeExtraSourceList': '',

  // 상업적으로 이용 가능한 타 유로 외부소스를 사용하였습니다 (On / Off)
  'payExtraSource': 'on',
  // 타 유료 외부소스 목록 
  'payExtraSourceList': '',

  // 동적 구성 요소가 포함되어 있습니다 (On / Off)
  'dynamicElement': 'on',

  // 동의
  'agreement': 'off'
};
