import React, { useContext } from 'react';
import { AppContext } from 'app';
import { STATUS_DRAFT, STATUS_REJECT, STATUS_INPROGRESS, STATUS_REQUEST, STATUS_APPROVED, STATUS_OPEN, STATUS_COMPLETE_ON_OPEN, STATUS_REQUEST_ON_OPEN, STATUS_IN_REVIEW_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import { useTranslation } from 'react-i18next';
import RcSelect from 'react-select';
import { Wrap } from './style';
import { useRecoilState } from 'recoil';
import { displaySelector, languagesSelector, newStatusSelector, statusSelector } from 'boards/DetailBoardWrite/recoil/selectors';

export default (({ disabled, godoGoodsNo, onChange }) => {
    const { t } = useTranslation();
    const { userInfo } = useContext(AppContext);
    const [display] = useRecoilState(displaySelector);
    const [languages] = useRecoilState(languagesSelector);
    const [status] = useRecoilState(statusSelector);
    const [newStatus] = useRecoilState(newStatusSelector);
    const isModified = newStatus === STATUS_REQUEST_ON_OPEN || newStatus === STATUS_IN_REVIEW_ON_OPEN;

    const stateItems = [
        { value: STATUS_DRAFT, label: isModified ? t('reviewStatus.editing') : t('reviewStatus.empty') },
        { value: STATUS_REQUEST, label: isModified ? t('reviewStatus.editRequest') : t('reviewStatus.request') },
        // [todo]: 고도몰 번호가 없을 경우 바ㅣ활성화
        { value: STATUS_INPROGRESS, label: isModified ? t('reviewStatus.editReview') : t('reviewStatus.review'), isDisabled: !godoGoodsNo },
        { value: STATUS_APPROVED, label: t('reviewStatus.complete'), isDisabled: !(languages && languages.length === 4) },
        // 수정완료 상태의 경우
        {...display.isModified && { value: STATUS_COMPLETE_ON_OPEN, label: t('reviewStatus.completeOnOpen'), isDisabled: !(languages && languages.length === 4) }},
        { value: STATUS_REJECT, label: t('reviewStatus.reject') },
    ];

    // 수정 상태가 아닌경우
    if (!display.isEdit) {
        // 반려 항목을 추가한다.
        stateItems.push({ value: STATUS_REJECT, label: t('reviewStatus.reject') });
    }

    const getReviewStatusText = (reviewStatus, isModified) => {
        switch (reviewStatus) {
            case '' || STATUS_DRAFT:
                return t('reviewStatus.empty')
            case STATUS_REQUEST:
                return t('reviewStatus.request')
            case STATUS_INPROGRESS:
                return t('reviewStatus.review')
            case STATUS_REJECT:
                return t('reviewStatus.reject')
            case STATUS_APPROVED:
            case STATUS_OPEN:
                if (isModified) { return t('reviewStatus.completeOnOpen'); }
                else { return t('reviewStatus.complete'); }
        }
    }

    // 상태 표시이름
    const statusDisplayName = getReviewStatusText(status, display.isModified)

    // 관리자일 경우 
    if (userInfo.isAdmin) {
        return (
            <Wrap>
                <RcSelect
                    {...{
                        isMulti: false,
                        value: stateItems.find(x => 
                            // 수정완료 상태의 경우
                            (status === STATUS_APPROVED && display.isModified) 
                            ? x.value === STATUS_COMPLETE_ON_OPEN
                            : x.value === status
                        ),
                        options: stateItems,
                        isDisabled: disabled,
                        onChange
                    }}
                />
            </Wrap>
        );
    }

    // 관리자가 아닐경우 
    return (<span>{statusDisplayName}</span>);
});