import { Box, Input } from '@mui/material';
import React, { useRef, useState } from 'react';

interface Props {
  defaultValue: string|undefined;
  setValue: (val: string) => void;
}

export default function SearchBar({
  defaultValue,
  setValue
}: Props) {
  // mui input은 div로 한 번 감싸져 있어서 useRef를 쓸 수 없다.
  const [input, setInput] = useState<string|undefined>();
  return (
    <Box 
      display="flex"
      alignItems="center"
      border="1px solid #cdcdcd"
      borderRadius="4px"
      padding="10px 16px"
    >
      <Input
        type='number'
        {...typeof defaultValue !== 'undefined' && {
          defaultValue
        }}
        placeholder="상품 번호로 검색"
        onChange={e => {
          if (e.currentTarget.value.length > 0) {
            setInput(e.currentTarget.value);
          } else {
            setInput(undefined);
          }
        }}

        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setValue(input);
          }
        }}
        sx={{
          width: '100%',
          height: '12px',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '12px',
          color: '#333',
          "&:before": {
            display: "none",
          },
          "&:after": {
            display: "none",
          },
          "&::placeholder": {
            color: '#333',
          },
          '& input::-webkit-outer-spin-button': {
            display: 'none',
          },
          '& input::-webkit-inner-spin-button': {
            display: 'none',
          },
        }}
      />
      <button
        type="button"
        onClick={() => setValue(input)}
        style={{
          marginLeft: 'auto',
          padding: '0 1px',
          background: 'transparent',
          border: 'none',
          width: 'fit-content',
          height: 'fit-content',
        }}
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4765 11.8908C9.49566 12.5892 8.2958 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 8.29586 12.5892 9.49577 11.8907 10.4766L14.5 13.0859C14.8905 13.4764 14.8905 14.1096 14.5 14.5001C14.1094 14.8906 13.4763 14.8906 13.0857 14.5001L10.4765 11.8908ZM11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7Z" fill="#545454"/>
        </svg>
      </button>
    </Box>
  );
};