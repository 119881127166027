import React, { useState, useContext, useEffect, useRef } from 'react';
import { Flex, Field, Title, BalloonDescription, Item, Value } from '../../style';
import { useTranslation } from 'react-i18next';
import { Icon } from 'rsuite';
import { ImageBox, ImageType, TypeDivision, Message } from './style';
import SubImage from './image';
import { WhiteButton } from 'boards/DetailBoardWrite/component/form';
import { AppContext } from 'app';
import { useRecoilState } from 'recoil';
import { displaySelector, subImageSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import downloadImg from 'utils/downloadImg';

export default (() => {
    const { t } = useTranslation();
    const { userInfo } = useContext(AppContext);
    const [display] = useRecoilState(displaySelector);
    const [image, setImage] = useRecoilState(subImageSelector);

    // 현재 이미지 유형 (기본 1)
    const [imageType, setImageType] = useState(1);

    // 메세지 
    const [message, setMessage] = useState({
        type: false,
        txt: ''
    });

    // 이미지1 
    const [image1, setImage1] = useState('');
    // 이미지2
    const [image2, setImage2] = useState('');
    // 이미지3
    const [image3, setImage3] = useState('');

    // 서브이미지1 완료여부 
    const [isComplete1, setIsComplete1] = useState(false);
    // 서브이미지2 완료여부 
    const [isComplete2, setIsComplete2] = useState(false);
    // 서브이미지3 완료여부 
    const [isComplete3, setIsComplete3] = useState(false);

    // 서브이미지1 크로퍼
    const subImage1CropperTag = useRef(null);
    // 서브이미지2 크로퍼
    const subImage2CropperTag = useRef(null);
    // 서브이미지3 크로퍼
    const subImage3CropperTag = useRef(null);

    useEffect(() => {
        if (!isComplete1 && image.subImage1.cropInfo)
            setIsComplete1(true);

        if (!isComplete2 && image.subImage2.cropInfo)
            setIsComplete2(true);

        if (!isComplete3 && image.subImage3.cropInfo)
            setIsComplete3(true);
    }, [image.subImage1.cropInfo, image.subImage2.cropInfo, image.subImage3.cropInfo]);

    const allImageEventHandler = [
        {
            setImageType: () => { setImageType(1); },
            cropperTag: subImage1CropperTag,
            setCropInfo: value => {
                setImage({ 
                    subImage1: {
                        cropInfo: value,
                    }
                });
            },
            setImageUrl: (key, name) => {
                setImage({ 
                    subImage1: {
                        imagePath: key,
                        imageName: name,
                    }
                });
            },
            setImage: setImage1,
            setComplete: setIsComplete1
        },
        {
            setImageType: () => { setImageType(1); },
            cropperTag: subImage2CropperTag,
            setCropInfo: value => {
                setImage({ 
                    subImage2: {
                        cropInfo: value,
                    }
                });
            },
            setImageUrl: (key, name) => {
                setImage({ 
                    subImage2: {
                        imagePath: key,
                        imageName: name,
                    }
                });
            },
            setImage: setImage2,
            setComplete: setIsComplete2
        },
        {
            setImageType: () => { setImageType(1); },
            cropperTag: subImage3CropperTag,
            setCropInfo: value => {
                setImage({ 
                    subImage3: {
                        cropInfo: value,
                    }
                });
            },
            setImageUrl: (key, name) => {
                setImage({ 
                    subImage3: {
                        imagePath: key,
                        imageName: name,
                    }
                });
            },
            setImage: setImage3,
            setComplete: setIsComplete3
        }
    ];
    // 메세지 표시하기 메소드
    const showMessage = (txt, type) => {
        // type이 정의되지 않았을 경우 
        if (type === undefined)
            type = false;

        // 메세지 표시 
        setMessage({
            type,
            txt
        });

        setTimeout(() => {
            // 2초 뒤 메세지 초기화 
            setMessage({
                ...message,
                txt: ''
            });
        }, 2000);
    };
    // 이미지 다운로드 버튼 클릭 이벤트 처리기 메소드 
    const onClickImageDownload = async () => {
        // 크롭된 이미지들 
        const {subImage1, subImage2, subImage3} = image;
        
        downloadImg(process.env.STORAGE_ORIGIN + '/' + subImage1.imagePath, "서브이미지1");
        downloadImg(process.env.STORAGE_ORIGIN + '/' + subImage2.imagePath, "서브이미지2");
        downloadImg(process.env.STORAGE_ORIGIN + '/' + subImage3.imagePath, "서브이미지3");
    };

    const setCompleteCallback = () => {
        if (imageType === 1) {
            if (!isComplete2) {
                setImageType(2);
                return;
            }
            if (!isComplete3) {
                setImageType(3);
                return;
            }
        }

        if (imageType === 2) {
            if (!isComplete3) {
                setImageType(3);
                return;
            }
            if (!isComplete1) {
                setImageType(1);
                return;
            }
        }

        if (imageType === 3) {
            if (!isComplete1) {
                setImageType(1);
                return;
            }
            if (!isComplete2) {
                setImageType(2);
                return;
            }
        }
    };

    const setCallback = () => { };
    const setFileCallback = () => { };

    // 크롭된 이미지들 
    const { subImage1, subImage2, subImage3 } = image;
    
    return (
        <Field>
            <Title>
                <span>{t("subImageTitle")}</span>
                <BalloonDescription>{t('goods.subImageBallonDescription')}</BalloonDescription>
            </Title>
            <Flex align="center" type="space-between">
                <Flex align="center" >
                    <ImageType active={imageType === 1} completed={isComplete1} onClick={() => { setImageType(1); }}>
                        {t('subThumbnail')}1
                        {!display.isDisp && <Icon icon="check-circle" />}
                    </ImageType>
                    <TypeDivision />
                    <ImageType active={imageType === 2} completed={isComplete2} onClick={() => { setImageType(2); }}>
                        {t('subThumbnail')}2
                        {!display.isDisp && <Icon icon="check-circle" />}
                    </ImageType>
                    <TypeDivision />
                    <ImageType active={imageType === 3} completed={isComplete3} onClick={() => { setImageType(3); }}>
                        {t('subThumbnail')}3
                        {!display.isDisp && <Icon icon="check-circle" />}
                    </ImageType>
                </Flex>
                <Flex>
                    {userInfo.isAdmin &&
                        <WhiteButton height="28"
                            onClick={onClickImageDownload}
                            disabled={!subImage1.imageBlob || !subImage2.imageBlob || !subImage3.imageBlob}
                        >
                            {t('downloadImage')}
                        </WhiteButton>
                    }
                </Flex>
            </Flex>
            <Item>
                <Value>
                    {message.txt && <Message type={message.type}>{message.txt}</Message>}
                    {<SubImage
                        message={message}
                        showMessage={showMessage}
                        setCroppedImageBlob={value => {
                            setImage({ 
                                subImage1: {
                                    imageBlob: value,
                                }
                            });
                        }}
                        setCroppedImageDataUrl={value => {
                            setImage({ 
                                subImage1: {
                                    croppedImageDataUrl: value,
                                }
                            });
                        }}
                        imgType={'subImage1'}
                        cropperTag={subImage1CropperTag}
                        disabled={display.isDisp}
                        image={image1}
                        setImage={setImage1}
                        allImageEventHandler={allImageEventHandler}
                        isComplete={isComplete1}
                        setComplete={setIsComplete1}
                        isShow={imageType === 1}
                        setCallback={setCallback}
                        setCompleteCallback={setCompleteCallback}
                        setFileCallback={setFileCallback}
                    />}
                    {<SubImage
                        message={message}
                        showMessage={showMessage}
                        setCroppedImageBlob={value => {
                            setImage({ 
                                subImage2: {
                                    imageBlob: value,
                                }
                            });
                        }}
                        setCroppedImageDataUrl={value => {
                            setImage({ 
                                subImage2: {
                                    croppedImageDataUrl: value,
                                }
                            });
                        }}
                        imgType={'subImage2'}
                        cropperTag={subImage2CropperTag}
                        disabled={display.isDisp}
                        image={image2}
                        setImage={setImage2}
                        allImageEventHandler={allImageEventHandler}
                        isComplete={isComplete2}
                        setComplete={setIsComplete2}
                        isShow={imageType === 2}
                        setCallback={setCallback}
                        setCompleteCallback={setCompleteCallback}
                        setFileCallback={setFileCallback}
                    />}
                    {<SubImage
                        message={message}
                        showMessage={showMessage}
                        setCroppedImageBlob={value => {
                            setImage({ 
                                subImage3: {
                                    imageBlob: value,
                                }
                            });
                        }}
                        setCroppedImageDataUrl={value => {
                            setImage({ 
                                subImage3: {
                                    croppedImageDataUrl: value,
                                }
                            });
                        }}
                        imgType={'subImage3'}
                        cropperTag={subImage3CropperTag}
                        disabled={display.isDisp}
                        image={image3}
                        setImage={setImage3}
                        allImageEventHandler={allImageEventHandler}
                        isComplete={isComplete3}
                        setComplete={setIsComplete3}
                        isShow={imageType === 3}
                        setCallback={setCallback}
                        setCompleteCallback={setCompleteCallback}
                        setFileCallback={setFileCallback}
                    />}
                </Value>
            </Item>
            {
                image.subImage1.croppedImageDataUrl && image.subImage2.croppedImageDataUrl && image.subImage3.croppedImageDataUrl && (
                    <ImageBox id="thumbnailImage">
                        <div>
                            <img src={image.subImage1.croppedImageDataUrl} />
                        </div>
                        <div>
                            <div>
                                <img src={image.subImage2.croppedImageDataUrl} />
                            </div>
                            <div>
                                <img src={image.subImage3.croppedImageDataUrl} />
                            </div>
                        </div>
                    </ImageBox>
                )
            }
        </Field>
    );
});