import { selector } from "recoil";
import { packageState } from "..";
import { PackageType } from "../types";

const packageSelector = selector<PackageType>({
  key: 'packageSelector',
  get:({get}) => get(packageState),
  set: ({set}, val) => set(packageState, val),
});

export default packageSelector;
