import styled from "styled-components";

export const FundingAmountWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-bettwen;
`;

export const FieldWrapper = styled.div`
  &:last-child {
    margin-top: 16px;
  }
`;

export const LabelText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #7c7c7c;
  margin-bottom: 12px;
`;
