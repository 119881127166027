import { BlackButton } from 'components/button';
import { TextCenter } from 'components/style';
import React, { useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { FlexItem } from 'signup/style';
import { gql, useMutation, useQuery } from '@apollo/client';
import Spinner from 'spinner/Spinner';
import styled from 'styled-components';
import { useEffect } from 'react';

const query = gql`
    query ($goodsNo: Float!) {
        buyers: getGoodsBuyers(goodsNo: $goodsNo) {
            orderName
            orderEmail
            groupNm
        }
    }
`;

const mutation = gql`
    mutation (
        $input: ProductUpdateMailInput!
    ) {
        result: sendMailsWhenProductUpdated(
            input: $input
        )
    }
`;

const MaxHeight = styled.div`
    height: 300px;
    overflow: auto;
`;

const Success = styled.td`
    background: #8CDA80;
`;

const Fail = styled.td`
    background: #DA8080;
`;

const Dialog = ({show, handleClose, goodsNo, productName, brandName, docId }) => {
    const { data, loading } = useQuery(query, {variables: {goodsNo: goodsNo}});
    const [ sendResult, setSendResult ] = useState(false);
    const [ sendMailProductUpdate ] = useMutation(mutation);

    const downloadExcel = () => {
        // const table = document.getElementById('tableExport'); // 비동기 변경으로 비활성화
        // saveAs('data:application/vnd.ms-excel,'+table.outerHTML.replace(/ /g, '%20'), 'test.xls');
    };

    useEffect(() => {
        if (show && !loading && data && data.buyers.length === 0) {
            // 상품구매자가 없으면 alert + 팝업 닫음
            handleClose();
            alert('상품 구매자가 없습니다.');
            return;
        }

        if (show && data?.buyers) {
            // 팝업이 보이는 상태 AND 모든 데이터가 갖춰줬을 때

            const mailData = {
                targets: [],
                productNo: goodsNo,
                date: new Date().toISOString(),
                docId: docId
            }

            data.buyers.forEach(buyer => {
                let languageCode = 'en';

                if (buyer.groupNm === '일반') {
                    languageCode = 'ko';
                } else if (buyer.groupNm === 'Worldwide') {
                    languageCode = 'zh';
                } else if (buyer.groupNm === 'Global') {
                    languageCode = 'en';
                }

                mailData.targets.push({
                    name: buyer.orderName,
                    email: buyer.orderEmail,
                    languageCode,
                });
            });

            sendMailProductUpdate({ variables: { input: mailData } }).then((res) => {
                setSendResult(res.data.result);
            }).catch((err) => {
                setSendResult(false)
            });
        }
    }, [data, docId, show]);

    return (
        <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton />
            <Modal.Body>
                { data?.buyers ?
                    <>
                        <TextCenter><big><strong>총 발송 대상: {data.buyers.length}</strong></big></TextCenter>
                        <MaxHeight>
                            <Table striped bordered hover>
                                <tr>
                                    <td>일괄 발송 결과</td>
                                    <td>{sendResult ? (<Success>성공</Success>) : (<Fail>실패</Fail>)} </td>
                                </tr>
                            </Table>
                        </MaxHeight>
                    </>
                : <Spinner/>}
            </Modal.Body>
            <Modal.Footer>
                <FlexItem>
                    <BlackButton max onClick={downloadExcel}>발송목록 상세 다운로드(미구현)</BlackButton>
                </FlexItem>
            </Modal.Footer>
        </Modal>
    );
};

export default Dialog;