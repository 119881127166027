import FundingSelector from "boards/DetailBoardWrite/recoil/selectors/funding";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { Radio, RadioGroup } from "rsuite";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { LanguageCodeEnum } from "generated/graphql";
import { STATUS_OPEN } from "boards/DetailBoardWrite/constants";
import { AppContext } from "app";

const Styled = {
  RadioGroup: styled(RadioGroup)<{ disabled: boolean }>`
    display: flex;
    gap: 145px;

    ${(props) =>
      props.disabled &&
      `
      pointer-events: none;
    `}
  `,
};

type Props = {
  disabled: boolean;
}

export default ({ disabled }: Props) => {
  const { t } = useTranslation();
  const [funding, setFunding] = useRecoilState(FundingSelector);
  
  const handleProductTypeChange = (type: string) => {
    if (type === "general") {
      setFunding(null);
      return;
    }

    setFunding({
      goalAmount: 0,
      startDate: null,
      endDate: null,
      deliveryDate: null,
    });
  };

  return (
    <Styled.RadioGroup
      inline
      name="diy"
      value={funding ? "funding" : "general"}
      disabled={disabled}
      onChange={handleProductTypeChange}
    >
      <Radio disabled={disabled} value="general">
        {t("product.type.default")}
      </Radio>
      <Radio disabled={disabled} value="funding">
        {t("product.type.funding")}
      </Radio>
    </Styled.RadioGroup>
  );
};
