import React, { useContext } from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Tooltip, Whisper } from 'rsuite';
import { CANCEL_REQUEST_STATUS, DELETE_PROD_ITEM } from '../../../../api/mutations';
import { AppContext } from '../../../../app';
import dayjs from 'dayjs'
import { STATUS_DRAFT, STATUS_REQUEST, STATUS_OPEN } from 'boards/DetailBoardWrite/constants';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { Field } from 'components/table';
import { color } from 'acon-mui/style';
import { Label } from 'acon-mui/components';
import { LinkIcon } from './LinkIcon';
import { DeleteButton } from './DeleteButton';
import convertLanguageCode from 'utils/convertLanguageCode';
import { Exact, GetDisplayProductsQuery, HubProductStatus, New_Product_Status } from 'generated/graphql';
import { LanguageEnum } from 'boards/DetailBoardWrite/recoil/types';

interface Props {
    id?: string;
    godoGoodsNo?: number;
    mainImage?: string;
    titles?: {
        title: string;
        lang: string;
        isLink: boolean;
    }[];
    requestDates?: {
        requestDate: Date;
        lang: string;
    }[];
    created?: any;
    brand?: string;
    status: string;
    isEdit: boolean;
    newStatus: string;
    refetch: (variables?: Partial<Exact<{
        title?: string;
        contents?: string;
        godoGoodsNo?: number;
        status?: string | string[];
        page: number;
        limit: number;
        brandIds?: number | number[];
        createStartDate?: string;
        createEndDate?: string;
        requestStartDate?: string;
        requestEndDate?: string;
    }>>) => Promise<ApolloQueryResult<GetDisplayProductsQuery>>;
    [key: string]: unknown;
}

export default (({
    id,
    godoGoodsNo,
    mainImage,
    titles,
    requestDates,
    created,
    brand,
    status,
    newStatus,
    isEdit,
    refetch,
}: Props) => {
    const { t, i18n } = useTranslation();
    const {
        // 모달창 표시하기 메소드
        showConfirmMessage,
        // 사용자 정보
        userInfo
    } = useContext(AppContext);

    const { isAdmin } = userInfo;

    const defaultLang = i18n.language;
    // 제공하는 언어
    const resourceLang = ['KO', 'EN', 'CN', 'JP'];
    // 검토 요청일
    const requestDate = requestDates.find(y => y.lang === defaultLang)?.requestDate;
    // 상태 라벨
    const label = () => {
        let label;
        switch (newStatus) {
            // 작성중
            case New_Product_Status.Draft: label = (
                <Label color="yellow" text={t('reviewStatus.none')} mx="auto" />
            ); break;
            // 수정중
            case New_Product_Status.DraftOnOpen: label = (
                <Label color="yellow" text={t('reviewStatus.editing')} mx="auto" />
            ); break;
            // 검토요청 
            case New_Product_Status.Requested: label = (
                <Label color="blue" text={t('reviewStatus.request')} mx="auto" />
            ); break;
            // 검토진행
            case HubProductStatus.InReview: label = (
                <Label color="blue" text={t('reviewStatus.review')} mx="auto" />
            ); break;
            case New_Product_Status.RequestedOnOpen: label = (
                <Label color="sky-blue" text={t('reviewStatus.editRequest')} mx="auto" />
            ); break;
            case New_Product_Status.InReviewOnOpen: label = (
                <Label color="sky-blue" text={t('reviewStatus.editReview')} mx="auto" />
            ); break;
            // 반려
            case New_Product_Status.Rejected: label = (
                <Label color="red" text={t(`reviewStatus.reject`)} mx="auto" />
            ); break;
            // 검토완료
            case New_Product_Status.Complete: label = (
                <Label color="green" text={t(`reviewStatus.complete`)} mx="auto" />
            ); break;
            // 수정완료
            case New_Product_Status.CompleteOnOpen: label = (
                <Label color="green" text={t(`reviewStatus.completeOnOpen`)} mx="auto" />
            ); break;
            // 오픈됨
            case STATUS_OPEN: label = (
                <Label color="green" text={t(`reviewStatus.open`)} mx="auto" />
            ); break;
            // 이외 
            default:
                label = (<Label color="yellow" text={t(`reviewStatus.${status}`)} mx="auto" />);
        }
        return label;
    }
    // 항목 삭제하기 
    const [deleteProdItem] = useMutation(DELETE_PROD_ITEM, {
        onCompleted() {
            refetch();
        }
    });
    // 항목 요청취소하기 
    const [cancelRequestProdItem] = useMutation(CANCEL_REQUEST_STATUS, {
        onCompleted() {
            refetch();
        }
    });

    // 툴팁 가져오기 메소드 
    const getTooltipWrap = (components, text) => {
        const tooltip = (<Tooltip>{text}</Tooltip>);

        return (
            <Whisper speaker={tooltip} placement="top" trigger="hover">
                {components}
            </Whisper>
        );
    };
    // 동작 버튼 클릭 이벤트 처리기 메소드 
    const onClickActionButton = (docId, status) => {
        return () => {
            try {
                showConfirmMessage(
                    status === STATUS_REQUEST ? t("isCancelRequest") : t("isDelete"),
                    status === STATUS_REQUEST ? t("isCancelRequestConfirmButton") : t("toDeleteConfirmButton"),
                    () => {
                        // 상태가 요청된 것 일 경우 
                        if (status === STATUS_REQUEST) {
                            // 요청상태 취소하기 
                            cancelRequestProdItem({ variables: { docId } });
                            // 종료
                            return;
                        }
                        // 항목 삭제하기 
                        deleteProdItem({ variables: { docId } });
                    },
                    (() => {
                        if (status === STATUS_REQUEST) {
                            return {
                                cancelText: t('goBack'),
                                confirmText: t('cancelConfirm')
                            }
                        }
                        return undefined;
                    })()
                );
            }
            catch (errObj) {
                // 실패 메세지 조립 
                errObj.message = `상품 삭제버튼 이벤트 실패(${errObj.message})`;
                // 로그 기록(TODO)
                console.log(errObj);
                // 사용자 안내 메세지 표시
                alert('관리자에게 문의해주세요.');
            }
        }
    };

    return (
        <TableRow key={`detail table ${id}`} >
            <TableCell>
                <Box
                    width="80px"
                    height="80px"
                    borderRadius="6px"
                    sx={{
                        backgroundImage: `url(${mainImage || '/assets/detail-board/default_img.png'})`,
                        backgroundSize: 'cover'
                    }}
                />
            </TableCell>
            <TableCell>
                <Field width={isAdmin ? 187 : 250}>
                    
                    {// 제목들 
                        titles && titles.length && titles.length > 0 ? (titles.map((y, i) =>
                            <Box 
                                key={i}
                                sx={{
                                    py: 0.5,
                                    display: 'flex',
                                    '& a': {
                                        color: color.text.primary,
                                        fontWeight: '700',
                                        fontSize: '14px',
                                        lineHeight: '22px'
                                    }
                                }}
                            >
                                <Typography 
                                    mr={2}
                                    p="2px 8px"
                                    width="32px"
                                    color={color.text.primary}
                                    fontWeight="700"
                                    fontSize="12px"
                                    lineHeight="20px"
                                    border={`1px solid ${color.gray.border}`}
                                    borderRadius="6px"
                                >
                                    {convertLanguageCode(y.lang as LanguageEnum).toUpperCase()}
                                    </Typography>
                                {y.title && y.isLink ?
                                    getTooltipWrap(<NavLink to={`/goods/write/${y.lang}/${id}`}>{y.title}</NavLink>, y.title)
                                    : '-'}
                            </Box>
                            
                        )) : resourceLang.map(x => (
                            <Box 
                                key={x}
                                sx={{
                                    py: 0.5,
                                    color: color.text.primary,
                                    fontWeight: '700',
                                    fontSize: '14px',
                                    lineHeight: '22px'
                                }}
                            >
                                <Typography 
                                    mr={2}
                                    p="2px 8px"
                                    width="32px"
                                    fontSize="12px"
                                    lineHeight="20px"
                                    border={`1px solid ${color.gray.border}`}
                                    borderRadius="6px"
                                >
                                    [{x}]
                                </Typography>
                                -
                            </Box>
                        ))
                    }
                </Field>
            </TableCell>
            <TableCell>
                <Typography fontSize="12px" lineHeight="18px" color={color.text.secondary}>
                    {requestDate ? dayjs(requestDate).format('YY-MM-DD\u00a0\u00a0HH:mm') : '-'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography fontSize="12px" lineHeight="18px" color={color.text.secondary}>
                    {created ? dayjs(created).format('YY-MM-DD\u00a0\u00a0HH:mm') : '-'}
                </Typography>
            </TableCell>
            {isAdmin ?
                <TableCell>
                    <Typography fontSize="12px" lineHeight="18px" color={color.text.secondary}>
                        {brand || '-'}
                    </Typography>
                </TableCell> : <></>
            }
            <TableCell>
                {label()}
            </TableCell>
            <TableCell>
                {godoGoodsNo ? 
                    getTooltipWrap((
                        <span><LinkIcon 
                            href={`https://www.acon3d.com/${convertLanguageCode(i18n.language as LanguageEnum)}/toon/product/${godoGoodsNo}`} 
                            disabled={!godoGoodsNo} 
                        /></span>
                        ), `상품 번호 ${godoGoodsNo}`)
                : 
                    <>
                        <LinkIcon 
                            href={`https://www.acon3d.com/${convertLanguageCode(i18n.language as LanguageEnum)}/toon/product/${godoGoodsNo}`} 
                            disabled={!godoGoodsNo} 
                        />
                    </>
                }
            </TableCell>
            <TableCell>
                {getTooltipWrap(
                    <span><DeleteButton
                        onClick={onClickActionButton(id, status)}
                        type={status === STATUS_REQUEST ? "cancel" : "delete"}
                        disabled={!(isAdmin || (!isAdmin && status === STATUS_DRAFT && !isEdit) || (!isAdmin && status === STATUS_REQUEST && !isEdit))}
                    /></span>, status === STATUS_REQUEST ? `${
                    isEdit ? t('project.cancelEditRequest') : t('project.cancelReviewRequest')
                    }` : `${t('Remove.label')}`)}
            </TableCell>
        </TableRow>
    );
});