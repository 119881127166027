import { selector } from "recoil";
import { brandState } from "..";
import { BrandType } from "../types";

const brandSelector = selector<BrandType>({
  key: 'brandSelector',
  get:({get}) => get(brandState),
  set: ({set}, val) => set(brandState, val),
});

export default brandSelector;
