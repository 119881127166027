import { useQuery } from "@apollo/client";
import { GET_EXTENSION_CONFIGS } from "api/quries";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RcSelect from "react-select";
import ListModal from "./listModal";
import { Item, Title, Value } from "../../style";
import { UnderButton } from "boards/DetailBoardWrite/component/form";
import { useRecoilState } from "recoil";
import {
  applicationsSelector,
  extensionSelector,
} from "boards/DetailBoardWrite/recoil/selectors";
import { APPLICATION_RULE } from "../application/constant";

export default () => {
  // 번역도구
  const { t } = useTranslation();
  const [extension, setExtension] = useRecoilState(extensionSelector);
  const [applicationsState, setApplicationsState] =
    useRecoilState(applicationsSelector);
  const [isShowModal, setIsShowModal] = useState(false);
  const {
    data: extensionConfigData,
    loading,
    refetch,
  } = useQuery(GET_EXTENSION_CONFIGS);
  const extensionConfigs = extensionConfigData?.getExtensionConfigs || [];

  const onChange = (itemObjArr) => {
    const value = itemObjArr ? itemObjArr.map((x) => x.value) : [];
    const newValue = value.filter((item) => !extension.includes(item));

    if (newValue.length > 0) {
      const newApplication = APPLICATION_RULE.find((application) =>
        application.extensionIds.includes(newValue[0])
      )?.value?.id;
      if (newApplication)
        setApplicationsState([
          ...new Set([...applicationsState, newApplication]),
        ]);
    }

    setExtension(value);
  };

  // 로딩중 여부
  const isLoading = !(
    !loading &&
    extensionConfigData &&
    extensionConfigData.getExtensionConfigs
  );

  // 리스트 추가하기 버튼 클릭 이벤트 처리기 메소드
  const handleAddListButton = () => {
    // 모달창 표시하기
    setIsShowModal(true);
  };

  return (
    <Item>
      <Title admin={true}>
        {t("goods.extension")}
        <UnderButton onClick={handleAddListButton}>
          {t("addListItem")}
        </UnderButton>
      </Title>
      <Value>
        {!isLoading &&
          (() => {
            const extensions = extensionConfigs.map((x) => {
              return {
                value: x.id,
                label: x.name,
              };
            });

            const getExtension = (items) => {
              return extensions.filter(
                (x) => items.filter((y) => x.value === y).length > 0
              );
            };

            return (
              <RcSelect
                {...{
                  placeholder: t("goods.extension"),
                  value: getExtension(extension),
                  isMulti: true,
                  options: extensions,
                  onChange,
                }}
              />
            );
          })()}
      </Value>
      <ListModal
        items={extensionConfigs}
        show={isShowModal}
        onClose={() => {
          setIsShowModal(false);
        }}
        refetch={refetch}
      />
    </Item>
  );
};
