import React, { useContext } from 'react';
import ImageUpload from 'boards/DetailBoardWrite/component/imageUpload';

export default (({
    cropperTag,
    setCropInfo,
    setCropKey,
    image,
    setImage,
    croppedImageBlob,
    setCroppedImageBlob,
    croppedImageDataUrl,
    setCroppedImageDataUrl,
    disabled,
    isShow,
    isComplete,
    setComplete,
    cropInfo,
    url,
    showMessage
}) => {
    // 파일 개수 제한 
    const limit = 1;
    // 확장자들 
    const extensions = ['image/jpg', 'image/png', 'image/jpeg'];
    // 파일 크기 
    const fileSize = [1755, 900];

    return (
        <ImageUpload
            showMessage={showMessage}
            cropperTag={cropperTag}
            image={image}
            setImage={setImage}
            croppedImageBlob={croppedImageBlob}
            setCroppedImageBlob={setCroppedImageBlob}
            croppedImageDataUrl={croppedImageDataUrl}
            setCroppedImageDataUrl={setCroppedImageDataUrl}
            isUploadCropImg={true}
            isShow={isShow}
            disabled={disabled}
            isComplete={isComplete}
            setComplete={setComplete}
            maxWidth={fileSize[0]}
            maxHeight={fileSize[1]}
            extensions={extensions}
            sizeLimit={10}
            targetWidth={585}
            targetHeight={300}
            boxWidth={585}
            boxHeight={300}
            limit={limit}
            cropRatio={1.95}
            cropInfo={cropInfo}
            left={cropInfo?.imageOriginX}
            top={cropInfo?.imageOriginY}
            width={cropInfo?.imageWidth}
            height={cropInfo?.imageHeight}
            url={url}
            setCropInfo={value => setCropInfo(value)}
            setImageUrl={(key, name) => {
                setCropKey(key);
            }}
        />
    );
});