import { Button } from '@mui/material';
import { color } from 'acon-mui/style';
import React from 'react';

type Props = React.CSSProperties & {
  children: React.ReactNode;
  onClick?: (e) => void;
  disabled?: boolean;
  disableRipple?: boolean;
  variant?: 'text' | 'contained' | 'outlined';
  colorTheme?: 'primary' | 'normal';
  width?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
};

export default ({
  children,
  onClick,
  disabled=false,
  disableRipple=true,
  variant="outlined",
  colorTheme="normal",
  width="100%",
  startIcon,
  endIcon,
  ...rest
}: Props) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      disableRipple={disableRipple}
      color={colorTheme}
      variant={variant}
      sx={{ 
        width,
        fontWeight: '500',
        fontSize: '15px', 
        lineHeight: '26px', 
        ...(!disabled && colorTheme === 'normal' && variant === 'outlined') && {
          borderColor: color.gray.border
        },
        ...(!disabled && colorTheme === 'normal' && variant === 'contained') && {
          color: `${color.text.primary} !important`,
          background: `rgba(145, 158, 171, 0.08) !important`,
          boxShadow: 'none'
        },
        ...rest,
      }}
    >
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </Button>
  );
};
