import styled from "@emotion/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { statusState } from "./SignUp.atoms";
import { SignupChapterEnum } from "./type";
import parse from "html-react-parser";
import { LanguageCodeEnum } from "generated/graphql";

const Styled = {
  Progress: styled.div<{ status: number }>`
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 40px;
    margin-bottom: 60px;
    width: 100%;

    ::before,
    ::after {
      content: "";
      position: absolute;
      display: block;
      height: 2px;
      margin-left: 24px;
      margin-top: 12px;
    }

    ::before {
      width: calc(100% - 9px - 15px - 15px - 15px);
      background: #dfdfdf;
    }

    ::after {
      width: calc(
        100% - 9px - 15px - 15px - 15px - ${(props) => (4 - props.status) * 24}%
      );
      background: #f300ba;
      transition: 1s;
    }
  `,
  Column: styled.div<{ value: number; active: boolean }>`
    position: relative;
    font-size: 12px;
    text-align: center;
    line-height: 18px;

    ::before,
    ::after {
      content: "";
      position: relative;
      display: block;
      margin: 0 auto;
      border-radius: 50%;
    }

    ::before {
      width: 30px;
      height: 30px;
      margin-bottom: 12px;
      background: #c4c4c4;
    }

    ::after {
      position: absolute;
      z-index: 2;
      width: 0;
      height: 0;
      top: 15px;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: width 0.5s, height 0.5s;
    }

    &::after {
      width: 40px;
      height: 40px;
      content: "${(props) => props.value}";
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      ${(props) =>
        props.active
          ? `color: #fff;
          background-color: #f300ba;`
          : `color: #dfdfdf;
          border: 2px solid rgb(223, 223, 223);
          background-color: #fff;`}
    }
  `,
};

const process = {
  ko: [
    {
      label: "가입 유형",
      value: SignupChapterEnum.UserType,
    },
    {
      label: "계정 정보",
      value: SignupChapterEnum.Account,
    },
    {
      label: "파트너 정보",
      value: SignupChapterEnum.Partner,
    },
    {
      label: "계약 정보",
      value: SignupChapterEnum.Payment,
    },
    {
      label: "계약서 서명",
      value: SignupChapterEnum.Contract,
    },
  ],
  en: [
    {
      label: "Account<br />Information",
      value: SignupChapterEnum.Account,
    },
    {
      label: "Creator<br />Information",
      value: SignupChapterEnum.Partner,
    },
    {
      label: "Contract<br />Information",
      value: SignupChapterEnum.Payment,
    },
    {
      label: "Sign<br />Agreement",
      value: SignupChapterEnum.Contract,
    },
    {
      label: "Confirm<br />Information",
      value: SignupChapterEnum.Confirm,
    },
  ],
};

export default function Progress() {
  const { i18n } = useTranslation();
  const [status] = useRecoilState(statusState);

  return (
    <Styled.Progress
      status={i18n.language === LanguageCodeEnum.Ko ? status : status - 1}
    >
      {process[i18n.language].map((x, i) => (
        <Styled.Column
          key={`column_${i}`}
          value={i + 1}
          active={x.value <= status}
        >
          {parse(x.label)}
        </Styled.Column>
      ))}
    </Styled.Progress>
  );
}
