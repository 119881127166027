import React, { useContext, useEffect, useState } from "react";
import { FlexItem } from "signup/style";
import { Checkbox } from "rsuite";
import { InputFile } from "signup/input";
import { gql, useMutation, useQuery } from "@apollo/client";
import { uploadFile } from "api";
import { FlexBox, TextCenter } from "components/style";
import { PLAN_TYPE } from "../value/PlanType";
import { AppContext } from "app";
import { resign } from "lib/user";
import InputSign from "user/component/InputSign";
import { generateTermImage } from "user/util";
import axios from "axios";
import { SectionTitle, SubmitButton, Wrap } from "./styled";
import Summary from "./Summary";
import Term from "./Term";

const query = gql`
  query {
    getTermAll {
      type
      contents
    }
    brand {
      name
      godoBrandCd
    }
    user: signedUser {
      name
      companyName
      contact
    }
    settle {
      nationalIdNumber
    }
    userPlan {
      type
      isExclusive
      commissionRate
    }
  }
`;

const mutation = gql`
  mutation ($sign: FileInput!, $term: FileInput!) {
    createTermAgree(sign: $sign, term: $term)
  }
`;

const Component = (props) => {
  const { userInfo } = useContext(AppContext);
  const { loading, error, data } = useQuery(query);
  const [termAgree] = useMutation(mutation);

  const [isAgree, setIsAgree] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [file, setFile] = useState(null);
  const [termSrc, setTermSrc] = useState(
    process.env.STORAGE_ORIGIN + "/term/term.png"
  );

  const getVirtualImg = (src) => {
    return new Promise((resolve, reject) => {
      let img = document.createElement("img");
      img.crossOrigin = "anonymous";
      img.src = src;

      img.onload = () => {
        resolve(img);
      };
    });
  };

  const uploadSignedTerm = async (sign) => {
    const name = userInfo.isCompany ? data.user.companyName : data.user.name;
    const planType = PLAN_TYPE({
      type: data?.userPlan?.type,
      isExclusive: data?.userPlan?.isExclusive,
    });
    const commissionRate = data?.userPlan?.commissionRate;

    const canvas = document.createElement("canvas");
    canvas.width = sign.width;
    canvas.height = sign.height;

    const context = canvas.getContext("2d");
    context.drawImage(sign, 0, 0);

    const dataURL = canvas.toDataURL("image/png");

    return new Promise((resolve, reject) => {
      axios
        .post(
          "https://api-v2.acon3d.com/func/termGenerator",
          {
            name,
            planType,
            commissionRate,
            signSrc: dataURL,
            contact: data.user.contact,
            nationalIdNumber: data.settle.nationalIdNumber,
            password: data.brand.godoBrandCd,
          },
          { responseType: "blob", withCredentials: false }
        )
        .then((res) => {
          uploadFile(res.data, {
            directory: "settle/signedTerm",
          }).then(resolve);
        });
    });
  };

  const uploadSign = async () => {
    if (userInfo.isCompany) {
      return uploadFile(file, { directory: "settle/sign" });
    }

    const signCanvas = document.getElementById("sign");
    return new Promise((resolve) => {
      signCanvas.toBlob((blob) => {
        uploadFile(blob, { directory: "settle/sign" }).then(({ key }) => resolve(key));
      });
    });
  };

  const getSignImg = async () => {
    if (userInfo.isCompany) {
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onload = (e) => {
          getVirtualImg(e.target.result).then(resolve);
        };

        reader.readAsDataURL(file);
      });
    }

    const signCanvas = document.getElementById("sign");
    return getVirtualImg(signCanvas.toDataURL());
  };

  const onSubmit = async () => {
    try {
      const sign = await uploadSign();
      const img = await getSignImg();
      const term = await uploadSignedTerm(img);

      await termAgree({
        variables: {
          sign: { key: sign.key, name: "서명/인감" },
          term: { key: term.key, name: "계약서" },
        },
      });

      await resign();
      window.location.href = "/";
    } catch (err) {
      console.error(err);
      alert("오류발생");
    }
  };

  const onChangeSign = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setIsSigned(false);
    }

    setFile(file);
    setIsSigned(true);
  };

  useEffect(() => {
    if (data) {
      const name = userInfo.isCompany ? data.user.companyName : data.user.name;
      const planType = PLAN_TYPE({
        type: data?.userPlan?.type,
        isExclusive: data?.userPlan?.isExclusive,
      });
      const commissionRate = data?.userPlan?.commissionRate;

      generateTermImage(
        {
          name,
          planType,
          commissionRate,
          contact: data.user.contact,
          nationalIdNumber: data.settle.nationalIdNumber,
        },
        true
      ).then((src) => {
        setTermSrc(src);
      });
    }
  }, [data]);

  return (
    <Wrap>
      <h1>계약서 갱신을 확인해주세요</h1>
      <Summary />
      <Term src={termSrc} />
      {userInfo.isCompany ? (
        <section>
          <SectionTitle>인감/서명</SectionTitle>
          <InputFile onChange={onChangeSign} />
        </section>
      ) : (
        <section>
          <SectionTitle>서명</SectionTitle>
          <InputSign
            id="sign"
            summary="네모 칸 속에 서명을 그려주세요"
            setIsDrawn={setIsSigned}
          />
        </section>
      )}

      <FlexBox>
        <span>[필수]</span>
        <FlexItem>
          &nbsp;본인은 본 계약서에 서명/인감을 등록함으로써 종이문서의 서명과
          동일한 효력을 갖는데 동의합니다.
        </FlexItem>
        <span>
          <label>
            <Checkbox
              inline
              value={isAgree}
              onChange={(a, b) => setIsAgree(b)}
            />{" "}
            동의함
          </label>
        </span>
      </FlexBox>

      <section>
        <TextCenter>
          <SubmitButton onClick={onSubmit} disabled={!isAgree || !isSigned}>
            서명완료
          </SubmitButton>
        </TextCenter>
      </section>
    </Wrap>
  );
};

export default Component;
