import React, { useEffect, useRef, useState } from "react";
import { Icon } from "rsuite";
import { ListItem, NameField, Button } from "../style";
import { NameInputField, ButtonWrapper, Box } from "./style";

export default ({ item, onUpdate }) => {
  const nameTag = useRef(null);
  const [name, setName] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setName(name);

      // 이름 태그 포커싱
      nameTag && nameTag.current && nameTag.current.focus();
    }
  }, [isEdit]);

  // 수정 버튼 클릭 이벤트 처리기 메소드
  const handleEditButtonClick = () => {
    // 편집모드 on
    setIsEdit(true);
  };

  // 저장 버튼 클릭 이벤트 처리기 메소드
  const handleSaveButtonClick = async (id, name) => {
    await onUpdate(id, name);
    // 편집모드 on
    setIsEdit(false);
  };

  return (
    <ListItem key={item.id}>
      {!isEdit && (
        <NameField>{item?.name}</NameField>
      )}

      {isEdit && (
        <NameInputField
          ref={nameTag}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      )}

      <ButtonWrapper>
        {!isEdit && (
          <Button onClick={handleEditButtonClick}>
            <Icon icon="edit" />
          </Button>
        )}

        {isEdit && (
            <Button onClick={() => {handleSaveButtonClick(item.id, name) }}>
              <Icon icon="save" />
            </Button>
        )}

      </ButtonWrapper>
    </ListItem>
  );
};
