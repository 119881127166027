import { New_Product_Status, ProductStatus } from "generated/graphql";

export enum LanguageEnum {
  En = "en",
  Ja = "jp",
  Ko = "ko",
  Zh = "cn",
}

export type ProductNewStatusType = New_Product_Status;

export type DisplayType = {
  isDisp: boolean;
  lang: LanguageEnum | string;
  isEdit: boolean;
  hasOpened: boolean;
  isModified: boolean;
};

export type BrandType = {
  // 브랜드 ID
  brandId: number | null;
  // 관리자 메모
  brandName: string;
};

export type CommentType = {
  // 반려 사유
  rejectComment: string | null;
  // 수정 사항
  updateRequest: string | null;
  // 수정 의견
  updateResponse: string | null;
};

export type InitCopyrightType = {
  // 본 모델의 소스는 직접 제작하였습니다. (On / Off)
  diy: string;
  // 3D웨어하우스 소스를 사용하였습니다 (On / Off)
  "3dWarehouse": string;
  // 3D웨어하우스 소스 목록
  "3dWarehouseList": string;
  // 상업적으로 이용 가능한 타 무료 외부소스를 사용하였습니다 (On / Off)
  freeExtraSource: string;
  // 타 무료 외부소스 목록
  freeExtraSourceList: string;
  // 상업적으로 이용 가능한 타 유로 외부소스를 사용하였습니다 (On / Off)
  payExtraSource: string;
  // 타 유료 외부소스 목록
  payExtraSourceList: string;
  // 동적 구성 요소가 포함되어 있습니다 (On / Off)
  dynamicElement: string;
  // 동의
  agreement: string;
};

// 저작권
export type CopyrightType = {
  isErrorFreeCopyright: boolean;
  isErrorPayCopyright: boolean;
  isErrorWarehouseCopyright: boolean;
  isErrorAgree: boolean;
  copyright: InitCopyrightType;
};

// 파일 구성들
export type FileConfigType = {
  id: number;
  name: string;
  size: number | string;
};

export type ModelType = {
  // 모델 id
  modelFileId?: number | null;
  // 모델 key
  modelKey: string;
  // 모델 이름
  modelName: string;
  // 모델 구성 정보들
  modelConfig: {
    value: number;
    label: string;
  }[];
};

export type GodoGoodsNoType = {
  // 원본 고도몰 상품 번호
  originGodoGoodsNo: number | string | null;
  // 원본 고도몰 상품 번호
  godoGoodsNo: number | string | null;
};

export type CropInfo = {
  imageOriginX?: number;
  imageOriginY?: number;
  imageWidth?: number;
  imageHeight?: number;
};

export type ImageType = {
  imageBlob?: Blob | null;
  // 크롭된 이미지 dataurl
  croppedImageDataUrl?: string;
  imageName?: string;
  imagePath?: string;
  cropInfo?: CropInfo | null;
};

export type MainImageType = {
  mainImage?: ImageType;
  thumbnail?: ImageType;
};

export type SubImageType = {
  subImage1?: ImageType;
  subImage2?: ImageType;
  subImage3?: ImageType;
};

// 업데이트 내역
export type UpdateHistoryType = {
  id: string;
  name: string;
  date: Date | string;
};

export type SalePriceType = {
  // 무료여부
  isFree?: boolean;
  // 단가
  salePrice?: string;
  // 판매가
  price?: string;
};

export type RelatedType = number;

export type PackageProductType = {
  // id: 해당 상품의 godoGoodsNo
  id: number;
  docId: string;
  title: string;
};

export type PackageType = {
  // 세트 상품 여부 parent: master child: child
  type: "parent" | "child";
  // 세트로 연관된 상품들
  parents?: PackageProductType[];
  children?: PackageProductType[];
} | null;

export type FundingType = {
  /** 목표 금액 */
  goalAmount: number;
  /** 시작일 */
  startDate: Date;
  /** 종료일 */
  endDate: Date;
  /** 상품 전달 일 */
  deliveryDate: Date;
};

export type ApplicationType = number;

export type ProductAllInfoType = {
  isDisp?: DisplayType["isDisp"];
  lang?: DisplayType["lang"];
  isEdit?: DisplayType["isEdit"];
  hasOpened?: DisplayType["hasOpened"];
  isModified?: DisplayType["isModified"];
  docId?: string;
  title?: string;
  description?: string;
  brandId?: BrandType["brandId"];
  brandName?: BrandType["brandName"];
  status?: ProductStatus;
  newStatus?: ProductNewStatusType;
  version?: string;
  category?: number[];
  rejectComment?: CommentType["rejectComment"];
  updateRequest?: CommentType["updateRequest"];
  updateResponse?: CommentType["updateResponse"];
  commission?: number | string;
  contents?: string;
  isErrorFreeCopyright?: CopyrightType["isErrorFreeCopyright"];
  isErrorPayCopyright?: CopyrightType["isErrorPayCopyright"];
  isErrorWarehouseCopyright?: CopyrightType["isErrorWarehouseCopyright"];
  isErrorAgree?: CopyrightType["isErrorAgree"];
  copyright?: InitCopyrightType;
  dynamicItem?: string;
  extension?: number[];
  fileConfig?: FileConfigType[];
  modelFileId?: ModelType["modelFileId"];
  modelKey?: ModelType["modelKey"];
  modelName?: ModelType["modelName"];
  modelConfig?: ModelType["modelConfig"];
  originGodoGoodsNo?: GodoGoodsNoType["originGodoGoodsNo"];
  godoGoodsNo?: GodoGoodsNoType["godoGoodsNo"];
  keywords?: string;
  languages?: string[];

  mainImage?: MainImageType["mainImage"];
  thumbnail?: MainImageType["thumbnail"];
  subImage1?: SubImageType["subImage1"];
  subImage2?: SubImageType["subImage2"];
  subImage3?: SubImageType["subImage3"];

  updateHistory?: UpdateHistoryType[];
  isFree?: SalePriceType["isFree"];
  salePrice?: SalePriceType["salePrice"];
  price?: SalePriceType["price"];
  related?: RelatedType[];
  packageInfo?: PackageType;
  applications?: ApplicationType[];
  funding?: FundingType;
};
