import { categorySelector } from 'boards/DetailBoardWrite/recoil/selectors';
import React, { useContext } from 'react';
import { useRecoilState } from 'recoil';
import { Icon } from 'rsuite';
import context from '../../../provider/product';
import { CategoryItem, Title, RemoveButton } from './style';
export default (({ id, title }) => {
    const [category, setCategory] = useRecoilState(categorySelector);
    // 항목 삭제 클릭 이벤트 처리기 메소드 
    const onClickRemoveItem = () => {
        // 삭제 이후의 카테고리 정보 
        const categories = category.filter(x => x !== id);
        // 카테고리 저장 
        setCategory(categories);
    };
    return (
        <CategoryItem key={id}>
            <Title>
                {title}
            </Title>
            <RemoveButton onClick={onClickRemoveItem}>
                <Icon icon="close" />
            </RemoveButton>
        </CategoryItem>
    );
});
