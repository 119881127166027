import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Wrapper, Top, Contents, Board, Row, Column, SearchWrapper, SearchForm, SearchInput, SearchTypeCombo, NaviWrapper, WritePromotionButton, SearchButton, ColumnItem, RemovePromotionItemButton, ButtonWrap, ColumnLink } from '../style';
import { useTranslation } from 'react-i18next';
import PageNavigation from 'components/pageNavigation';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PROMOTION_LIST } from 'api/quries';
import dateFormat from 'dateformat';
import queryString from 'query-string';
import { REMOVE_PROMOTION } from 'api/mutations';
import { AppContext } from 'app';

export default ((props) => {
    const { history, match } = props;
    // confirm message 
    const { showConfirmMessage } = useContext(AppContext);
    // 현재 페이지 타입을 가져옵니다.
    const promotionDisplayLocation = match.params.displayLocation || "cosmic-deal";

    // 번역 도구 
    const { t } = useTranslation();
    // url 매개변수 객체 
    let urlParamObj = (() => {
        let obj = {
            // 페이지
            page: 1
        };

        // search 값이 존재할 경우 
        if (history?.location?.search) {
            // url 파라메터 객체 
            const urlParamObj = queryString.parse(history.location.search);

            obj.page = Number(urlParamObj.page) ? Number(urlParamObj.page) : obj.page;
            obj.title = urlParamObj.title ? urlParamObj.title : obj.title;
            obj.brandName = urlParamObj.brandName ? urlParamObj.brandName : obj.brandName;
        }
        for (let key in obj) {
            if (obj[key] === null || obj[key] === undefined)
                delete obj[key];
        }
        // 종료함 
        return obj;
    })();
    // 검색 타입
    const [searchType, setSearchType] = useState('brandName');
    // 검색 키워드 
    const [searchKeyword, setSearchKeyword] = useState('');
    // 선택된 id들
    const [selectedIds, setSelectedIds] = useState([]);

    const limit = 10;

    // 프로모션 항목 삭제하기
    const [removePromotionItem] = useMutation(REMOVE_PROMOTION);

    // 프로모션 리스트들 확보 
    const { data, loading, refetch } = useQuery(GET_PROMOTION_LIST, {
        fetchPolicy: "no-cache",
        variables: {
            page: urlParamObj.page,
            limit,
            title: urlParamObj.title,
            brandName: urlParamObj.brandName,
            type: 'cosmic'
        }
    });

    // 프로모션 항목 전체 선택 체크박스 클릭 이벤트 처리기 메소드
    const onClickAllSelectPromotionItemInput = () => {
        // 전체선택이 되어있지 않은경우 
        if (selectedIds.length !== data.getPromotions.length) {
            // 항목들 전체 선택 
            setSelectedIds(data.getPromotions.map(x => x.id));
            // 종료 
            return;
        }
        // 초기화 
        setSelectedIds([]);
    };
    // 프로모션 항목 삭제 버튼 클릭 이벤트 처리기 메소드
    const onClickRemovePromotionItemButton = async () => {
        // 선택된 항목이 존재하지 않을경우 
        if (selectedIds.length === 0)
            // 아무 동작 하지 않음 
            return;

        showConfirmMessage(
            t('deleteItem'),
            t('reallyDeleteItemCount', { count: selectedIds.length }),
            async () => {
                await removePromotionItem({
                    variables: {
                        ids: selectedIds
                    }
                });
                await refetch();
            }
        )
    };
    // 검색 버튼 클릭 이벤트 처리기 메소드 
    const onClickSearchButton = () => {
        // url 객체 
        let searchObj = { ...urlParamObj };
        // 검색키워드가 존재하지 않을경우 
        if (!searchKeyword)
            // 검색을 행하지 않음. 
            return;

        // 페이지 값 설정 
        searchObj.page = 1;
        // 검색 키워드 설정 
        searchObj[searchType] = searchKeyword;

        // 검색 쿼리 
        const query = queryString.stringify(searchObj);
        // 검색페이지로 이동 
        history.push({
            pathname: history.location.pathname,
            search: query
        });
    };
    return (
        <Wrapper>
            <Top>
                <ButtonWrap>
                    <RemovePromotionItemButton disabled={selectedIds.length === 0} onClick={onClickRemovePromotionItemButton}>
                        선택 삭제
                    </RemovePromotionItemButton>
                    <WritePromotionButton>
                        <NavLink to={`/manager/promotion/${promotionDisplayLocation}/write`}>
                            프로모션 생성
                        </NavLink>
                    </WritePromotionButton>
                </ButtonWrap>
            </Top>
            <Contents>
                <Board>
                    <Row isHead={true}>
                        <Column flex="0.08" align="center">
                            <input type="checkbox" checked={data && data.getPromotions && selectedIds.length === data.getPromotions.length} onChange={onClickAllSelectPromotionItemInput} />
                        </Column>
                        <Column flex="0.08">번호</Column>
                        <Column flex="0.08">상태</Column>
                        <Column flex="0.188">진행기간</Column>
                        <Column flex="0.28">작품명</Column>
                        <Column flex="0.292">작가명</Column>
                    </Row>
                    {!loading && data && data.getPromotions && data.getPromotions.map(x =>
                        <Row key={x.id}>
                            <Column flex="0.08" align="center">
                                <input type="checkbox"
                                    checked={selectedIds.filter(y => y === x.id).length > 0}
                                    onChange={() => {
                                        // 선택된 항목이 존재하지 않는경우 
                                        if (selectedIds.filter(y => y === x.id).length === 0) {
                                            // 선택된 항목 추가 
                                            setSelectedIds([...selectedIds, x.id])
                                            // 종료 
                                            return;
                                        }
                                        // 해당 항목 제외하고 설정 
                                        setSelectedIds(selectedIds.filter(y => y !== x.id));
                                    }} />
                            </Column>
                            <ColumnLink flex="0.92" href={`/manager/promotion/${promotionDisplayLocation}/write/${x.id}`}>
                                <Column flex="0.087">
                                    {x.id}
                                </Column>
                                <Column flex="0.087">{x.status.map(y => <ColumnItem>{y}</ColumnItem>)}</Column>
                                <Column flex="0.203">
                                    {x.periods.map(y =>
                                        <ColumnItem>
                                            {dateFormat(y.startDate, 'yyyy-mm-dd')} ~ {dateFormat(y.endDate, 'yyyy-mm-dd')}
                                        </ColumnItem>
                                    )}
                                </Column>
                                <Column flex="0.30">{x.brandName}</Column>
                                <Column flex="0.323">{x.title}</Column>
                            </ColumnLink>
                        </Row>
                    )}
                </Board>
                <SearchWrapper>
                    <SearchForm>
                        <SearchTypeCombo value={searchType} onChange={e => { setSearchType(e.target.value); }}>
                            <option value="brandName">필명</option>
                            <option value="title">작품 제목</option>
                        </SearchTypeCombo>
                        <SearchInput value={searchKeyword} onChange={e => { setSearchKeyword(e.target.value); }} />
                        <SearchButton onClick={onClickSearchButton}>{t('search')}</SearchButton>
                    </SearchForm>
                </SearchWrapper>
                <NaviWrapper>
                    {!loading && data && (
                        <PageNavigation
                            match={match}
                            totalPage={Math.ceil(data.getPromotionCount / limit)}
                            pathName={`/manager/promotion/${promotionDisplayLocation}`}
                            history={history}
                            secPathName={"/"}
                        />
                    )}
                </NaviWrapper>
            </Contents>
        </Wrapper>
    );
});