import React, { useState, useRef } from 'react';
import { Item, Value } from 'boards/DetailBoardWrite/style';
import { Message } from './style';
import Cropper from './cropper';

export default (({ url, setCropKey, cropInfo, setCropInfo }) => {
    // 완료여부 
    const [isComplete, setIsComplete] = useState(false);
    // 메세지 
    const [message, setMessage] = useState({
        type: false,
        txt: ''
    });
    // 이미지 
    const [image, setImage] = useState('');

    const cropperTag = useRef(null);

    const [croppedDataUrl, setCroppedDataUrl] = useState('');
    const [croppedBlob, setCroppedBlob] = useState('');

    // 메세지 표시하기 메소드
    const showMessage = (txt, type) => {
        // type이 정의되지 않았을 경우 
        if (type === undefined)
            type = false;

        // 메세지 표시 
        setMessage({
            type,
            txt
        });

        setTimeout(() => {
            // 2초 뒤 메세지 초기화 
            setMessage({
                ...message,
                txt: ''
            });
        }, 2000);
    };

    return (
        <Item>
            <Value>
                {message.txt && <Message type={message.type}>{message.txt}</Message>}
                {<Cropper
                    croppedImageBlob={croppedBlob}
                    setCroppedImageBlob={value => {
                        setCroppedBlob(value)
                    }}
                    croppedImageDataUrl={croppedDataUrl}
                    setCroppedImageDataUrl={value => {
                        setCroppedDataUrl(value)
                    }}
                    cropperTag={cropperTag}
                    disabled={false}
                    image={image}
                    url={url}
                    setImage={setImage}
                    isComplete={isComplete}
                    setComplete={setIsComplete}
                    setCropKey={setCropKey}
                    setCropInfo={setCropInfo}
                    cropInfo={cropInfo}
                    isShow={true}
                    setCompleteCallback={() => { }}
                    setFileCallback={() => { }}
                    message={message}
                    showMessage={showMessage}
                />}

            </Value>
        </Item>
    );
});