import { authOrigin } from "api";
import axios from "axios";
import dateFormat from "dateformat";

const getVirtualImg = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    let img = document.createElement("img");
    img.crossOrigin = "anonymous";
    img.src = src;

    img.onload = () => {
      resolve(img);
    };
  });
};

const getSignImg = async (sign) => {
  const result = await axios.post(authOrigin + "/auth/download", { id: sign });

  return getVirtualImg(
    process.env.STORAGE_ORIGIN + "/" + result.data.split("/").slice(3).join("/")
  );
};

export async function generateTermImage(
  {
    sign,
    name,
    contact,
    nationalIdNumber,
    planType,
    commissionRate,
    lang,
  }: any,
  toDataUrl = false,
  hideDate = false
) {
  const promiseTerm = getVirtualImg(
    process.env.STORAGE_ORIGIN +
      `/term/v2/${lang}/${lang === "ko" ? "term_full.png" : `_2_${lang}.jpg`}`
  );

  const promiseArtistSign = sign
    ? typeof sign === "number"
      ? getSignImg(sign)
      : getVirtualImg(sign)
    : null;

  const [term, artistSign] = await Promise.all([
    promiseTerm,
    promiseArtistSign,
  ]);
  const now = new Date();

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.width = term.width;
  canvas.height = term.height;
  context.font = "bold 30px serif";
  context.drawImage(term, 0, 0);

  if (lang === "ko") {
    if (artistSign) {
      context.drawImage(artistSign, 600, canvas.height - 200, 160, 50);
    }

    if (name) {
      context.fillText(name, 650, 340, 400);
      context.font = "bold 25px serif";
      context.fillText(name, 1335, canvas.height - 275, 150);
    }

    context.font = "bold 25px serif";

    if (contact) {
      context.fillText(contact, 1335, canvas.height - 205, 150);
    }

    if (nationalIdNumber) {
      context.fillText(nationalIdNumber, 1335, canvas.height - 140, 150);
    }

    if (!hideDate) {
      context.font = "bold 30px serif";
      context.fillText(
        now.getFullYear().toString(),
        600,
        canvas.height - 1217,
        45
      );
      context.fillText(
        (now.getMonth() + 1).toString(),
        790,
        canvas.height - 1217,
        45
      );
      context.fillText(now.getDate().toString(), 895, canvas.height - 1217, 45);
    }

    // if (planType) {
    //   context.fillText(planType, 1270, canvas.height - 875);
    // } else {
    //   context.fillStyle = "gray";
    //   context.font = "bold 20px serif";
    //   context.fillText("가입 승인 후 선택 반영", 1270, canvas.height - 875);
    // }

    // if (commissionRate) {
    //   context.fillText(commissionRate + "%", 1270, canvas.height - 827);
    // } else {
    //   context.fillStyle = "gray";
    //   context.font = "bold 20px serif";
    //   context.fillText("가입 승인 후 선택 반영", 1270, canvas.height - 827);
    // }
  }

  if (lang === "en") {
    context.font = "bold 30px serif";
    if (name) {
      context.fillText(name, 1170, 395, 300);
      context.fillText(name, 1040, 9820, 300); // 10130
    }

    // context.fillText(dateFormat(new Date(), "yyyy-mm-dd"), 750, 9180, 300); // 9680
  }

  if (lang === "zh") {
    if (name) {
      context.font = "30px serif";
      context.fillText(name, 900, 400, 400);
      context.font = "bold 30px serif";
      context.fillText(name, 1050, 9080, 300);
    }

    // context.fillText(dateFormat(new Date(), "yyyy-mm-dd"), 800, 8675, 300);
  }

  return new Promise((resolve, reject) => {
    try {
      if (toDataUrl) {
        resolve(canvas.toDataURL());
      } else {
        canvas.toBlob(resolve);
      }
    } catch (err) {
      reject(err);
    }
  });
}
