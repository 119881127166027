import { selector } from "recoil";
import { applicationsState } from "..";
import { ApplicationType } from "../types";

const applicationsSelector = selector<ApplicationType[]>({
  key: 'applicationsSelector',
  get:({get}) => get(applicationsState),
  set: ({set}, val) => set(applicationsState, val),
});

export default applicationsSelector;
