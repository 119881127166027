import { commissionSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import ACON from 'lib/global';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Item, Title, Input, Value } from '../../style';

export default ((props) => {
    // 번역도구 
    const { t } = useTranslation();
    const [atom, setAtom] = useRecoilState(commissionSelector);

    // input 태그 변경 이벤트 처리기 메소드 
    const onChange = (e) => {
        // 숫자로 변환한 값
        const value = ACON.OnlyNumber(e.target.value);
        // 입력한 값이 100보다 큰 경우 
        if (value > 100)
            // 종료한다.
            return;

        // 100보다 작은 숫자만 입력 가능함 
        setAtom(e.target.value);
    };

    return (
        <Item>
            <Title admin={true}>
                {t("aconCommition")}</Title>
            <Value>
                <Input
                    maxLength={3}
                    placeholder={t("input.commition")}
                    value={atom}
                    onChange={onChange}
                />
            </Value>
        </Item>
    );
});