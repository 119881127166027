import Modal from "acon-mui/components/Modal";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { promptModalVisibleState, statusState } from "signup/SignUp.atoms";

export default function PromptModal() {
  const history = useHistory();
  const { t } = useTranslation();
  const [status] = useRecoilState(statusState);
  const [promptModalVisible] = useRecoilState(promptModalVisibleState);

  const [isOpen, setOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [isLeave, setLeave] = useState(false);

  const handleModal = (nextLocation, action) => {
    if (!nextLocation?.pathname.includes('signup')) {
      setOpen(true);
      setNextLocation(nextLocation);
    }
    return isLeave;
  };

  useEffect(() => {
    if (isLeave) {
      return history.push(nextLocation.pathname)
    }
  }, [isLeave, nextLocation, history]);

  // 한국어 페이지 가입 유형 고르는 부분에서는 prompt 없도록 설정.
  if (status < 1 || !promptModalVisible) return <></>;
  return (
    <>
      <Prompt when={true} message={handleModal} />
      <Modal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title={t('signupModal.inprogress')}
        description={t('signupModal.warning')}
        leftButtonText={t('Yes.label')}
        onClickLeftButton={() => {
          setOpen(false);
          setLeave(true);
        }}
        rightButtonText={t('signupModal.continue')}
        onClickRightButton={() => setOpen(false)}
      />
    </>
  );
};
