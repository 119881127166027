import ACON from "lib/global";
import { DefaultValue, selector } from "recoil";
import { salePriceState } from "..";
import { SalePriceType } from "../types";

const salePriceSelector = selector<SalePriceType>({
  key: 'salePriceSelector',
  get:({get}) => get(salePriceState),
  set: ({get, set}, val) => {
    const prevState = get(salePriceState);
    if (!(val instanceof DefaultValue)) {
      const { isFree, price, salePrice } = val;
      set(salePriceState, {
        ...prevState,
        isFree,
        ...(price && {
          price: ACON.AddCommas(ACON.OnlyNumber(price)),
        }),
        ...(salePrice && {
          salePrice: salePrice === '' ? '': ACON.AddCommas(ACON.OnlyNumber(salePrice)),
        }),
      });
    }
  },
});

export default salePriceSelector;
