import { Box, Button, Modal, Typography } from '@mui/material';
import { packageSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import { PackageProductType } from 'boards/DetailBoardWrite/recoil/types';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Box as ModalContainer } from '../../../style';
import PackageTable from '../PackageTable';
import { PackageSetButtonStyle } from '../style';
import ProductSelectDropdown from './ProductSelectDropdown';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const EditModal = ({ isOpen, onClose }: Props) => {
  const [packageState] = useRecoilState(packageSelector);
  const [products, setProducts] = useState<PackageProductType[]>([]);
  const [state, setState] = useRecoilState(packageSelector);

  const onSave = () => {
    setState({ ...state, children: products, });
    onClose();
  }; 

  useEffect(() => {
    if (packageState && packageState?.children) {
      setProducts(packageState.children);
    }
  }, [packageState]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      componentsProps={{
        backdrop: {
          style: {
            background: '#000000B2',
          }
        }
      }}
    >
      <ModalContainer
        style={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '600px',
        }}
      >
        <Box display="flex" alignItems="center">
          <Box 
            width="4px" 
            height="4px" 
            borderRadius="2px" 
            bgcolor="#FF00C3"
          />
          <Typography
            ml="10px"
            fontSize="14px"
            fontWeight="700"
            lineHeight="20px"
            color="#333"
          >
            세트 구성 상품 설정
          </Typography>
        </Box>
        <Box height="40px" overflow="visible">
          <ProductSelectDropdown
            products={products}
            setProducts={setProducts}
          />
        </Box>
        <PackageTable
          products={products}
          setProducts={setProducts}
          isEditable
          style={{ marginTop: '36px' }} 
        />
        <Button 
          onClick={onSave} 
          disableRipple
          sx={{ 
            ...PackageSetButtonStyle, 
            marginTop: '36px',
            marginBottom: '24px' 
          }}
        >
          저장
        </Button>
      </ModalContainer>
    </Modal>
  );
};

export default EditModal;
