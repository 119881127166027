import { Flex } from 'boards/DetailBoardWrite/style';
import { WhiteButton } from 'components/button';
import styled from 'styled-components'

export const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${(props: { isFixed?: boolean; isadmin?: string; }) => props.isFixed ? 
        `position: fixed;
        z-index: 100;
        box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
        left: 0;
        top: ${props.isadmin ? '115px' : '65px'};
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        background-color: white;
        padding-top: 10px;
        padding-bottom: 10px;
        ` : 
        ``}
`;

export const ItemWrap = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    max-width: 586px;
    margin: 0 auto;
`;

export const EditButton = styled(WhiteButton)`
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    text-align: center;
    justify-content: center;
`;