import { LanguageEnum } from "boards/DetailBoardWrite/recoil/types";
import { LanguageCodeEnum } from "generated/graphql";

export default function convertLanguageCode(lang: LanguageEnum): LanguageCodeEnum {
  if (lang === LanguageEnum.Ja) {
    return LanguageCodeEnum.Ja;
  }
  if (lang === LanguageEnum.Zh) {
    return LanguageCodeEnum.Zh;
  }
  if (lang === LanguageEnum.En) {
    return LanguageCodeEnum.En;
  }
  if (lang === LanguageEnum.Ko) {
    return LanguageCodeEnum.Ko;
  }
}