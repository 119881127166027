import React, { useState, useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router';
import RcSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import { Wrap } from './style';
import { AppContext } from 'app';
import { GET_PROD_LANGUAGE } from 'api/quries';
import { useQuery } from '@apollo/client';
import { STATUS_APPROVED, STATUS_DRAFT } from 'boards/DetailBoardWrite/constants';
import { useRecoilState } from 'recoil';
import { displaySelector, languagesSelector, statusSelector } from 'boards/DetailBoardWrite/recoil/selectors';

export default (({ isAdmin }) => {
    const { showConfirmMessage } = useContext(AppContext);
    const [status] = useRecoilState(statusSelector);
    const [display] = useRecoilState(displaySelector);
    const [languages, setLanguages] = useRecoilState(languagesSelector);
    // URL 파생 정보 
    const { lang, docId } = useParams<{ docId?: string; lang?: string; }>();

    const { data } = useQuery(GET_PROD_LANGUAGE, {
        variables: {
            docId
        },
        skip: !docId,
        fetchPolicy: "no-cache",
    });

    // history 
    const history = useHistory();
    // 번역도구 
    const { t } = useTranslation();

    const langItems = [
        { value: 'ko', label: '한국어' },
        { value: 'en', label: 'English' },
        { value: 'cn', label: '中文' },
        { value: 'jp', label: '日本語' }
    ];

    const [langs, setLangs] = useState([]);

    useEffect(() => {
        // 데이터가 존재하지 않을경우 
        if (!data)
            // 종료 
            return;

        // 데이터 조립 
        setLanguages(data.productLanguages);
    }, [data]);

    useEffect(() => {
        // 언어 배열 
        const langs = [
            { value: 'ko', label: '한국어' },
            { value: 'en', label: 'English' },
            { value: 'cn', label: '中文' },
            { value: 'jp', label: '日本語' }
        ].map((x: { value: string; label: string; isDisabled?: boolean; }) => {
            // 언어 비활성화 여부 
            const isDisabled = languages.filter(y => y === x.value).length === 0;

            if (isDisabled)
                x.isDisabled = true;

            return x;
        });

        setLangs(langs);
    }, [languages]);

    // select 태그 변경 이벤트 처리기 메소드 
    const onChange = (itemObj) => {
        // 경로를 변경하는 메소드입니다. 
        const changeUrl = () => {
            // 이동 될 경로 
            const toPushUrl = `/goods/write/${itemObj.value}${docId ? `/${docId}` : ''}`;
            // 경로 변경 
            history.push(toPushUrl);
        };

        // 작가가 상태가 완료된 경우 
        if (!isAdmin && status === STATUS_APPROVED) {
            // 경로 변경 
            changeUrl();
            // 종료 
            return;
        }

        // 외에 경우에는 내용이 변경될 여지가 있어 팝업을 표시해준다.
        showConfirmMessage(
            t("toPageMove"),
            t("ifNotSaveRemoveContents"),
            changeUrl
        );
    };

    const isUseUser = (!isAdmin && ([STATUS_APPROVED, STATUS_DRAFT].includes(status) || display.isEdit));
    return (
        <Wrap>
            {((data && data.productLanguages) || isUseUser) && <RcSelect
                {...{
                    value: (isUseUser ? langItems : langs).find(x => x.value === lang),
                    isMulti: false,
                    options: isUseUser ? langItems : langs,
                    onChange
                }}
            />}
        </Wrap>
    );
});