import { useQuery } from "@apollo/client";
import { GET_APPLICATIONS } from "api/quries";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RcSelect from "react-select";
import { Item, Title, Value } from "../../style";
import { UnderButton } from "boards/DetailBoardWrite/component/form";
import ApplicationList from "./applicationList";
import { useRecoilState } from "recoil";
import { applicationsSelector } from "boards/DetailBoardWrite/recoil/selectors";

export default () => {
  // 번역도구
  const { t } = useTranslation();
  const [applicationsState, setApplicationsState] =
    useRecoilState(applicationsSelector);
  const [isShowModal, setIsShowModal] = useState(false);
  const { data, loading, refetch } = useQuery(GET_APPLICATIONS);

  const applicationArr = data ? data.applications : [];

  // 로딩중 여부
  const isLoading = loading || !data || !applicationArr;

  // state에 선택한 응용프로그램 추가: RcSelect.value에서 Application 타입으로 역변환
  const handleRcChange = (applicationArr) => {
    const value: number[] = applicationArr
      ? applicationArr.map((app) => {
          return Number(app.value);
        })
      : [];

    setApplicationsState(value);
  };

  // 리스트 추가하기 버튼 클릭 이벤트 처리기 메소드
  const handleAddListButton = () => {
    setIsShowModal(true);
  };

  // 전체 응용프로그램 목록을 알파벳순으로 정렬: RcSelect.value 타입으로 변환
  const allApplications = applicationArr
    ?.map((app) => {
      return {
        value: app.id,
        label: app.name,
      };
    })
    .sort((a, b) => a.label?.localeCompare(b.label));

  // 확장자가 변하면 따라 들어온다
  const applications = applicationsState?.slice() ?? []; // Array<{ id: number; name: string }>

  // 상품에 귀속된 응용프로그램 목록: RcSelect.value 타입으로 변환
  const productApplications = applicationArr
    .filter((app) => applications.includes(app.id))
    .map((app) => {
      return {
        value: app.id,
        label: app.name,
      };
    });

  return (
    <Item>
      <Title admin={true}>
        {t("goods.application")}
        <UnderButton onClick={handleAddListButton}>
          {t("addAndModifyList")}
        </UnderButton>
      </Title>
      <Value>
        {!isLoading && (
          <RcSelect
            placeholder={t("goods.application")}
            value={productApplications}
            isMulti={true}
            options={allApplications}
            onChange={handleRcChange}
          />
        )}
      </Value>
      <ApplicationList
        allApplications={allApplications}
        isShown={isShowModal}
        onClose={() => {
          setIsShowModal(false);
        }}
        refetch={refetch}
      />
    </Item>
  );
};
