import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import convertLanguageCode from 'utils/convertLanguageCode';
import { TableCellStyle, TableHeadStyle } from './style';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LanguageEnum, PackageProductType } from 'boards/DetailBoardWrite/recoil/types';

interface Props {
  products: PackageProductType[];
  setProducts?: (val: PackageProductType[]) => void | undefined;
  isEditable?: boolean;
  style?: React.CSSProperties;
}

const PackageTable = ({ 
  products,
  setProducts = (val: PackageProductType[]) => {},
  isEditable = false,
  style,
}: Props) => {
  const { lang } = useParams<{ lang?: LanguageEnum }>();
  const height = useMemo(() => {
    const h = isEditable ? 33 : 23;
    if (products.length <= 5) {
      return `${products.length * h}px`;
    } else {
      return `${5 * h}px`;
    }
  }, [products, isEditable]);

  if (products.length === 0) return <></>;
  return (
    <TableContainer sx={{ overflow: 'hidden', ...style }}>
      <Table padding='none'>
        <TableHead>
          <TableRow>
            <TableCell width="98px" sx={TableHeadStyle(isEditable)}>
              상품번호
            </TableCell>
            <TableCell sx={TableHeadStyle(isEditable)}>
              노출 제목
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Box
        height={height}
        sx={{ 
          overflowY: 'auto',
          pr: (isEditable && products.length > 5) ? '5px': '0',
          "&::-webkit-scrollbar": {
            width: '8px',
          },
          "&::-webkit-scrollbar-thumb": {
            background: '#c1c1c1',
            borderRadius: '4px',
          },
          "&::-webkit-scrollbar-track": {
            background: 'rgba(193,193,193,0.5)',
            borderRadius: '4px',
            boxShadow: 'none'
          },
        }}
      >
        <Table padding='none' sx={{ direction: 'ltr' }}>
          <colgroup>
            <col width="98px" />
            <col/>
            <col width="14px" />
          </colgroup>
          <TableBody>
            {products.map((val: PackageProductType) => (
              <TableRow  key={val.id}>
                <TableCell 
                  sx={{ 
                    ...TableCellStyle(isEditable), 
                    textDecoration: 'underline' 
                  }}
                >
                  <a href={`https://www.acon3d.com/${convertLanguageCode(lang)}/toon/product/${val.id}`} target="_blank" rel="noopener noreferrer">
                    {val.id}
                  </a>
                </TableCell>
                <TableCell sx={TableCellStyle(isEditable)}>
                  {val.title}
                </TableCell>
                {isEditable && (
                  <TableCell
                    width='fit-content'
                    sx={TableCellStyle(isEditable)}
                  >
                    <Box
                      component="button"
                      type="button"
                      onClick={() => {
                        const result = products.filter((product: PackageProductType) => val.id !== product.id);
                        setProducts(result);
                      }}
                      bgcolor="transparent"
                      padding={0}
                      width="14px"
                      height="14px"
                    >
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8334 1.16675L1.16675 12.8334" stroke="#333333" strokeWidth="1.25"/>
                        <path d="M12.8334 12.8334L1.16675 1.16675" stroke="#333333" strokeWidth="1.25"/>
                      </svg>
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
};

export default PackageTable;