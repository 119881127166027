import { LanguageCodeEnum } from "generated/graphql";

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const PATH_PAGE = {
  root: "/",
  user: "/user",
  userSettle: "/userSettle",
  goods: "/goods",
  settle: "/manager/settle",
};

export const PATH_GUEST = {
  root: "/",
  signin: "/",
  signup: (lang: string) => `/signup/${lang}`,
};

export const PATH_PRODUCT = {
  // root: PATH_PAGE.goods
  write: (lang: string) => `${PATH_PAGE.goods}/write/${lang}`,
};

export const PATH_USER = {
  root: PATH_PAGE.user,
  settle: PATH_PAGE.userSettle,
  view: (id: string) => path(PATH_PAGE.user, `/${id}`),
};

export const PATH_SETTLE = {
  root: PATH_PAGE.settle,
};
