import { New_Product_Status, ProductStatus } from "generated/graphql";
import { DefaultValue, selector } from "recoil";
import {
  brandState,
  categoryState,
  commentState,
  commissionState,
  contentsState,
  copyrightState,
  descriptionState,
  displayState,
  docIdState,
  dynamicItemState,
  extensionState,
  fileConfigState,
  godoGoodsNoState,
  keywordsState,
  languagesState,
  mainImageState,
  modelState,
  relatedState,
  salePriceState,
  statusState,
  subImageState,
  titleState,
  updateHistoryState,
  versionState,
  applicationsState,
  packageState,
  fundingState,
  newStatusState,
} from "..";
import { ProductAllInfoType } from "../types";

const productAllInfoSelector = selector<ProductAllInfoType>({
  key: "productAllInfoSelector",
  get: ({ get }) => {
    const { isDisp, lang, isEdit, hasOpened, isModified } = get(displayState);
    const docId = get(docIdState);
    const title = get(titleState);
    const description = get(descriptionState);
    const { brandId, brandName } = get(brandState);
    const status = get(statusState);
    const newStatus = get(newStatusState);
    const version = get(versionState);
    const category = get(categoryState);
    const { rejectComment, updateRequest, updateResponse } = get(commentState);
    const commission = get(commissionState);
    const contents = get(contentsState);
    const funding = get(fundingState);

    const {
      isErrorFreeCopyright,
      isErrorPayCopyright,
      isErrorWarehouseCopyright,
      isErrorAgree,
      copyright,
    } = get(copyrightState);

    const dynamicItem = get(dynamicItemState);
    const extension = get(extensionState);
    const fileConfig = get(fileConfigState);
    const { modelFileId, modelKey, modelName, modelConfig } = get(modelState);
    const { originGodoGoodsNo, godoGoodsNo } = get(godoGoodsNoState);
    const keywords = get(keywordsState);
    const languages = get(languagesState);

    const { mainImage, thumbnail } = get(mainImageState);

    const { subImage1, subImage2, subImage3 } = get(subImageState);

    const updateHistory = get(updateHistoryState);
    const { isFree, salePrice, price } = get(salePriceState);
    const related = get(relatedState);
    const packageInfo = get(packageState);
    const applications = get(applicationsState);

    return {
      isDisp,
      lang,
      isEdit,
      hasOpened,
      isModified,
      docId,
      title,
      description,
      brandId,
      brandName,
      status,
      newStatus,
      version,
      category,
      rejectComment,
      updateRequest,
      updateResponse,
      commission,
      contents,
      isErrorFreeCopyright,
      isErrorPayCopyright,
      isErrorWarehouseCopyright,
      isErrorAgree,
      copyright,
      dynamicItem,
      extension,
      fileConfig,
      modelFileId,
      modelKey,
      modelName,
      modelConfig,
      originGodoGoodsNo,
      godoGoodsNo,
      keywords,
      languages,
      mainImage,
      thumbnail,
      subImage1,
      subImage2,
      subImage3,
      updateHistory,
      isFree,
      salePrice,
      price,
      related,
      packageInfo,
      applications,
      funding,
    };
  },

  set: ({ get, set }, val) => {
    if (!(val instanceof DefaultValue)) {
      const {
        isDisp,
        lang,
        isEdit,
        hasOpened,
        isModified,
        docId,
        title,
        description,
        brandId,
        brandName,
        status,
        newStatus,
        version,
        category,
        rejectComment,
        updateRequest,
        updateResponse,
        commission,
        contents,
        isErrorFreeCopyright,
        isErrorPayCopyright,
        isErrorWarehouseCopyright,
        isErrorAgree,
        copyright,
        dynamicItem,
        extension,
        fileConfig,
        modelFileId,
        modelKey,
        modelName,
        modelConfig,
        originGodoGoodsNo,
        godoGoodsNo,
        keywords,
        languages,

        mainImage,
        thumbnail,
        subImage1,
        subImage2,
        subImage3,

        updateHistory,
        isFree,
        salePrice,
        price,
        related,
        packageInfo,
        applications,
        funding,
      } = val;

      if (
        typeof isDisp === "boolean" ||
        typeof lang === "string" ||
        typeof isEdit === "boolean" || 
        typeof isModified === 'boolean'
      ) {
        set(displayState, {
          ...(typeof isDisp === "boolean" && { isDisp }),
          ...(typeof lang === "string" && { lang }),
          ...(typeof isEdit === "boolean" && { isEdit }),
          ...(typeof hasOpened === "boolean" && { hasOpened }),
          ...(typeof isModified === 'boolean' && { isModified }),
        });
      }
      if (typeof docId === "string") {
        set(docIdState, docId);
      }
      if (typeof title === "string") {
        set(titleState, title);
      }
      if (typeof description === "string") {
        set(descriptionState, description);
      }
      if (typeof brandId === "number" || typeof brandName === "string") {
        const prevState = get(brandState);
        set(brandState, {
          ...prevState,
          ...(typeof brandId === "number" && { brandId }),
          ...(typeof brandName === "string" && { brandName }),
        });
      }
      if (Object.values(ProductStatus).includes(status)) {
        set(statusState, status);
      }
      if (
        Object.values(New_Product_Status).includes(newStatus as any)
      ) {
        set(newStatusState, newStatus);
      }
      if (typeof version === "string") {
        set(versionState, version);
      }
      if (Array.isArray(category)) {
        set(categoryState, category);
      }
      if (
        typeof rejectComment === "string" ||
        typeof updateRequest === "string" ||
        typeof updateResponse === "string"
      ) {
        const prevState = get(commentState);
        set(commentState, {
          ...prevState,
          ...(typeof rejectComment === "string" && { rejectComment }),
          ...(typeof updateRequest === "string" && { updateRequest }),
          ...(typeof updateResponse === "string" && { updateResponse }),
        });
      }
      if (typeof commission === "string") {
        set(commissionState, commission);
      }
      if (typeof contents === "string") {
        set(contentsState, contents);
      }
      if (
        typeof isErrorFreeCopyright === "boolean" ||
        typeof isErrorPayCopyright === "boolean" ||
        typeof isErrorWarehouseCopyright === "boolean" ||
        typeof isErrorAgree === "boolean" ||
        typeof copyright === "object"
      ) {
        const prevState = get(copyrightState);
        set(copyrightState, {
          ...prevState,
          ...(typeof isErrorFreeCopyright === "boolean" && {
            isErrorFreeCopyright,
          }),
          ...(typeof isErrorPayCopyright === "boolean" && {
            isErrorPayCopyright,
          }),
          ...(typeof isErrorWarehouseCopyright === "boolean" && {
            isErrorWarehouseCopyright,
          }),
          ...(typeof isErrorAgree === "boolean" && { isErrorAgree }),
          ...(typeof copyright === "object" && {
            copyright: {
              ...prevState.copyright,
              ...copyright,
            },
          }),
        });
      }
      if (typeof dynamicItem === "string") {
        set(dynamicItemState, dynamicItem);
      }
      if (Array.isArray(extension)) {
        set(extensionState, extension);
      }
      if (Array.isArray(fileConfig)) {
        set(fileConfigState, fileConfig);
      }
      if (
        typeof modelFileId === "number" ||
        typeof modelKey === "string" ||
        typeof modelName === "string" ||
        Array.isArray(modelConfig)
      ) {
        const prevState = get(modelState);
        set(modelState, {
          ...prevState,
          ...(typeof modelFileId === "number" && {
            modelFileId,
          }),
          ...(typeof modelKey === "string" && {
            modelKey,
          }),
          ...(typeof modelName === "string" && {
            modelName,
          }),
          ...(Array.isArray(modelConfig) && {
            modelConfig,
          }),
        });
      }
      if (
        (typeof originGodoGoodsNo === "string" ||
          typeof originGodoGoodsNo === "number") &&
        (typeof godoGoodsNo === "string" ||
          typeof originGodoGoodsNo === "number")
      ) {
        set(godoGoodsNoState, { originGodoGoodsNo, godoGoodsNo });
      }
      if (typeof keywords === "string") {
        set(keywordsState, keywords);
      }
      if (Array.isArray(languages)) {
        set(languagesState, languages);
      }
      if (mainImage || thumbnail) {
        const prevState = get(mainImageState);
        set(mainImageState, {
          ...prevState,
          ...(mainImage && { mainImage }),
          ...(thumbnail && { thumbnail }),
        });
      }
      if (subImage1 || subImage2 || subImage3) {
        const prevState = get(subImageState);
        set(subImageState, {
          ...prevState,
          ...(subImage1 && { subImage1 }),
          ...(subImage2 && { subImage2 }),
          ...(subImage3 && { subImage3 }),
        });
      }

      if (Array.isArray(updateHistory)) {
        set(updateHistoryState, updateHistory);
      }
      if (
        typeof isFree === "boolean" ||
        typeof salePrice === "string" ||
        typeof price === "string"
      ) {
        const prevState = get(salePriceState);
        set(salePriceState, {
          ...prevState,
          ...(typeof isFree === "boolean" && { isFree }),
          ...(typeof salePrice === "string" && { salePrice }),
          ...(typeof price === "string" && { price }),
        });
      }
      if (Array.isArray(related)) {
        set(relatedState, related);
      }
      if (
        packageInfo &&
        (packageInfo.type === "parent" ||
          packageInfo.type === "child" ||
          Array.isArray(packageInfo.parents) ||
          Array.isArray(packageInfo.children))
      ) {
        set(packageState, {
          type: packageInfo.type,
          ...(packageInfo.parents && { parents: packageInfo.parents }),
          ...(packageInfo.children && { children: packageInfo.children }),
        });
      }
      if (Array.isArray(applications)) {
        set(applicationsState, applications);
      }
      if (funding) {
        set(fundingState, funding);
      }
    }
  },
});

export default productAllInfoSelector;
