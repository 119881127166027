import gql from "graphql-tag";

// 로그인 정보 가져오기 쿼리
export const GET_IS_LOGIN = gql`
  query {
    isLogin
  }
`;

export const GET_BRANDS_NOT_HAVING_USER = gql`
  query {
    getBrandsNotHavingUser {
      id
      godoBrandCd
      name
    }
  }
`;

// 브랜드 정보들 가져오기 쿼리
export const GET_BRANDS = gql`
  query GET_BRANDS {
    getBrands {
      id
      godoBrandCd
      name
    }
  }
`;

export const GET_IS_VALID_GODO_GOODS_NO = gql`
  query GET_IS_VALID_GODO_GOODS_NO($docId: String, $godoGoodsNo: Int!) {
    isValidGodoGoodsNo(docId: $docId, godoGoodsNo: $godoGoodsNo)
  }
`;

export const GET_BRANDS_ALL = gql`
  query GET_BRANDS_ALL {
    getBrands {
      id
      name
      godoBrandCd
      item {
        name
        language {
          code
        }
      }
    }
  }
`;

export const GET_MAINGROUP_ALL = gql`
  query GET_MAINGROUP_ALL {
    getMainGroups {
      id
      name
      godoSno
      item {
        name
        language {
          code
        }
      }
    }
  }
`;

// 카테고리 정보들 가져오기 쿼리
export const GET_CATEGORIES = gql`
  query GET_CATEGORIES($lang: String!) {
    getCategoriesByLang(lang: $lang) {
      id
      parent
      i18n {
        name
      }
    }
  }
`;

// 공지사항 정보들 가져오기 쿼리
export const GET_NOTICE = gql`
  query GetNotice($input: searchInput!) {
    noticeBoards(input: $input) {
      _id
      title
      body
      date
      visible
      endDate
      updateDate
      register
      lang
      status
    }
    noticeBoardTotal(input: $input)
  }
`;

const MYPAGE_FIELDS = `
  name
  type
  subType
  typeString
  subTypeString
  subTypeStringList
  companyName
  account
  contact
  contactAdditional
  snsUrl
  accept {
    statusString
    statusStringList
    status
    comment
    modifyStatus
    modifyRejectComment
    modifyApproveComment
  }
  brand {
    id
    name
    godoBrandCd
    item {
      language {
        id
        code
      }
      name
    }
  }
  reference {
    id
    url
  }
  settle {
    bankName
    bankAccountNumber
    bankAccountOwner
    nationalIdNumber
    bankId
    country
    signLang
    bank {
      name
    }
    bankBook {
      id
      fullName
      path
    }
    idCard {
      id
      fullName
      path
      size
    }
    limitedTaxApl {
      id
      fullName
      path
      size
    }
    sign {
      id
      fullName
    }
  }
`;

export const NOTIFICATIONS = gql`
  query {
    notifications {
      type
      message
    }
  }
`;

export const USER_ACCEPT_STATUS_NUMBER = gql`
  query ($status: Float!, $modifyStatus: Float) {
    userAcceptStatusNumber(status: $status, modifyStatus: $modifyStatus)
  }
`;

export const USER_CHANGE = gql`
  query USER_CHANGE {
    user: signedUser {
      account
      contact
      brand {
        name
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser($id: Float!) {
    user(id: $id) {
      name
      companyName
      accept {
        statusStringList
        status
        comment
        modifyStatus
        modifyRejectComment
        modifyApproveComment
        isModifyPayment
      }
      brand {
        id
        name
        godoBrandCd
        item {
          language {
            id
            code
          }
          name
        }
      }
      settle {
        nationalIdNumber
        country
        signLang
        sign {
          id
        }
      }
      reference {
        id
        url
      }
      type
      subType
      typeString
      subTypeString
      account
      contact
      contactAdditional
      snsUrl
    }
    termAgree(id: $id) {
      uploadFileId
      agreeAt
      file {
        fullName
        path
      }
    }
    userPlan(id: $id) {
      type
      isExclusive
      commissionRate
    }
  }
`;

export const GET_SIGNED_USER = gql`
  query GetUser {
    user: signedUser {
      ${MYPAGE_FIELDS}
    }
    termAgree {
      uploadFileId
      agreeAt
      file {
        fullName
        path
      }
    }
    userPlan {
      type
      isExclusive
      commissionRate
    }
  }
`;

export const GET_USER_SETTLE = gql`
  query getUserSettle($id: Float!, $password: String!) {
    user: userSettle(id: $id, password: $password) {
      type
      typeString
      typeStringList
      subType
      subTypeStringList
      name
      companyName
      settle {
        bankName
        bankAccountNumber
        bankAccountOwner
        nationalIdNumber
        bankId
        bankBook {
          id
          fullName
          path
        }
        idCard {
          id
          fullName
          path
          size
        }
        limitedTaxApl {
          id
          fullName
          path
          size
        }
        bank {
          name
        }
      }
    }
  }
`;

// 창작자 가져오기 쿼리
export const GET_USER_SEARCH = gql`
  query GetUsers(
    $keyword: String!
    $type: String!
    $offset: Float!
    $limit: Float!
  ) {
    getUsers(keyword: $keyword, type: $type, offset: $offset, limit: $limit) {
      count
      rows {
        # _id
        id
        account
        name
        brand {
          id
          name
          item {
            language {
              id
              code
            }
            name
          }
        }
        accept {
          status: statusString
          modifyStatus: modifyStatusString
          statusStringList
        }
        settle {
          country
        }
        # brandId
        # name
        # bankName
        # bankAccount
        # bankAccountOwner
      }
    }
    # userTotal(keyword: $keyword, type: $type)
  }
`;

export const GET_PRODUCT_LIST = gql`
  query getDisplayProducts(
    $title: String
    $contents: String
    $godoGoodsNo: Int
    $status: [String!]
    $page: Int!
    $limit: Int!
    $brandIds: [Int!]
    $createStartDate: String
    $createEndDate: String
    $requestStartDate: String
    $requestEndDate: String
  ) {
    getDisplayProductsTotal(
      title: $title
      contents: $contents
      godoGoodsNo: $godoGoodsNo
      status: $status
      brandIds: $brandIds
      createStartDate: $createStartDate
      createEndDate: $createEndDate
      requestStartDate: $requestStartDate
      requestEndDate: $requestEndDate
    )
    getDisplayProducts(
      title: $title
      contents: $contents
      godoGoodsNo: $godoGoodsNo
      status: $status
      page: $page
      limit: $limit
      brandIds: $brandIds
      createStartDate: $createStartDate
      createEndDate: $createEndDate
      requestStartDate: $requestStartDate
      requestEndDate: $requestEndDate
    ) {
      id
      godoGoodsNo
      mainImage
      originLanguage
      titles {
        title
        lang
      }
      requestDates {
        requestDate
        lang
      }
      created
      brand
      status
      isEdit
      isOpen
    }
  }
`;

export const GET_SETTLE_ALL = gql`
  query SETTLE(
    $startDate: String
    $endDate: String
    $paymentStartDate: String
    $paymentEndDate: String
    $settleStartDate: String
    $settleEndDate: String
  ) {
    getSettleAllListV2(
      startDate: $startDate
      endDate: $endDate
      settleStartDate: $settleStartDate
      settleEndDate: $settleEndDate
      paymentStartDate: $paymentStartDate
      paymentEndDate: $paymentEndDate
    ) {
      sno
      brandCd
      orderNo
      orderMemo
      licenseType
      orderName
      goodsNm
      paymentDt
      settleDt
      regDt
      goodsPrice
      goodsDcPrice
      couponGoodsDcPrice
      addField
    }
  }
`;

export const GET_SETTLE_PRICE = gql`
  query SETTLEPRICE(
    $brandId: String!
    $isFree: Boolean
    $goodsNm: String
    $penNm: String
    $startDate: String
    $endDate: String
    $paymentStartDate: String
    $paymentEndDate: String
    $settleStartDate: String
    $settleEndDate: String
  ) {
    getSettleAllPrice(
      brandId: $brandId
      isFree: $isFree
      startDate: $startDate
      endDate: $endDate
      goodsNm: $goodsNm
      penNm: $penNm
      settleStartDate: $settleStartDate
      settleEndDate: $settleEndDate
      paymentStartDate: $paymentStartDate
      paymentEndDate: $paymentEndDate
    )
  }
`;
export const GET_SETTLE = gql`
  query SETTLE(
    $brandId: String!
    $page: Int
    $limit: Int
    $isFree: Boolean
    $startDate: String
    $endDate: String
    $goodsNm: String
    $penNm: String
    $paymentStartDate: String
    $paymentEndDate: String
    $settleStartDate: String
    $settleEndDate: String
  ) {
    getSettleCount(
      brandId: $brandId
      isFree: $isFree
      startDate: $startDate
      endDate: $endDate
      goodsNm: $goodsNm
      penNm: $penNm
      settleStartDate: $settleStartDate
      settleEndDate: $settleEndDate
      paymentStartDate: $paymentStartDate
      paymentEndDate: $paymentEndDate
    ) {
      totalCount
      searchCount
    }
    getSettleListV2(
      brandId: $brandId
      page: $page
      limit: $limit
      isFree: $isFree
      startDate: $startDate
      endDate: $endDate
      goodsNm: $goodsNm
      penNm: $penNm
      settleStartDate: $settleStartDate
      settleEndDate: $settleEndDate
      paymentStartDate: $paymentStartDate
      paymentEndDate: $paymentEndDate
    ) {
      sno
      brandCd
      orderNo
      orderMemo
      licenseType
      orderName
      goodsNm
      paymentDt
      settleDt
      regDt
      goodsPrice
      goodsDcPrice
      couponGoodsDcPrice
      addField
    }
  }
`;
export const GET_BURDEN = gql`
  query BURDEN(
    $couponPage: Int!
    $couponLimit: Int!
    $benefitPage: Int!
    $benefitLimit: Int!
  ) {
    getCouponBurdenCount
    getBenefitBurdenCount
    getCouponBurdenList(page: $couponPage, limit: $couponLimit) {
      sno
      name
      burden
    }
    getBenefitBurdenList(page: $benefitPage, limit: $benefitLimit) {
      sno
      name
      burden
    }
  }
`;
// 제품 가져오기 쿼리
export const GET_PRODUCT = gql`
  query getProducts(
    $reviewStatus: [String]!
    $lang: String!
    $keyword: String!
    $type: String!
    $page: Int!
    $limit: Int!
  ) {
    productTotal(
      reviewStatus: $reviewStatus
      lang: $lang
      keyword: $keyword
      type: $type
    )
    products(
      limit: $limit
      page: $page
      lang: $lang
      keyword: $keyword
      type: $type
    ) {
      _id
      date
      writerId
      brandId {
        _id
        id
        name
        trans {
          _id
          lang
          name
        }
      }
      godoGoodsNo
      data {
        _id
        title
        reviewStatus
      }
      openData {
        _id
        title
        reviewStatus
      }
    }
  }
`;

// 공지게시판 데이터 가져오기
export const GET_NOTICE_DATA = gql`
  query ($page: Int!, $limit: Int!, $type: String, $keyword: String) {
    getNotices(page: $page, limit: $limit, type: $type, keyword: $keyword) {
      id
      title
      body
      type {
        code
      }
      language {
        code
      }
      fixed
      created
      updated
    }
    getNoticesTotalCount(type: $type, keyword: $keyword)
  }
`;

// FAQ게시판 데이터 가져오기
export const GET_FAQ_DATA = gql`
  query ($page: Int!, $limit: Int!, $type: String, $keyword: String) {
    getFaqs(page: $page, limit: $limit, type: $type, keyword: $keyword) {
      id
      title
      body
      type {
        code
      }
      language {
        code
      }
      fixed
      created
      updated
    }
    getFaqsTotalCount(type: $type, keyword: $keyword)
  }
`;

// 팝업게시판 데이터 가져오기
export const GET_POPUP_DATA = gql`
  query ($page: Int!, $limit: Int!, $type: String, $keyword: String) {
    getPopups(page: $page, limit: $limit, type: $type, keyword: $keyword) {
      id
      title
      body
      language {
        code
      }
      startDate
      endDate
      location {
        url
      }
      allowHide
      created
      updated
    }
    getPopupsTotalCount(type: $type, keyword: $keyword)
  }
`;
export const GET_IS_USED_EMAIL = gql`
  query getIsUsedEmail($account: String!) {
    existsUser(account: $account)
  }
`;

export const GET_IS_USED_PEN_NAME = gql`
  query ($lang: String!, $name: String!) {
    isDuplicateBrandName(lang: $lang, name: $name)
  }
`;

export const GET_USER_PLAN = gql`
  query ($type: String!) {
    getTerm(type: $type) {
      contents
    }
    user: signedUser {
      id
      name
      companyName
      contact
      brand {
        godoBrandCd
      }
      settle {
        nationalIdNumber
        imageSign
      }
    }
    termAgree {
      agreeAt
      file {
        fullName
      }
    }
  }
`;

export const GET_TERM_ALL = gql`
  query {
    getTermAll {
      type
      contents
    }
  }
`;

export const GET_PROD_LANGUAGE = gql`
  query ProductLanguages($docId: String!) {
    productLanguages(docId: $docId)
  }
`;

export const GET_PROD_VERSION = gql`
  query ProductVersions($docId: String!, $lang: String!) {
    productVersions(docId: $docId, lang: $lang) {
      id
      major
      minor
      created
    }
  }
`;
export const GET_PROD_DIFF_ITEM = gql`
  query GetProduct(
    $docId: String!
    $isOpen: Boolean!
    $lang: String!
    $major: Int
    $minor: Int
  ) {
    getProduct(
      docId: $docId
      isOpen: $isOpen
      lang: $lang
      major: $major
      minor: $minor
    ) {
      docId
      language {
        code
      }
      isEdit
      brandId
      status
      godoGoodsNo
      title
      contents
      description
      price
      salePrice
      commission
      copyright
      keywords
      dynamicItem
      modelFileName
      model {
        id
      }
      modelConfigList {
        id
        name
      }
      fileUpdateList {
        id
        name
        date
      }
      fileConfigList {
        id
        name
        size
      }
      fileExtensionList {
        id
        name
      }
      imageList {
        cropX
        cropY
        cropWidth
        cropHeight
        type
        item {
          fullName
        }
      }
      categories {
        id
      }
      comments {
        type
        message
      }
      related {
        to
      }
      versions {
        contents
      }
    }
  }
`;

export const GET_PROD_ITEM = gql`
  query GetProductInfo($docId: String!, $isOpen: Boolean!, $lang: String!) {
    getProductInfo(docId: $docId, isOpen: $isOpen, lang: $lang) {
      docId
      language {
        code
      }
      isEdit
      brandId
      status
      godoGoodsNo
      title
      contents
      description
      price
      salePrice
      commission
      copyright
      keywords
      dynamicItem
      modelFileName
      model {
        id
      }
      modelConfigList {
        id
        name
      }
      fileUpdateList {
        id
        name
        date
      }
      fileConfigList {
        id
        name
        size
      }
      fileExtensionList {
        id
        name
        extensionConfig {
          id
        }
      }
      imageList {
        cropX
        cropY
        cropWidth
        cropHeight
        type
        item {
          fullName
        }
      }
      categories {
        id
      }
      comments {
        type
        message
      }
      related {
        to
      }
      applications {
        id
        name
      }
    }
  }
`;

export const GET_MODEL_CONFIG_ALL = gql`
  query {
    getModelConfigs {
      id
      i18n {
        name
        language {
          code
        }
      }
    }
  }
`;

export const BANKS = gql`
  query {
    banks {
      id
      name
    }
  }
`;

export const GET_DETAIL_PAGE_BANNERS_DATA = gql`
  query ($page: Int!, $limit: Int!) {
    getBanners(page: $page, limit: $limit) {
      id
      text
      image {
        fullName
      }
      startDate
      endDate
      href
      showOnlyForSelectedProducts
      type {
        name
        displayType
      }
      selectedProducts {
        godoGoodsNo
      }
      language {
        code
      }
    }
    getBannersTotalCount
  }
`;

export const GET_DETAIL_BANNER_WRITING_MATERIALS = gql`
  query ($id: Int!) {
    getLanguages {
      id
      code
      name
    }
    getBannerTypes {
      id
      code
      name
      displayType
    }
    getBanner(id: $id) {
      id
      text
      image {
        fullName
      }
      startDate
      endDate
      href
      showOnlyForSelectedProducts
      type {
        name
        id
      }
      selectedProducts {
        godoGoodsNo
      }
      language {
        code
        id
      }
    }
  }
`;

export const GET_NOTICE_WRITING_MATERIALS = gql`
  query ($id: Int!) {
    getNoticeTypes {
      id
      code
      name
    }
    getNotice(id: $id) {
      id
      title
      body
      type {
        id
        code
      }
      language {
        id
        code
      }
      fixed
    }
  }
`;

export const GET_FAQ_WRITING_MATERIALS = gql`
  query ($id: Int!) {
    getFaqTypes {
      id
      code
      name
    }
    getFaq(id: $id) {
      id
      title
      body
      type {
        id
        code
      }
      language {
        id
        code
      }
      fixed
    }
  }
`;

export const GET_POPUP_WRITING_MATERIALS = gql`
  query ($id: Int!) {
    getPopup(id: $id) {
      id
      title
      body
      language {
        code
      }
      startDate
      endDate
      location {
        url
      }
      allowHide
      created
      updated
    }
  }
`;

export const GET_FAQ_ACON_TYPES = gql`
  query {
    faqAconTypes {
      id
      code
      i18n {
        name
        language {
          id
          code
        }
      }
    }
  }
`;

export const GET_FAQ_CREATOR_TYPES = gql`
  query {
    faqCreatorTypes {
      id
      code
      i18n {
        name
        lang
      }
    }
  }
`;

// 에이콘 FAQ 게시판 데이터 가져오기
export const GET_FAQ_ACON_PAGE = gql`
  query (
    $page: Int!
    $limit: Int!
    $searchType: String
    $keyword: String
    $lang: String
    $typeId: Int
  ) {
    faqAcons(
      page: $page
      limit: $limit
      lang: $lang
      typeId: $typeId
      searchType: $searchType
      keyword: $keyword
    ) {
      id
      title
      subtitle
      type {
        i18n {
          name
          language {
            code
          }
        }
      }
      language {
        id
        code
      }
      order
      created
      updated
    }
    faqAconCount(
      lang: $lang
      typeId: $typeId
      searchType: $searchType
      keyword: $keyword
    )
  }
`;

// 에이콘 FAQ 게시판 데이터 가져오기
export const GET_FAQ_CREATOR_PAGE = gql`
  query GET_FAQ_CREATOR_PAGE(
    $page: Int
    $limit: Int
    $searchType: String
    $keyword: String
    $lang: String
    $typeId: Int
  ) {
    faqCreators(
      page: $page
      limit: $limit
      lang: $lang
      typeId: $typeId
      searchType: $searchType
      keyword: $keyword
    ) {
      id
      title
      subtitle
      lang
      order
      type {
        i18n {
          name
          lang
        }
      }
      created
      updated
    }
    faqCreatorCount(
      lang: $lang
      typeId: $typeId
      searchType: $searchType
      keyword: $keyword
    )
  }
`;

export const GET_FAQ_CREATOR_WRITE = gql`
  query ($id: Int!) {
    faqCreator(id: $id) {
      id
      title
      subtitle
      body
      type {
        id
        code
      }
      lang
    }
  }
`;

export const GET_FAQ_ACON_WRITE = gql`
  query ($id: Int!) {
    faqAcon(id: $id) {
      id
      title
      subtitle
      body
      type {
        id
        code
      }
      language {
        id
        code
      }
    }
  }
`;

export const GET_GODO_COSMIC_DEAL_LIST = gql`
  query ($godoGoodsNo: Int!) {
    godoCosmicDealList(godoGoodsNo: $godoGoodsNo) {
      sno
      benefitNm
      periodDiscountStart
      periodDiscountEnd
      goodsPrice
    }
  }
`;

export const GET_COSMIC_PROMOTION = gql`
  query ($id: Int!) {
    getPromotion(id: $id) {
      id
      godoGoodsNo
      reserveDate
      languageList {
        language {
          code
        }
      }
      imageList {
        cropX
        cropY
        cropWidth
        cropHeight
        originUploadFile {
          fullName
        }
        resizeUploadFile {
          fullName
        }
        type
      }
      benefitList {
        startDate
        endDate
        name
        amount
        type
      }
      isAutoCreateReviewBenefit
    }
  }
`;

export const GET_COMET_PROMOTION = gql`
  query ($id: Int!) {
    getPromotion(id: $id) {
      id
      godoGoodsNo
      reserveDate
      languageList {
        language {
          code
        }
      }
      imageList {
        cropX
        cropY
        cropWidth
        cropHeight
        originUploadFile {
          fullName
        }
        resizeUploadFile {
          fullName
        }
        type
      }
      benefitList {
        startDate
        endDate
        name
        amount
        type
      }
      badgeList {
        type
        item {
          language {
            id
            code
          }
          amount
        }
      }
      isAutoCreateReviewBenefit
    }
  }
`;

export const GET_PROMOTION_LIST = gql`
  query (
    $brandName: String
    $title: String
    $page: Int!
    $limit: Int!
    $type: String
  ) {
    getPromotionCount(brandName: $brandName, title: $title, type: $type)
    getPromotions(
      brandName: $brandName
      title: $title
      page: $page
      limit: $limit
      type: $type
    ) {
      id
      title
      brandName
      periods {
        startDate
        endDate
      }
      status
    }
  }
`;

export const GET_IS_EXIST_PROMOTION = gql`
  query ($godoGoodsNo: Int!, $id: Int, $type: String!) {
    isExistPromotion(godoGoodsNo: $godoGoodsNo, id: $id, type: $type)
  }
`;

export const GET_FULL_PAGE_BANNER_DATA = gql`
  query ($page: Int!, $limit: Int!) {
    getBandBanners(page: $page, limit: $limit) {
      id
      type {
        name
      }
      language {
        code
      }
      text
      backgroundColor
      closeColor
      href
      startDate
      endDate
      isClosedShow {
        name
      }
    }
    getBandBannersTotalCount
  }
`;

export const GET_FULL_BANNER_WRITING_MATERIALS = gql`
  query ($id: Int!) {
    getLanguages {
      id
      code
      name
    }
    getBandBannerTypes {
      id
      code
      name
    }
    getBandBannerClosed {
      id
      code
      name
    }
    getBandBanner(id: $id) {
      id
      type {
        name
      }
      language {
        id
      }
      text
      backgroundColor
      closeColor
      href
      isBlankHref
      startDate
      endDate
      isClosedShow {
        id
      }
    }
  }
`;

export const GET_POINT_BENEFIT_WRITING_MATERIALS = gql`
  query ($id: Int!) {
    getLanguages {
      id
      code
      name
    }
    getPointBenefitTypes {
      id
      code
      name
    }
    getPointBenefitApply {
      id
      code
      name
    }
    getPointBenefit(id: $id) {
      id
      language {
        id
      }
      type {
        id
      }
      pointUnit
      payPoint
      applyType {
        id
        code
      }
      applyList {
        value
      }
      startDate
      endDate
      quantity
      useCount
      description
      defaultApply
      isAutoCreatedByPromotion
    }
    getReviewPointItems {
      language {
        code
      }
      payPoint
      typeId
    }
  }
`;

export const GET_POINT_BENEFIT_DATA = gql`
  query ($page: Int!, $limit: Int!, $langId: Int) {
    getLanguages {
      id
      code
      name
    }
    getPointBenefits(page: $page, limit: $limit, langId: $langId) {
      id
      language {
        id
        code
      }
      type {
        name
        code
      }
      pointUnit
      payPoint
      applyType {
        name
        code
      }
      applyList {
        value
      }
      startDate
      endDate
      quantity
      useCount
      description
    }
    getPointBenefitsTotalCount(langId: $langId)
  }
`;

export const GET_VALIDATION_GOODS_NO = gql`
  query ($godoGoodsNo: Int, $lang: String!) {
    getValidationGoodsNo(godoGoodsNo: $godoGoodsNo, lang: $lang)
  }
`;

export const GET_BENEFIT_LIMIT_LIST = gql`
  query ($page: Int!, $limit: Int!) {
    getPointLimitPolicyList(page: $page, limit: $limit) {
      id
      type
      brand {
        id
        name
      }
      category {
        id
        i18n {
          language {
            code
          }
          name
        }
      }
      goodsNo
      startDate
      endDate
    }
    getPointLimitPolicyCount
  }
`;

export const GET_BENEFIT_LIMIT = gql`
  query ($id: Int!) {
    getPointLimitPolicy(id: $id) {
      id
      type
      brand {
        id
        name
      }
      category {
        id
        i18n {
          language {
            code
          }
          name
        }
      }
      goodsNo
      startDate
      endDate
    }
  }
`;

export const GET_COUPON_BENEFIT_WRITING_MATERIALS = gql`
  query ($id: Int!) {
    getLanguages {
      id
      code
      name
    }
    getPointBenefitTypes {
      id
      code
      name
    }
    getPointBenefitApply {
      id
      code
      name
    }
    getCouponBenefit(id: $id) {
      id
      language {
        id
      }
      type {
        id
      }
      godoCouponNo
      godoCouponName
      godoCouponRegDt
      applyType {
        id
        code
      }
      applyList {
        value
      }
    }
  }
`;

export const GET_COUPON_BENEFIT_DATA = gql`
  query ($page: Int!, $limit: Int!, $langId: Int) {
    getLanguages {
      id
      code
      name
    }
    getCouponBenefits(page: $page, limit: $limit, langId: $langId) {
      id
      language {
        id
        code
      }
      type {
        name
        code
      }
      godoCouponName
      godoCouponRegDt
      applyType {
        name
      }
      applyList {
        value
      }
    }
    getCouponBenefitsTotalCount(langId: $langId)
  }
`;

export const GET_COUPON_ITEMS = gql`
  query ($couponName: String!, $couponRegDt: String!) {
    getCouponItems(couponName: $couponName, couponRegDt: $couponRegDt) {
      godoCouponNo
      godoCouponName
      godoCouponRegDt
    }
  }
`;

export const GET_BENEFIT_BANNER_WRITING_MATERIALS = gql`
  query ($id: Int!) {
    getLanguages {
      id
      code
      name
    }
    getItem(id: $id) {
      id
      language {
        id
        code
      }
      title
      conditionFirst
      conditionSecond
      couponNo
      image {
        fullName
      }
      startDate
      endDate
    }
  }
`;

export const GET_BENEFIT_PAGE_BANNERS_DATA = gql`
  query (
    $page: Int!
    $limit: Int!
    $viewOnlyActiveCoupons: Boolean!
    $lang: String!
  ) {
    getLanguages {
      id
      code
      name
    }
    getItems(
      page: $page
      limit: $limit
      viewOnlyActiveCoupons: $viewOnlyActiveCoupons
      lang: $lang
    ) {
      id
      language {
        code
      }
      title
      conditionFirst
      conditionSecond
      couponNo
      image {
        fullName
      }
      startDate
      endDate
    }
    getCounts(viewOnlyActiveCoupons: $viewOnlyActiveCoupons, lang: $lang)
  }
`;

export const GET_GODO_COMET_DEAL_LIST = gql`
  query ($godoGoodsNo: Int!) {
    godoCometDealList(godoGoodsNo: $godoGoodsNo) {
      sno
      benefitNm
      periodDiscountStart
      periodDiscountEnd
      goodsPrice
    }
  }
`;

export const GET_EVENT_PAGE_BANNERS_DATA = gql`
  query (
    $page: Int!
    $limit: Int!
    $viewOnlyActiveCoupons: Boolean!
    $lang: String!
  ) {
    getEventBannerTotalCount(
      isInprogressEvent: $viewOnlyActiveCoupons
      lang: $lang
    )
    getEventBannerItems(
      page: $page
      limit: $limit
      isInprogressEvent: $viewOnlyActiveCoupons
      lang: $lang
    ) {
      id
      language {
        code
        name
      }
      bannerTextFirst
      bannerTextSecond
      bannerTextThird
      settingLink
      order
      image {
        fullName
      }
      startDate
      endDate
    }
    getLanguages {
      id
      code
      name
    }
  }
`;

export const GET_EVENT_BANNER_WRITING_MATERIALS = gql`
  query ($id: Int!) {
    getLanguages {
      id
      code
      name
    }
    getEventBannerItem(id: $id) {
      id
      language {
        id
        code
        name
      }
      bannerTextFirst
      bannerTextSecond
      bannerTextThird
      settingLink
      image {
        fullName
      }
      startDate
      endDate
    }
  }
`;

// 상품 배지 항목들 조회
export const GET_PRODUCT_BADGE_PAGE_DATA = gql`
  query ($page: Int!, $limit: Int!) {
    getBadges(page: $page, limit: $limit) {
      id
      texts {
        langId
        lang
        text
      }
      startDate
      endDate
      godoGoodsNos
      status
    }
    getBadgeCount
  }
`;

// 상품 배지 항목 조회
export const GET_PRODUCT_BADGE_WRITING_MATERIALS = gql`
  query ($id: Float!) {
    getLanguages {
      id
      code
      name
    }
    getBadge(id: $id) {
      id
      texts {
        lang
        text
      }
      startDate
      endDate
      godoGoodsNos
      status
      isAutoCreate
    }
  }
`;

// 다운로드 로그 조회
export const GET_DOWNLOAD_LOGS = gql`
  query (
    $orderNo: String
    $goodsNo: Int
    $userId: String
    $offset: Int
    $limit: Int
  ) {
    getDownloadLogs(
      orderNo: $orderNo
      goodsNo: $goodsNo
      userId: $userId
      offset: $offset
      limit: $limit
    ) {
      totalCount
      downloadCounts {
        id
        userType
        givenCount
        userNo
        userId
        orderNo
        goodsNo
        created
        logs {
          requestIp
          isDeduction
          created
        }
      }
    }
  }
`;

export const GET_VERSION_LANGUAGE = gql`
  query ($docId: String!, $major: Int!, $minor: Int!) {
    versionLanguage(docId: $docId, major: $major, minor: $minor)
  }
`;

export const GET_EXTENSION_CONFIGS = gql`
  query GetExtensionConfigs {
    getExtensionConfigs {
      id
      name
    }
  }
`;

/** 응용프로그램 전체조회 */
export const GET_APPLICATIONS = gql`
  query GetApplications {
    applications: getApplications {
      id
      name
    }
  }
`;

export const GET_POPUPS_HERE = gql`
  query GetPopupsHere($url: String!, $origin: String!) {
    getPopupsHere(url: $url, origin: $origin) {
      id
      title
      image {
        azureStoragePath
      }
      text
      subText
      buttonText
      connectedUrl
    }
  }
`;
