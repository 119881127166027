import { Box, Button, Typography } from '@mui/material';
import { PackageProductType } from 'boards/DetailBoardWrite/recoil/types';
import React, { useState } from 'react';
import { DropdownButtonStyle } from '../../style';
import SearchBar from './SearchBar';
import SearchTable from './SearchTable';

interface Props {
  products: PackageProductType[];
  setProducts: (val: PackageProductType[]) => void;
}

const ProductSelectDropdown = ({
  products,
  setProducts
}: Props) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string|undefined>();

  const handleClose = () => {
    setOpen(false);
    setFilter(undefined);
  };

  return (
    <>
      <Box
        mt="16px"
        position="relative"
        bgcolor="#fff"
        border="1px solid #cdcdcd"
        borderRadius="3px"
        {...isOpen && {
          boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.08)'
        }}
        zIndex="10"
      >
        <Button
          type="button"
          onClick={() => setOpen(!isOpen)}
          sx={{
            ...DropdownButtonStyle,
            '&:focus': {
              outline: 'none'
            }
          }}
        >
          <Typography
            fontSize={14}
            lineHeight={1}
            fontWeight={400}
            mr="auto"
          >
            작가 상품 리스트
          </Typography>
          <Box 
            sx={{ 
              transform: `rotate(${isOpen ? '180deg' : '0'})`,
              transformOrigin: 'center',
              transition: '0.5s',
              lineHeight: '5px',
              '&:focus': {
                outline: 'none'
              }
            }}
          >
            <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 4.44519L6 0.44519H0L3 4.44519Z" fill="#333333" />
            </svg>
          </Box>
        </Button>
        {isOpen && (
          <Box padding="16px" borderTop='1px solid #cdcdcd'>
            <SearchBar defaultValue={filter} setValue={setFilter} />
            <SearchTable
              products={products}
              setProducts={setProducts}
              filter={filter}
            />
          </Box>
        )}
      </Box>
      {/* 리스트 열렸을 때 이외의 구역 누를 시 사라지게 하는 용도 */}
      {isOpen && (
        <Box 
          position="absolute" 
          top="0" 
          left="0" 
          right="0" 
          bottom="0" 
          onClick={handleClose}
        />
      )}
    </>
  );
};

export default ProductSelectDropdown;
