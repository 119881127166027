import { displaySelector, keywordsSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {Item, Title, DivInput, Description, Value, InputLimitText} from '../../style';

export default (() => {
    // 번역도구 
    const { t } = useTranslation();
    const [display] = useRecoilState(displaySelector);
    const [keywords, setKeywords] = useRecoilState(keywordsSelector);

    // input 태그 변경 이벤트 처리기 메소드 
    const onChangeInput = e => {
        if (e.target.value.indexOf('\n') > -1)
            return false;

        setKeywords(e.target.value);
    }
    const onKeyDown = e => {
        if (e.key === 'Enter') {
            return false;
        }
    };

    return (
        <Item>
            <Title>{t("preferredSearchKeyword")}</Title>
            <Value>
                <DivInput
                    maxLength={255}
                    onKeyDown={onKeyDown}
                    disabled={display.isDisp}
                    placeholder={t('PleaseEnterSearchKeyword.label')}
                    onChange={onChangeInput}
                    value={keywords}
                />
            </Value>
            <Description>
                {t("preferredSearchKeywordDescription")}< br/>{t("preferredSearchKeywordDescriptionExample")}
                <InputLimitText>{keywords?.length} / 255</InputLimitText>
            </Description>
            
        </Item>
    );
});