import React from "react";
import { NavLink } from "react-router-dom";
import { useGetUserAcceptStatusNumberQuery } from "generated/graphql";
import { Box, Stack } from "@mui/material";
import styled from "@emotion/styled";
import { color } from "acon-mui/style";

const Link = styled(NavLink)`
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;

  &:hover {
    color: white;
  }
`;
export default function AdminGnb() {
  const { data } = useGetUserAcceptStatusNumberQuery({
    variables: { status: 0, modifyStatus: 0 },
  });

  return (
    <Stack direction="row" gap={3}>
      {/* 브랜드코드 관리 */}
      <Link to="/manager/brand" key="/manager/brand">
        브랜드
      </Link>
      {/* 카테고리 다국어 메뉴 설정 */}
      <Link to="/manager/categoryLang" key="/manager/categoryLang">
        카테고리
      </Link>
      {/* 메인상품진열 다국어 메뉴 설정 */}
      <Link to="/manager/mainGroup" key="/manager/mainGroup">
        메인페이지
      </Link>
      {/* 혜택관리 메뉴 설정 */}
      <Link
        to="/manager/benefit/brandBurden"
        key="/manager/benefit/brandBurden"
      >
        혜택
      </Link>
      {/* 창작자 관리 */}
      <Link to="/account" key="/account" style={{ display: 'flex', alignItems: 'center' }}>
        허브계정관리
        {data && (
          <Box
            ml="2px"
            p="4px"
            fontSize={10}
            lineHeight={1}
            fontWeight={500}
            bgcolor={color.primary}
            borderRadius={99}
          >
            {data.userAcceptStatusNumber > 999 ? 999 : data.userAcceptStatusNumber}
          </Box>
        )}
      </Link>
      {/* 검색관리 메뉴 설정 */}
      <Link to="/searchKeyword" key="/search">
        검색어
      </Link>
      {/* 배너관리 메뉴 설정 */}
      <Link to="/banner/fullPage" key="/banner/fullPage">
        배너
      </Link>
      {/* 에이콘FAQ 메뉴 설정 */}
      <Link to="/aconfaq/faq-acon" key="/aconfaq">
        FAQ
      </Link>
      {/* 에이콘 프로모션 메뉴 설정 */}
      <Link to="/manager/promotion/cosmic-deal" key="/promotion/cosmic-deal">
        프로모션
      </Link>
      {/* 공지관리 메뉴 설정 */}
      <Link to="/board/notice" key="/board/notice">
        게시판
      </Link>
      {/* 수동회원가입 메뉴 설정 */}
      <Link to="/signup/manual" key="/signup/manual">
        수동 회원가입
      </Link>
      {/* 다운로드 관리 메뉴 설정 */}
      <Link to="/download" key="/download">
        다운로드
      </Link>
    </Stack>
  );
}
