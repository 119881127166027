import { selector } from "recoil";
import { modelState } from "..";
import { ModelType } from "../types";

const modelSelector = selector<ModelType>({
  key: 'modelSelector',
  get:({get}) => get(modelState),
  set: ({set}, val) => set(modelState, val),
});

export default modelSelector;