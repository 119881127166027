import { Box, Typography } from "@mui/material";
import {
  STATUS_APPROVED,
  STATUS_DRAFT,
  STATUS_REJECT,
} from "boards/DetailBoardWrite/constants";
import {
  commentSelector,
  displaySelector,
  newStatusSelector,
  statusSelector,
} from "boards/DetailBoardWrite/recoil/selectors";
import { New_Product_Status } from "generated/graphql";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { Description, DivInput, Item, Title, Value } from "../../style";
import { DisplayInput } from "./styled";
export default ({ isAdmin }) => {
  // 번역도구
  const { t } = useTranslation();
  const [display] = useRecoilState(displaySelector);
  const [status] = useRecoilState(statusSelector);
  const [newStatus] = useRecoilState(newStatusSelector);
  const [comment, setComment] = useRecoilState(commentSelector);

  return (
    <>
      {(status === STATUS_REJECT || (
        (newStatus === New_Product_Status.Requested || newStatus === New_Product_Status.InReview)
        && display.isEdit)) && (
        <Item>
          <Title>{t("product.rejectCommentTitle")}</Title>
          <Value>
            {isAdmin && (
              <DivInput
                value={comment.rejectComment}
                onChange={(e) => {
                  setComment({
                    ...comment,
                    rejectComment: e.target.value,
                  });
                }}
              />
            )}
            {!isAdmin && <DisplayInput>{comment.rejectComment}</DisplayInput>}
          </Value>
        </Item>
      )}

      {display.isEdit && (newStatus !== New_Product_Status.Requested && newStatus !== New_Product_Status.InReview)
        ? (comment.updateResponse || isAdmin) &&
          status !== STATUS_REJECT && (
            <Item>
              <Title>{t("product.editCommentTitle")}</Title>
              <Value>
                {isAdmin && (
                  <>
                    <DivInput
                      minRows={3}
                      value={comment.updateResponse}
                      onChange={(e) => {
                        setComment({
                          ...comment,
                          updateResponse: e.target.value,
                        });
                      }}
                    />
                    <Description>
                      수정 내역을 간단하게 정리해 주세요. (수정 완료 안내 메일에
                      포함됩니다)
                    </Description>
                  </>
                )}
                {!isAdmin && (
                  <DisplayInput>{comment.updateResponse}</DisplayInput>
                )}
              </Value>
            </Item>
          )
        : (status === STATUS_APPROVED && display.isModified) && (
            <Item>
              <Title>{t("product.editCommentTitle")}</Title>
              <Value>
                <DisplayInput>{comment.updateResponse}</DisplayInput>
              </Value>
            </Item>
          )}

      {display.isEdit && (newStatus !== New_Product_Status.Requested && newStatus !== New_Product_Status.InReview)
        ? status !== STATUS_REJECT && (
            <Item>
              <Box display="flex" alignItems="center" pb="16px">
                <Title>{t("product.editMessageTitle")}</Title>
                <Typography ml="8px" fontSize="12px" fontWeight="700">
                  &#40;{t("product.mandatory")}&#41;
                </Typography>
              </Box>
              <Value>
                {!isAdmin && status === STATUS_DRAFT && (
                  <>
                    <DivInput
                      value={comment.updateRequest}
                      minRows={2}
                      onChange={(e) => {
                        setComment({
                          ...comment,
                          updateRequest: e.target.value,
                        });
                      }}
                    />
                    <Description>
                      {t("product.editMessageDescription")}
                    </Description>
                  </>
                )}
                {(isAdmin || (!isAdmin && status !== STATUS_DRAFT)) && (
                  <DisplayInput>{comment.updateRequest}</DisplayInput>
                )}
              </Value>
            </Item>
          )
        : (status === STATUS_APPROVED && display.isModified) && (
            <Item>
              <Box display="flex" alignItems="center" pb="16px">
                <Title>{t("product.editMessageTitle")}</Title>
              </Box>
              <Value>
                <DisplayInput> {comment.updateRequest}</DisplayInput>
              </Value>
            </Item>
          )}
    </>
  );
};
