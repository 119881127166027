import { selector } from "recoil";
import { docIdState } from "..";

const docIdSelector = selector<string>({
  key: 'docIdSelector',
  get:({get}) => get(docIdState),
  set: ({set}, val) => set(docIdState, val),
});

export default docIdSelector;
