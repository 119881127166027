import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Quill,
  QUILL_DEFAULT_OPTION,
  EDITOR_ALLOW_IMAGE_PATH_LIST,
} from "./constants";
import Delta from "quill-delta";
import { sendFiles } from "./ImageUploader";
import LinkDialog from "./dialog/LinkDialog";
import ImageDialog from "./dialog/ImageDialog";
import VideoDialog from "./dialog/VideoDialog";
import Loading from "./Loading";

import "./index.scss";
import { Icon } from "rsuite";

export default (props) => {
  const { t } = useTranslation();

  const [height, setHeight] = useState(
    +sessionStorage.getItem("editorHeight") || 500
  );
  const [isShowLoading, loading] = useState(false);
  const [quill, setQuill] = useState(null);
  const [linkText, setLinkText] = useState("");
  const [isShowLinkDialog, showLinkDialog] = useState(false);
  const [isShowImageDialog, showImageDialog] = useState(false);
  const [isShowVideoDialog, showVideoDialog] = useState(false);

  const preventDefault = (e) =>
    e?.dataTransfer.files.length && e.preventDefault();
  const undo = () => quill.history.undo();
  const redo = () => quill.history.redo();
  const resize = (adjustHeight) =>
    setHeight((prev) => {
      let newHeight = prev + adjustHeight;

      if (!newHeight || typeof newHeight !== "number") {
        newHeight = 500;
      }

      sessionStorage.setItem("editorHeight", newHeight);

      return newHeight;
    });

  const insertLink = (text, url) => {
    if (text.length <= 0 || url.length <= 0) {
      props.showAlertMessage(t("linkDialog.title"), t("linkDialog.alert"));
      return false;
    }

    const range = quill.getSelection(true) || quill.selection.savedRange;
    const { index, length } = range;

    quill.updateContents(
      new Delta().retain(index).delete(length).insert(text, { link: url })
    );
    return true;
  };

  const insertImage = (url) => {
    url = url.trim();

    if (url?.length <= 0) {
      props.showAlertMessage(t("imageDialog.title"), t("imageDialog.alert"));
      return false;
    }

    const range = quill.getSelection(true) || quill.selection.savedRange;
    const { index } = range;

    quill.insertEmbed(index, "image", url);
    return true;
  };

  const insertVideo = (url) => {
    if (!url) {
      props.showAlertMessage(t("videoDialog.title"), t("videoDialog.alert"));
      return false;
    }

    const range = quill.getSelection(true) || quill.selection.savedRange;
    const { index } = range;

    quill.insertEmbed(index, "video", url);
    quill.formatText(index, 2, "width", "100%");
    quill.formatText(index, 2, "height", "306px");
    return true;
  };

  const uploadImages = async (files) => {
    // url 들
    const urls = (await sendFiles(files)).reverse();

    for (let i = 0; i < urls.length; i++) {
      insertImage(urls[i]);
    }
    return urls;
  };

  /**
   * 드래그 앤 드랍 이벤트
   * @param {*} e
   * @todo 외부브라우저에서 드래그앤드랍 막기 keyword dataTransfer.items
   * @todo 드랍위치 인근에 드랍
   */
  const onDrop = async (e) => {
    e.persist();
    const fileList = e.dataTransfer.files;

    console.log(e.dataTransfer.items);

    if (!fileList || !fileList.length) {
      return;
    }

    e.preventDefault();

    await uploadImages(fileList);
  };

  const onPaste = (e) => {
    // 어드민계정은 붙여넣기에 제약을 받지 않음
    if (props.isAdmin) return true;

    const clipboardData =
      (e.originalEvent || e).clipboardData || window.clipboardData;
    const clipboardText = (
      clipboardData?.getData("text/html") ||
      clipboardData?.getData("text/plain")
    )?.trim();

    if (!clipboardText) return false;

    const tempDom = document.createElement("div");
    tempDom.innerHTML = clipboardText;

    const hasExceptImage = Array.prototype.some.call(
      tempDom.getElementsByTagName("img"),
      (img) => {
        return EDITOR_ALLOW_IMAGE_PATH_LIST.every((path) => {
          return !img.src.startsWith(path);
        });
      }
    );

    if (hasExceptImage) {
      props.showAlertMessage(" ", t("incorrectInformation"));
      setTimeout(() => {
        props.closeConfirmMessage();
      }, 2000);
      return false;
    }
  };

  const onChange = () => {
    if (props.onChange) {
      props.onChange(quill.root.innerHTML);
    }
  };

  const onLinkOpenClick = () => {
    let text = "";

    if (quill.hasFocus() && window.getSelection().toString().trim().length) {
      text = window.getSelection().toString().trim();
    }

    setLinkText(text);
    showLinkDialog(true);
  };

  // componentDidMount
  useEffect(() => {
    const wrap = document.getElementById("Editor-content");
    setQuill(new Quill(wrap, QUILL_DEFAULT_OPTION));
  }, []);

  // quill initialization
  useEffect(() => {
    if (!quill) return;
    const vdom = document.createElement("div");
    vdom.innerHTML = props.contents;

    const headers = [...vdom.querySelectorAll("span[style]")].filter(
      (span) => span.style.fontSize === "18px"
    );
    headers.forEach((header) => {
      const h4 = document.createElement("h4");
      h4.innerHTML = header.innerHTML;
      header.parentElement.replaceChild(h4, header);
    });

    const delta = quill.clipboard.convert(vdom.innerHTML);
    quill.setContents(delta);

    quill.history.clear();

    const toolbar = quill.getModule("toolbar");
    if (props.isDisp) {
      quill.disable();
      toolbar.addHandler("link", () => false);
      toolbar.addHandler("image", () => false);
      toolbar.addHandler("video", () => false);
    } else {
      toolbar.addHandler("link", onLinkOpenClick);
      toolbar.addHandler("image", () => showImageDialog(true));
      toolbar.addHandler("video", () => showVideoDialog(true));
    }

    // clear text formatting on paste
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      const range = quill.getSelection() || quill.selection.savedRange;
      const targetFormat = quill.getFormat(range);

      delta.ops = delta.ops.map((op) => {
        const result = props.isAdmin ? op : { insert: op.insert };

        if (targetFormat?.header) {
          result.attributes = { bold: true };
        }

        return result;
      });

      return delta;
    });
    // --clear text formatting on paste

    quill.on("text-change", onChange);
    quill.root.addEventListener("paste", onPaste);
    onChange();
  }, [quill]);

  return (
    <>
      <LinkDialog
        isShow={isShowLinkDialog}
        close={() => showLinkDialog(false)}
        insertLink={insertLink}
        defaultText={linkText}
      />
      <ImageDialog
        isShow={isShowImageDialog}
        close={() => showImageDialog(false)}
        uploadImages={uploadImages}
        insertImage={insertImage}
        showUrlInput={props.isAdmin}
      />
      <VideoDialog
        isShow={isShowVideoDialog}
        close={() => showVideoDialog(false)}
        insertVideo={insertVideo}
      />
      <div
        className={`Editor ${props.isDisp ? "disabled" : ""}`}
        onDrop={onDrop}
        onDragOver={preventDefault}
        onDragEnter={preventDefault}
      >
        {isShowLoading && <Loading />}
        <div className="Editor-toolbar" id="Editor-toolbar">
          <span className="ql-formats">
            <select className="ql-header">
              <option value="">{t("edit.body")}</option>
              <option value="4">{t("edit.title")}</option>
            </select>
          </span>
          <span className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
          </span>
          {props.isAdmin && (
            <span className="ql-formats">
              <button className="ql-blockquote"></button>
              <button className="ql-code-block"></button>
            </span>
          )}
          <span className="ql-formats">
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
          </span>
          <span className="ql-formats">
            <button onClick={undo}>
              <i className="note-icon-undo"></i>
              <Icon icon="undo" />
            </button>
            <button onClick={redo}>
              <Icon icon="repeat" />
            </button>
          </span>
          <span className="ql-formats">
            <button className="ql-link"></button>
            <button className="ql-image"></button>
            <button className="ql-video"></button>
          </span>
        </div>
        <div id="Editor-content" style={{ height }}></div>
        <Resizer resize={resize} />
      </div>
      <div
        style={{
          fontSize: "12px",
          color: "#F300BA",
          fontWeight: "400",
          marginTop: "8px",
        }}
      >
        {t("editor.description")}
      </div>
    </>
  );
};

function Resizer(props) {
  const refProps = {};
  let prev = 0;

  const resize = (diff) => props.resize && props.resize(diff);

  const onDrag = (e) => {
    const current = e.clientY;
    const diff = current - prev;

    if (diff !== 0) {
      resize(diff);
    }

    prev = current;
  };

  const disable = () => {
    document.body.style.userSelect = "";

    window.removeEventListener("mouseup", disable);
    window.removeEventListener("mousemove", onDrag);
  };

  refProps.className = "Editor-resize-handler";
  refProps.onMouseDown = (e) => {
    e.target.focus();

    document.body.style.userSelect = "none";

    prev = e.clientY;

    window.addEventListener("mouseup", disable);
    window.addEventListener("mousemove", onDrag);
  };

  return React.createElement("div", refProps);
}
