import { selector } from "recoil";
import { commentState } from "..";
import { CommentType } from "../types";

const commentSelector = selector<CommentType>({
  key: 'commentSelector',
  get:({get}) => get(commentState),
  set: ({set}, val) => set(commentState, val),
});

export default commentSelector;
