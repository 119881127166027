import React from 'react';
import ImageUpload from 'boards/DetailBoardWrite/component/imageUpload';
import { useRecoilState } from 'recoil';
import { mainImageSelector } from 'boards/DetailBoardWrite/recoil/selectors';

export default (({
    setCallback,
    cropperTag,
    image,
    setImage,
    setCroppedImageBlob,
    setCroppedImageDataUrl,
    disabled,
    isShow,
    isComplete,
    setComplete,
    setCompleteCallback,
    setFileCallback,
    showMessage
}) => {
    // 파일 개수 제한 
    const limit = 1;
    // 확장자들 
    const extensions = ['image/jpg', 'image/png', 'image/jpeg'];
    // 파일 크기 
    const fileSize = [1000, 1000];

    const [mainImage, setMainImage] = useRecoilState(mainImageSelector);

    return (
        <ImageUpload
            showMessage={showMessage}
            setCallback={setCallback}
            cropperTag={cropperTag}
            image={image}
            setImage={setImage}
            setCompleteCallback={setCompleteCallback}
            setCroppedImageBlob={setCroppedImageBlob}
            setCroppedImageDataUrl={setCroppedImageDataUrl}
            isShow={isShow}
            disabled={disabled}
            isComplete={isComplete}
            setComplete={setComplete}
            maxWidth={fileSize[0]}
            maxHeight={fileSize[1]}
            extensions={extensions}
            sizeLimit={10}
            targetWidth={548}
            targetHeight={358}
            boxWidth={585}
            boxHeight={300}
            limit={limit}
            cropRatio={1.53}
            left={mainImage.thumbnail?.cropInfo?.imageOriginX}
            top={mainImage.thumbnail?.cropInfo?.imageOriginY}
            width={mainImage.thumbnail?.cropInfo?.imageWidth}
            height={mainImage.thumbnail?.cropInfo?.imageHeight}
            url={mainImage.thumbnail?.imagePath}
            setCropInfo={value => {
                setMainImage({
                    thumbnail: {
                        cropInfo: value,
                    }
                });
            }}
            setImageUrl={(key, name) => {
                setMainImage({
                    thumbnail: {
                        imagePath: key,
                        imageName: name,
                    }
                });
            }}
            setFileCallback={setFileCallback}
        />
    );
});