import { descriptionSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Item, Title, Input, Value } from '../../style';

export default ((props) => {
    // 번역도구 
    const { t } = useTranslation();
    const [atom, setAtom] = useRecoilState(descriptionSelector)

    return (
        <Item>
            <Title admin={true}>{t("description")}</Title>
            <Value>
                <Input
                    placeholder={t("PleaseWriteDownShortExplanation.label")}
                    value={atom}
                    onChange={(e) => {
                        setAtom(e.target.value);
                    }}
                />
            </Value>
        </Item>
    );
});