import { selector } from "recoil";
import { copyrightState } from "..";
import { CopyrightType } from "../types";

const copyrightSelector = selector<CopyrightType>({
  key: 'copyrightSelector',
  get:({get}) => get(copyrightState),
  set: ({set}, val) => set(copyrightState, val),
});

export default copyrightSelector;
