import { useQuery } from "@apollo/client";
import { GET_IS_USED_PEN_NAME } from "api/quries";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SimpleBorderInput } from "../input";
import {
  FlexColumn,
  FlexRow,
  Label,
  SectionTitle,
  Summary,
  InfoLabel,
} from "../style";
import { SmallButton } from "./style/2";
import LabelTextInput from "signup/input/labelTextInput";
import { Grid, Stack } from "@mui/material";
import { LanguageCodeEnum } from "generated/graphql";
import { chapterState, partnerFormState } from "signup/SignUp.atoms";
import { useRecoilState } from "recoil";
import { SignupChapterEnum } from "signup/type";
import Navigator from "signup/Navigator";

export default function PartnerInformationForm() {
  // 번역 도구
  const { t, i18n } = useTranslation();
  const [, setChapterValid] = useRecoilState(chapterState);
  const [, setPartnerForm] = useRecoilState(partnerFormState);

  const [state, setState] = useState({
    koBrandName: "",
    enBrandName: "",
    zhBrandName: "",
    jaBrandName: "",
    contact: "",
    contactAdditional: "",
    snsUrl: "",
    documents: [],
  });

  const {
    koBrandName,
    enBrandName,
    zhBrandName,
    jaBrandName,
    contact,
    contactAdditional,
    snsUrl,
    documents,
  } = state;

  const { refetch } = useQuery(GET_IS_USED_PEN_NAME, {
    variables: {
      lang: i18n.language === "ko" ? "ko" : "en",
      name: i18n.language === "ko" ? koBrandName : enBrandName,
    },
    skip: true,
  });
  const [isDuplicatedKoBrandName, setIsDuplicatedKoBrandName] = useState(false);
  const [isDuplicatedEnBrandName, setIsDuplicatedEnBrandName] = useState(false);
  const [isDuplicatedZhBrandName, setIsDuplicatedZhBrandName] = useState(false);
  const [isDuplicatedJaBrandName, setIsDuplicatedJaBrandName] = useState(false);
  const [inputDocument, setInputDocument] = useState("");

  /**
   * @summary 입점 검토 자료 칸 추가
   */
  const appendDocument = () => {
    if (!inputDocument) {
      return;
    }

    const tmp = [...documents];

    tmp.unshift(inputDocument);

    setState({
      ...state,
      documents: tmp,
    });

    setInputDocument("");
  };

  /**
   * @summary 입점 검토 자료 칸 삭제
   */
  const removeDocument = (idx) => {
    const tmp = [...documents];

    tmp.splice(idx, 1);

    setState({
      ...state,
      documents: tmp,
    });
  };

  const onBlur = async (lang, name) => {
    if (!name) {
      switch (lang) {
        case "ko":
          setIsDuplicatedKoBrandName(false);
          break;
        case "en":
          setIsDuplicatedEnBrandName(false);
          break;
        case "zh":
          setIsDuplicatedZhBrandName(false);
          break;
        case "ja":
          setIsDuplicatedJaBrandName(false);
          break;
        default:
          throw new Error("not supported language");
      }
    } else {
      const { data, error } = await refetch({ lang, name });
      const isDuplicated = data.isDuplicateBrandName;
      switch (lang) {
        case "ko":
          setIsDuplicatedKoBrandName(isDuplicated);
          break;
        case "en":
          setIsDuplicatedEnBrandName(isDuplicated);
          break;
        case "zh":
          setIsDuplicatedZhBrandName(isDuplicated);
          break;
        case "ja":
          setIsDuplicatedJaBrandName(isDuplicated);
          break;
        default:
          throw new Error("not supported language");
      }

      if (error) {
        console.error(error);
      }
    }
  };

  const isSuccess = (() => {
    const isExistPenName =
      isDuplicatedKoBrandName ||
      isDuplicatedEnBrandName ||
      isDuplicatedZhBrandName ||
      isDuplicatedJaBrandName;

    if (i18n.language === LanguageCodeEnum.Ko) {
      if (
        koBrandName &&
        contact &&
        documents.some((doc) => doc.trim().length > 0) &&
        !isExistPenName
      ) {
        return true;
      }
    } else {
      if (
        enBrandName &&
        documents.some((doc) => doc.trim().length > 0) &&
        !isExistPenName
      ) {
        return true;
      }
    }

    return false;
  })();

  const setCurrentChapterValid = (valid: boolean) => {
    setChapterValid((prev) => {
      return {
        ...prev,
        [SignupChapterEnum.Partner]: valid,
      };
    });
  };

  useEffect(() => {
    setCurrentChapterValid(isSuccess);
  }, [isSuccess]);

  const handleNext: () => Promise<string> = () => {
    return new Promise((resolve) => {
      setPartnerForm({
        brandNames: {
          koBrandName,
          enBrandName,
          zhBrandName,
          jaBrandName,
        },
        snsUrl,
        contact,
        contactAdditional,
        documents,
      });
      resolve("");
    });
  };

  return (
    <>
      <Stack>
        <SectionTitle>{t("signupPage.creatorInfo")}</SectionTitle>
        <FlexColumn>
          <FlexColumn marginBottom="36">
            <Label>{t("signupPage.creatorName")}</Label>
            <Stack spacing={1.25} width={"100%"}>
              <Grid
                display={"grid"}
                gridTemplateColumns={"calc(50% - 5px) calc(50% - 5px)"}
                gap={1.25}
              >
                <Stack
                  flex={1}
                  {...(i18n.language !== LanguageCodeEnum.Ko && { order: 3 })}
                >
                  <LabelTextInput
                    value={koBrandName}
                    labelText={t("signupPage.label.korean")}
                    placeholder={
                      i18n.language === "ko"
                        ? t("signupPage.placeholder.creatorName")
                        : t("signupPage.placeholder.creatorNameOptional")
                    }
                    onChange={(e) => {
                      setState({
                        ...state,
                        koBrandName: e.target.value,
                      });
                    }}
                    onBlur={(e) => onBlur("ko", koBrandName)}
                    isValid={!isDuplicatedKoBrandName}
                  />
                  {isDuplicatedKoBrandName && koBrandName && (
                    <Summary fail>{t("signupPage.isExistsPenName")}</Summary>
                  )}
                </Stack>
                <Stack
                  flex={1}
                  {...(i18n.language !== LanguageCodeEnum.Ko && { order: 1 })}
                >
                  <LabelTextInput
                    value={enBrandName}
                    labelText={t("signupPage.label.english")}
                    placeholder={
                      i18n.language === "ko"
                        ? t("signupPage.placeholder.creatorNameOptional")
                        : t("signupPage.placeholder.creatorName")
                    }
                    onChange={(e) => {
                      setState({
                        ...state,
                        enBrandName: e.target.value,
                      });
                    }}
                    onBlur={(e) => onBlur("en", enBrandName)}
                    isValid={!isDuplicatedEnBrandName}
                  />
                  {isDuplicatedEnBrandName && enBrandName && (
                    <Summary fail>{t("signupPage.isExistsPenName")}</Summary>
                  )}
                </Stack>
                <Stack
                  flex={1}
                  {...(i18n.language !== LanguageCodeEnum.Ko && { order: 2 })}
                >
                  <LabelTextInput
                    value={zhBrandName}
                    labelText={t("signupPage.label.chinese")}
                    placeholder={t(
                      "signupPage.placeholder.creatorNameOptional"
                    )}
                    onChange={(e) => {
                      setState({
                        ...state,
                        zhBrandName: e.target.value,
                      });
                    }}
                    onBlur={(e) => onBlur("zh", zhBrandName)}
                    isValid={!isDuplicatedZhBrandName}
                  />
                  {isDuplicatedZhBrandName && zhBrandName && (
                    <Summary fail>{t("signupPage.isExistsPenName")}</Summary>
                  )}
                </Stack>
                <Stack
                  flex={1}
                  {...(i18n.language !== LanguageCodeEnum.Ko && { order: 4 })}
                >
                  <LabelTextInput
                    value={jaBrandName}
                    labelText={t("signupPage.label.japanese")}
                    placeholder={t(
                      "signupPage.placeholder.creatorNameOptional"
                    )}
                    onChange={(e) => {
                      setState({
                        ...state,
                        jaBrandName: e.target.value,
                      });
                    }}
                    onBlur={(e) => onBlur("ja", jaBrandName)}
                    isValid={!isDuplicatedJaBrandName}
                  />
                  {isDuplicatedJaBrandName && jaBrandName && (
                    <Summary fail>{t("signupPage.isExistsPenName")}</Summary>
                  )}
                </Stack>
              </Grid>
            </Stack>

            {i18n.language === "ko" && (
              <InfoLabel>
                해외몰 필명 미설정 시, 기본 필명을 번역하여 설정합니다.
              </InfoLabel>
            )}
          </FlexColumn>
          {i18n.language === "ko" && (
            <FlexColumn>
              <Label>{"연락처"}</Label>
              <FlexRow marginBottom="16">
                <SimpleBorderInput
                  value={contact}
                  isValid={true}
                  onChange={(e) => {
                    setState({
                      ...state,
                      contact: e.target.value.replace(/[^0-9\-]/, ""),
                    });
                  }}
                  placeholder={"‎"}
                />
              </FlexRow>
            </FlexColumn>
          )}
          <FlexColumn marginBottom="20">
            <Label>{t("signupPage.projectLink")}</Label>
            <FlexColumn>
              <FlexRow marginBottom="0">
                <SimpleBorderInput
                  value={inputDocument}
                  isValid={true}
                  onChange={(e) => setInputDocument(e.target.value)}
                  placeholder={t("signupPage.placeholder.projectReference")}
                />
                <SmallButton onClick={appendDocument}>
                  <img src={"/assets/icon/signin/add.svg"} />
                </SmallButton>
              </FlexRow>
              <InfoLabel style={{ marginBottom: 16 }}>
                {t("signupPage.placeholder.projectReference2")}
              </InfoLabel>
              {documents.map((doc, idx) => (
                <FlexRow
                  key={idx}
                  justifyContent="space-between"
                  marginBottom="16"
                  style={{
                    alignItems: "center",
                    lineHeight: "150%",
                    overflowWrap: "anywhere",
                  }}
                >
                  <a
                    key={idx}
                    href={doc}
                    target="_blank"
                    style={{
                      color: "#333333",
                      textDecoration: "underline",
                      fontSize: 14,
                    }}
                  >
                    {doc}
                  </a>
                  <SmallButton onClick={() => removeDocument(idx)}>
                    <img src={"/assets/icon/signin/remove.svg"} />
                  </SmallButton>
                </FlexRow>
              ))}
            </FlexColumn>
          </FlexColumn>
          <FlexColumn>
            <Label>{t("signupPage.contactAdditional")}</Label>
            <FlexRow>
              <SimpleBorderInput
                value={i18n.language === "ko" ? contactAdditional : snsUrl}
                isValid={true}
                onChange={(e) => {
                  setState({
                    ...state,
                    [i18n.language === "ko" ? "contactAdditional" : "snsUrl"]:
                      e.target.value,
                  });
                }}
                placeholder={t("signupPage.placeholder.snsUrl")}
              />
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </Stack>
      <Navigator onNext={handleNext} />
    </>
  );
}
