import { displaySelector, salePriceSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import ACON from 'lib/global';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import { Item, Title, Value, Input } from '../../style';
import { Wrap, CheckboxItem, TitleWrapper } from './style';

export default (({ isAdmin }) => {
    // 번역도구 
    const { t } = useTranslation();
    // URL 파생 정보 
    const { lang } = useParams<{ lang?: string; }>();
    const [display] = useRecoilState(displaySelector);
    const [priceState, setPriceState] = useRecoilState(salePriceSelector);

    // 단가 변경 이벤트 처리기 메소드 
    const onChangeSalePrice = e => {
        // 금액
        let salePrice = e.target.value === '' ? '' : e.target.value;

        setPriceState({ 
            // 금액이 0일 경우 
            ...(salePrice === '0' && {
                isFree: true,
            }),
            salePrice,
        });
    };
    const onChangeCheckboxItem = (value, checked) => {
        setPriceState({ 
            isFree: checked, 
            salePrice: checked ? '0' : '',
        });
    };

    return (
        <Wrap isDisp={display.isDisp}>
            <Item>
                <TitleWrapper>
                    <Title>
                        {isAdmin && t('goods.price')}
                        {!isAdmin && `${t('desiredPrice')} ${lang === 'en' ? '(USD)' : lang === 'cn' ? '(美元)' : lang === 'jp' ? '(ドル)' : ''}`}
                    </Title>
                    <CheckboxItem
                        isAdmin={isAdmin}
                        checked={priceState.isFree}
                        onChange={onChangeCheckboxItem}
                    >{t('freeSource')}
                    </CheckboxItem>
                </TitleWrapper>
                <Value>
                    <Input
                        maxLength={13}
                        type="text"
                        disabled={display.isDisp || priceState.isFree}
                        placeholder={(() => {
                            switch (lang) {
                                case 'ko':
                                    return 'KRW';
                                case 'en':
                                    return 'USD'
                                case 'cn':
                                    return '美元';
                                case 'jp':
                                    return 'ドル';
                                default:
                                    return 'KRW';
                            }
                        })()}
                        value={priceState.salePrice}
                        onChange={onChangeSalePrice}
                    />
                </Value>
            </Item>
        </Wrap >
    );
});