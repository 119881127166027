import { selector } from "recoil";
import { fileConfigState } from "..";
import { FileConfigType } from "../types";

const fileConfigSelector = selector<FileConfigType[]>({
  key: 'fileConfigSelector',
  get:({get}) => get(fileConfigState),
  set: ({set}, val) => set(fileConfigState, val),
});

export default fileConfigSelector;
