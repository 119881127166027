import { selector } from "recoil";
import { updateHistoryState } from "..";
import { UpdateHistoryType } from "../types";

const updateHistorySelector = selector<UpdateHistoryType[]>({
  key: 'updateHistorySelector',
  get:({get}) => get(updateHistoryState),
  set: ({set}, val) => set(updateHistoryState, val),
});

export default updateHistorySelector;
