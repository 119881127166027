import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { brandSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import { LanguageEnum, PackageProductType } from 'boards/DetailBoardWrite/recoil/types';
import { useGetProductsByBrandIdQuery } from 'generated/graphql';
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Spinner from 'spinner/Spinner';
import convertLanguageCode from 'utils/convertLanguageCode';
import { TableCellStyle, CheckButtonStyle } from '../../style';
interface Props {
  products: PackageProductType[];
  setProducts: (val: PackageProductType[]) => void;
  filter: string|undefined;
}

export default function SearchTable({
  products,
  setProducts,
  filter
}: Props) {
  const { lang } = useParams<{ lang?: LanguageEnum }>();
  const [brand] = useRecoilState(brandSelector);

  // 해당 brand code의 goodsNos 가져오기
  const { data, loading, error } = useGetProductsByBrandIdQuery({ 
    variables: { 
      brandId: brand.brandId 
    } 
  });

  const searchList = data?.productsByBrandId?.map((x) => ({ 
    id: x.godoGoodsNo,
    docId: x.docId,
    title: x.title,
  }));

  const onClickTable = (val: PackageProductType) => {
    if (!products.some((el: PackageProductType) => el.id === val.id)) {
      const newArray = [...products, val];
      newArray.sort((a, b) => a.id - b.id);

      setProducts(newArray);
    } else {
      const newArray = products.filter((el: PackageProductType) => el.id !== val.id);
      setProducts(newArray);
    }
  };
  if (loading) return (
    <Box position="relative" width="100%" height="70px">
      <Spinner 
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Box>
  );

  if (error) return <div />;

  return (
    <Box
      marginTop="12px"
      height="165px"
      sx={{ 
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
          width: '8px',
        },
        "&::-webkit-scrollbar-thumb": {
          background: '#c1c1c1',
          borderRadius: '4px',
        },
        "&::-webkit-scrollbar-track": {
          background: 'transparents',
          borderRadius: '4px',
          boxShadow: 'none'
        }
      }}
    >
      <Table padding='none'>
        <colgroup>
          <col width="32px" />
          <col width="98px" />
          <col/>
        </colgroup>
        <TableBody>
          {searchList.map((search: PackageProductType) => (
            <Fragment key={search.id}>
              {((filter 
                && filter.length > 0 
                && search.id
                && search.id.toString().includes(filter))
                || !filter) 
                && (
                <TableRow>
                  {/* 체크 버튼 */}
                  <TableCell sx={{ padding: '8px 0 !important' }}>
                    <button
                      type="button"
                      onClick={() => {
                        onClickTable(search);
                      }}
                      style={CheckButtonStyle(products.some((el: PackageProductType) => el.id === search.id))}
                    >
                      {products.some((el: PackageProductType) => el.id === search.id) && (
                        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 4L4.2 7L9 1" stroke="white" strokeWidth="2"/>
                        </svg>                        
                      )}
                    </button>
                  </TableCell>
                  {/* 상품 ID */}
                  <TableCell 
                    sx={{ 
                      ...TableCellStyle(false), 
                      padding: '9px 0 !important',
                      textDecoration: 'underline' 
                    }}
                  >
                    <a href={`https://www.acon3d.com/${convertLanguageCode(lang)}/toon/product/${search.id}`} target="_blank" rel="noopener noreferrer">
                      {search.id}
                    </a>
                  </TableCell>
                  {/* 상품 제목 */}
                  <TableCell 
                    sx={{
                      ...TableCellStyle(false),
                      padding: '9px 0 !important',
                    }}
                  >
                    {search.title}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
