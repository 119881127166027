import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {NavLink} from "react-router-dom";
import Guide from "../signup/guide/guide";
import {
    Flex,
    IdSaveCheckbox,
    IdSaveContainer,
    IdSaveText,
    JoinContainer,
    JoinText,
    LoginButton,
    LoginDescription,
    LoginForm,
    LoginFormContainer,
    LoginIdInput,
    LoginInput,
    LoginInputContainer,
    LoginLoader,
    LoginPasswordInput,
    LoginSearch,
    TitleText,
    ValidationMessage,
} from "./styled";
import {CustomText} from "components/style";
import "./SignIn.scss";
import useAuth from "hooks/useAuth";
import {Helmet} from "react-helmet";

export default (function (props) {
    const {login} = useAuth();
    // 번역 도구
    const {t, i18n} = useTranslation();
    // cookie
    const [cookie, setCookie, removeCookie] = useCookies();
    // id 값
    const [idValue, setIdValue] = useState("");
    // id 값이 입력되지 않았을 경우 문구 표시 여부
    const [isEmptyId, setIsEmptyId] = useState(false);
    // password 값
    const [passwordValue, setPasswordValue] = useState("");
    // password 값이 입력되지 않았을 경우 문구 표시 여부
    const [isEmptyPassword, setIsEmptyPassword] = useState(false);
    // 아이디 저장 여부
    const [isIdSave, setIsIdSave] = useState(true);
    // 로그인 진행 중 여부
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);
    // 로그인 에러 코드
    const [errorMessage, setErrorMessage] = useState("");

    /**
     * 로그인 에러 메세지
     * NOT_FOUND_ID_PW : ID / PW 확인 불가
     * NOT_FOUND_USER_INFO : 회원 정보를 찾을 수 없음.
     * LOCK_15_MINUTE : 잦은 로그인 시도로 15분 접속 차단
     * LOCK_10_MINUTE : 로그인 제한, 10분 접속 차단
     * DORMANT_MEMBER : 휴면 회원
     */
    const errorMessageList = {
        NOT_FOUND_ID_PW: t("login.errorMessage1"),
        NOT_FOUND_USER_INFO: t("login.errorMessage2"),
        LOCK_15_MINUTE: t("login.errorMessage3"),
        LOCK_10_MINUTE: t("login.errorMessage4"),
        DORMANT_MEMBER: t("login.errorMessage5"),
    };

    useEffect(() => {
        // 아이디 저장 쿠키가 있을 시 ID 표시
        if (cookie.HUB_SVID) {
            const cookieSaveId = atob(cookie.HUB_SVID);
            setIdValue(cookieSaveId);
        }
    }, []);

    // 아이디 저장 클릭
    const onChangeIdSave = () => {
        setIsIdSave(!isIdSave);
    };

    // 로그인 알림 문구 초기화
    const onFocusAlertClear = () => {
        setIsEmptyId(false);
        setIsEmptyPassword(false);
        setErrorMessage("");
    };

    // 로그인 버튼 클릭
    const onClickLoginButton = async () => {
        // 현재 로그인 중일경우
        if (isLoadingLogin) {
            return;
        }

        // 아이디 입력 여부 체크
        if (idValue === "") {
            setIsEmptyId(true);
            return;
        }

        // 비밀번호 입력 여부 체크
        if (passwordValue === "") {
            setIsEmptyPassword(true);
            return;
        }

        // 로그인 처리
        onFocusAlertClear();
        setIsLoadingLogin(true);

        try {
            await login(idValue, passwordValue);
            // 아이디 저장
            if (isIdSave) {
                // 로그인 성공
                // 10일 뒤 날짜
                const date = new Date();
                date.setDate(date.getDate() + 10);

                // 아이디 저장 쿠키 설정
                setCookie("HUB_SVID", btoa(idValue), {path: "/", expires: date});
            } else {
                // 아이디 저장 체크박스를 해제할 시 쿠키 삭제
                removeCookie("HUB_SVID", {path: "/"});
            }

            setIsLoadingLogin(false);
            // 루트 페이지로 이동
            props.history.push("/");
        } catch {
            // 로그인 오류 메시지
            setIsLoadingLogin(false);
            setErrorMessage(errorMessageList.NOT_FOUND_ID_PW);
            return false;
        }
    };

    return (
        <LoginForm>
            {props.isEn ? (
                <Helmet>
                    <title>ACON3D Partner hub</title>
                    <meta content="ACON3D Partner page" name="description"/>
                </Helmet>
            ) : (
                <></>
            )}
            <LoginFormContainer>
                <TitleText>{t("login.title")}</TitleText>
                <LoginInputContainer>
                    <LoginIdInput>
                        <LoginInput
                            type="text"
                            value={idValue}
                            placeholder={"email@acon3d.com"}
                            onChange={(e) => {
                                setIdValue(e.target.value);
                            }}
                            onFocus={onFocusAlertClear}
                            onKeyDown={(e) => {
                                e.key === "Enter" && onClickLoginButton();
                            }}
                        />
                        {isEmptyId && (
                            <ValidationMessage>{t("login.id.validation")}</ValidationMessage>
                        )}
                    </LoginIdInput>
                    <LoginPasswordInput>
                        <LoginInput
                            type="password"
                            value={passwordValue}
                            placeholder={t("login.pw.placeholder")}
                            onChange={(e) => {
                                setPasswordValue(e.target.value);
                            }}
                            onFocus={onFocusAlertClear}
                            onKeyDown={(e) => {
                                e.key === "Enter" && onClickLoginButton();
                            }}
                        />
                        {isEmptyPassword && (
                            <ValidationMessage>{t("login.pw.validation")}</ValidationMessage>
                        )}
                        {errorMessage !== "" && (
                            <ValidationMessage>{errorMessage}</ValidationMessage>
                        )}
                    </LoginPasswordInput>
                    <IdSaveContainer>
                        <IdSaveCheckbox isChecked={isIdSave} onClick={onChangeIdSave}/>
                        <IdSaveText onClick={onChangeIdSave}>
                            {t("login.idSave")}
                        </IdSaveText>
                    </IdSaveContainer>
                </LoginInputContainer>
                <LoginButton
                    isLoadingLogin={isLoadingLogin}
                    onClick={onClickLoginButton}
                >
                    {!isLoadingLogin ? (
                        <CustomText color={"white"} size={14} weight={500}>
                            {t("login.button")}
                        </CustomText>
                    ) : (
                        <LoginLoader/>
                    )}
                </LoginButton>
                <LoginSearch>
                    <NavLink to={`/password/searchPw`}>
                        <CustomText marginLeft={4}>{t("login.findPw")}</CustomText>
                    </NavLink>
                </LoginSearch>
                <LoginDescription>{t("login.description")}</LoginDescription>
                <JoinContainer>
                    <NavLink to={`/signup/${i18n.language === "ko" ? "ko" : "en"}`}>
                        <Flex>
                            <JoinText>{t("login.join")}</JoinText>
                            <img
                                src="/assets/icon/login-right-arrow.png"
                                width={6}
                                height={10}
                                alt={"loginArrowIcon"}
                                title={"loginArrowIcon"}
                            />
                        </Flex>
                    </NavLink>
                </JoinContainer>
            </LoginFormContainer>
            <Guide/>
        </LoginForm>
    );
});
