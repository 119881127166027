import { useMutation, useQuery } from "@apollo/client";
import { authOrigin } from "api";
import { CREATE_USER_PLAN } from "api/mutations";
import { GET_USER_PLAN } from "api/quries";
import { AppContext } from "app";
import axios from "axios";
import React, { useContext, useState } from "react";
import Commission from "user/value/Commission";
import { PLAN_TYPE } from "user/value/PlanType";
import withUs from "./with-us.png";
import { updateContract } from "api/contract";
import { Box, GlobalStyles, Typography } from "@mui/material";
import Benefits from "./components/Benefits";
import { ReactComponent as IconChevron } from "./components/chevron.svg";
import Partnerships from "./components/Partnerships";
import { useHistory } from "react-router-dom";

export default (props) => {
  const { userInfo, showConfirmMessage } = useContext(AppContext);
  const [createUserPlan] = useMutation(CREATE_USER_PLAN);
  const history = useHistory();
  const { data } = useQuery(GET_USER_PLAN, {
    variables: { type: "파트너십 및 입점계약서" },
  });
  const getSignImg = async () => {
    const result = await axios.post(authOrigin + "/auth/download", {
      id: data.user.settle.imageSign,
    });
    return result.data.fullName;
  };

  const uploadSignedTerm = async (signatureAzureStoragePath, val) => {
    const name = userInfo.isCompany ? data.user.companyName : data.user.name;
    const planType = PLAN_TYPE({ type: 1, isExclusive: val });
    const commissionRate = Commission({
      type: 1,
      isExclusive: val,
      sumDefault: true,
    });

    updateContract({
      userId: data.user.id,
      signatureAzureStoragePath,
      locale: userInfo?.settle?.locale,
      userName: name,
      userContact: data.user.contact,
      userPin: data.user.settle.nationalIdNumber,
      planType,
      commissionRate,
      password: data.user.brand.godoBrandCd,
    }).catch();
  };

  async function onSubmit(val) {
    try {
      const signatureAzureStoragePath = await getSignImg();
      await uploadSignedTerm(signatureAzureStoragePath, val);
      await createUserPlan({ variables: { type: 1, isExclusive: val } });
      history.push("/");
    } catch (err) {
      console.log(err);
      alert("오류");
    }
  }

  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            "& .wrap": {
              paddingLeft: "0 !important",
              paddingRight: "0 !important",
            },
            "& .wrap > div": {
              maxWidth: "100% !important",
            },
          },
        }}
      />
      <Box
        p="60px"
        bgcolor="#fff"
        color="#313135"
        textAlign="center"
        sx={{
          backgroundImage: `url(${withUs})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
          backgroundSize: "min(100%, 750px) auto",
        }}
      >
        <Typography component="h1" fontWeight={800} fontSize="32px">
          파트너십 플랜
        </Typography>
        <Typography mt={1.5} fontSize="14px" lineHeight="20px">
          창작자들의 든든한 지원군,
          <br />
          에이콘3D의 파트너 작가가 되신 것을 환영합니다.
          <br />
          에이콘3D에서는 작가님께서 온전히 작품 활동에 집중하실 수 있도록
          <br />
          아래와 같이 파트너십 제도를 운영하고 있습니다.
        </Typography>
      </Box>

      <Benefits />
      <Partnerships
        onClick={(val) => {
          showConfirmMessage(
            "",
            `<span style="font-weight: 700;">${
              val ? "독점" : "비독점"
            }</span>으로<br/>진행하시겠습니까?`,
            () => onSubmit(val),
            {
              confirmText: "확인",
            }
          );
        }}
      />

      <Box
        component="footer"
        p="48px 60px 140px 60px"
        bgcolor="#FBFBFB"
        textAlign="center"
      >
        <Box
          component="a"
          href="https://www.acon3d.com/ko/toon/intro/3d-creator"
          target="_blank"
          rel="noopener noreferrer"
          p="12px"
          border="1px solid #BCBCBE80"
          color="#313135"
          fontWeight={600}
          borderRadius={1}
          sx={{ "&:hover": { color: "#313135" } }}
        >
          에이콘 판매 가이드 보러가기 <IconChevron />
        </Box>
        <Typography
          mt={1.5}
          color="#313135"
          fontSize="14px"
          lineHeight="22px"
          sx={{ "& span": { fontWeight: 700 } }}
        >
          추가 문의는 <span>contact@acon3d.com</span>으로 부탁 드립니다.
        </Typography>
      </Box>
    </>
  );
};
