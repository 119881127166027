import { selector } from "recoil";
import { godoGoodsNoState } from "..";
import { GodoGoodsNoType } from "../types";

const godoGoodsNoSelector = selector<GodoGoodsNoType>({
  key: 'godoGoodsNoSelector',
  get:({get}) => get(godoGoodsNoState),
  set: ({set}, val) => set(godoGoodsNoState, val),
});

export default godoGoodsNoSelector;
