import styled from 'styled-components';

export const List = styled.div`
    border-top: 1px solid #E0E0E0;
    margin-top: 16px;
    font-weight: 400;
`;

export const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px; 
    border-bottom: 1px solid #E0E0E0;
    min-height: 40px;
    padding-top: 13px;
    padding-bottom: 13px;
`;

export const NameField = styled.div`
    font-size: 12px;
    width: 60%;
    line-height: 100%;
    padding-right: 10px;
    word-break: break-word;
`;

export const NameInputField = styled.input.attrs(props => ({
    type: 'text'
}))`
    font-size: 12px;
    width: 60%;
    line-height: 100%;
    padding-right: 10px;
    word-break: break-word;
`;

export const DateField = styled.div`
    font-size: 12px;
    width: 20%;
    line-height: 100%;
`;
export const Button = styled.div<{ disabled?: boolean; }>`
    background-color: none;  
    cursor: pointer;
`;