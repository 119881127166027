const SKETCHUP = {
    extensionIds: [77, 87, 111],
    id: 3,
    name: "Sketchup",
};

const CLIP_STUDIO_PAINT = {
    extensionIds: [82, 78, 80, 92, 98, 99, 96],
    id: 2,
    name: "Clip Studio Paint",
};

const PHOTOSHOP = {
    extensionIds: [85, 97, 102, 112, 109],
    id: 5,
    name: "Photoshop",
};

const PROCREATE = {
    extensionIds: [101],
    id: 6,
    name: "Procreate",
};

/** 기획서에 지정된 형태
 * https://www.notion.so/acon3d/6a8e5c74b262490c8eacf3d9cb4296e0*/
export const APPLICATION_RULE = [
    {
        extensionIds: SKETCHUP.extensionIds,
        value: {
            id: SKETCHUP.id,
            name: SKETCHUP.name,
        },
    },
    {
        extensionIds: CLIP_STUDIO_PAINT.extensionIds,
        value: {
            id: CLIP_STUDIO_PAINT.id,
            name: CLIP_STUDIO_PAINT.name,
        },
    },
    {
        extensionIds: PHOTOSHOP.extensionIds,
        value: {
            id: PHOTOSHOP.id,
            name: PHOTOSHOP.name,
        },
    },
    {
        extensionIds: PROCREATE.extensionIds,
        value: {
            id: PROCREATE.id,
            name: PROCREATE.name,
        },
    },
];
