import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_CATEGORIES } from "api/quries";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Item, Input, Title, Value } from "../../style";
import Tree from "rc-tree";
import {
  Wrap,
  SelectBox,
  SearchBox,
  ListBox,
  DropdownBox,
  Text,
  CategoryItems,
  Trigger,
} from "./style";
import { Icon } from "rsuite";
import CategoryItem from "./item";
import Arrow from "./arrow";
import { useRecoilState } from "recoil";
import { categorySelector } from "boards/DetailBoardWrite/recoil/selectors";

export default (props) => {
  // 번역도구
  const { t } = useTranslation();
  // URL 파생 정보
  const { lang } = useParams<{ lang?: string; }>();

  // 리스트 표현 여부
  const [isShowList, setIsShowList] = useState(false);

  // 검색 키워드
  const [searchKeyword, setSearchKeyword] = useState("");
  // 확장된 ID들
  const [expandedIds, setExpandedIds] = useState([]);

  const { data, loading } = useQuery(GET_CATEGORIES, {
    variables: {
      lang: lang,
    },
  });
  // 로딩중 여부
  const isLoading = !(!loading && data && data.getCategoriesByLang);
  const [category, setCategory] = useRecoilState(categorySelector);

  // 검색키워드 input 태그 변경 이벤트 처리기 메소드
  const onChangeSearchKeywordInputTag = (e) => {
    // 검색 키워드 설정
    setSearchKeyword(e.target.value);
  };
  // select box 클릭 이벤트 처리기 메소드
  const onClickSelectBox = () => {
    setIsShowList(!isShowList);
  };

  const onExpand = (expandedKeys) => {
    setExpandedIds([...expandedKeys]);
  };

  const onSelect = (selectedKeys) => {
    // 저장할 카테고리들
    // const categories = data.getCategoriesByLang.filter(x => selectedKeys.indexOf(x.id) !== -1);
    // 카테고리 저장
    setCategory(selectedKeys);
  };

    return (
      <Item>
        <Title admin={true}>{t("CategorySettings.label")}</Title>
        <Value>
          {!isLoading &&
            (() => {
              let renderCategories = data.getCategoriesByLang.map((x) => {
                return {
                  key: x.id,
                  parent: x.parent,
                  title: x.i18n[0].name,
                  children: [],
                };
              });
              // 렌더링 할 카테고리 조립
              renderCategories = renderCategories.filter((x) => {
                // 검색키워드에 걸리지 않은경우
                if (x.title.indexOf(searchKeyword) === -1)
                  // 렌더링하지 않음
                  return false;

                // 해당 항목이 부모가 존재할 경우
                if (x.parent) {
                  // 부모 항목 객체
                  let parentItemObj = renderCategories.find(
                    (y) => y.key === x.parent
                  );
                  // push
                  parentItemObj &&
                    parentItemObj.children &&
                    parentItemObj.children.push(x);
                }
                return !x.parent;
              });

              return (
                <>
                  <Wrap>
                    <SelectBox active={isShowList} onClick={onClickSelectBox}>
                      <CategoryItems>
                        {/* 카테고리 항목이 존재하지 않을 경우 placeholder / 존재할 경우 항목 리스트 표시  */}
                        {category.length === 0 ? (
                          <Text>{t("goods.category")}</Text>
                        ) : (
                          category.map((x) => (
                            <CategoryItem
                              id={x}
                              title={
                                data.getCategoriesByLang.find((y) => y.id === x)
                                  .i18n[0].name
                              }
                            />
                          ))
                        )}
                      </CategoryItems>
                      <Arrow active={isShowList} />
                    </SelectBox>
                    {isShowList && (
                      <DropdownBox>
                        <SearchBox>
                          <Input
                            onChange={onChangeSearchKeywordInputTag}
                            value={searchKeyword}
                            placeholder={t("inputSearchKeyword")}
                          />
                        </SearchBox>
                        <ListBox>
                          <Tree
                            multiple={true}
                            treeData={renderCategories}
                            showIcon={false}
                            switcherIcon={(obj) => {
                              if (!obj.isLeaf) {
                                return (
                                  <Trigger>
                                    <Icon
                                      icon={obj.expanded ? "minus" : "plus"}
                                    />
                                  </Trigger>
                                );
                              }
                            }}
                            onSelect={onSelect}
                            onExpand={onExpand}
                            autoExpandParent={false}
                            expandedKeys={expandedIds}
                            selectedKeys={category}
                          />
                        </ListBox>
                      </DropdownBox>
                    )}
                  </Wrap>
                </>
              );
            })()}
        </Value>
      </Item>
    );
};
