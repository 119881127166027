import React, { useState } from 'react';
import QueryString from 'query-string';
import { useTranslation } from 'react-i18next';import { Button, SelectBox } from 'acon-mui/components';
import { isMobile } from 'react-device-detect';
import { Box, TextField } from '@mui/material';
import { ReactComponent as SearchIcon } from 'acon-mui/icons/icon-search.svg';
import { color } from 'acon-mui/style';

export default (({ history, statusFilter=undefined, onClickResetButton }) => {
    // 검색옵션 
    const [searchOption, setSearchOption] = useState({
        keyword: '',
        type: 'title'
    });

    // 번역도구 
    const { t } = useTranslation();

    // 검색 옵션 배열 
    const optionArr = [
        { id: 1, value: 'title', displayName: t("Title.label") },
        { id: 2, value: 'body', displayName: t("Contents.label") },
        { id: 3, value: 'godoGoodsNo', displayName: t('godoNum') }
    ];

    // input 태그 keyup 이벤트 처리기 메소드 
    const onKeyUp = (e) => {
        try {
            // 입력한 키가 엔터일 경우 
            if (e.key === 'Enter') {
                // 검색 실행 
                redirectSearched()
            }
        }
        catch (errObj) {
            // 실패 메세지
            errObj.message = `상품정보를 검색하는 도중(엔터), 예상치 못한 예외 발생(${errObj.message})`;
            // 실패 로그 기록(TODO)
            console.log(errObj);
            // 사용자 안내 메세지 표시(TODO)
            alert('관리자에게 문의해주세요.');
        }
    }
    // 검색 옵션 변경 이벤트 처리기 메소드 
    const onChangeType = (e) => {
        setSearchOption({
            ...searchOption,
            type: optionArr.find(x => x.id === e.target.value).value
        });
    };
    const onChangeKeyword = (e) => {
        setSearchOption({
            ...searchOption,
            keyword: e.target.value
        });
    };
    // 검색된 페이지로 리다이렉트하기 메소드 
    const redirectSearched = () => {
        // 검색 키워드
        const searchKeyword = searchOption.keyword.trim();
        // url 파라메터 객체 
        let urlParamObj = QueryString.parse(history.location.search);
        urlParamObj.page = '1';

        delete urlParamObj.type;
        delete urlParamObj.keyword;
        delete urlParamObj.godoGoodsNo;
        delete urlParamObj.title;
        delete urlParamObj.body;

        // 검색 키워드가 존재하는 경우 
        if (searchKeyword) {
            urlParamObj[searchOption.type] = searchKeyword;
        }
        // 검색 쿼리 문자 
        const queryStr = QueryString.stringify(urlParamObj)
        // 검색페이지로 이동 
        history.push(`?${queryStr}`);
    };

    // submit 이벤트 처리기 메소드 
    const onSubmit = () => {
        try {
            redirectSearched();
        }
        catch (errObj) {
            // 실패 메세지
            errObj.message = `상품정보를 검색하는 도중, 예상치 못한 예외 발생(${errObj.message})`;
            // 실패 로그 기록(TODO)
            console.log(errObj);
            // 사용자 안내 메세지 표시(TODO)
            alert('관리자에게 문의해주세요.');
        }
    };

    return (
        <Box pt={3} display="flex" {...isMobile && { flexDirection: 'column', px: 4 }}>
            <SelectBox
                defaultValue={1}
                options={optionArr.map((x) => ({ id: x.id, label: x.displayName }))}
                onSelect={onChangeType}
                {...isMobile ? { 
                    marginTop: '16px'
                } : {
                    width: "160px",
                    marginLeft: "16px",
                    flexShrink: '0'
                }}
            />
            <TextField
                id="settle-table-search"
                placeholder={t('settle.inputSearchKeyword')}
                fullWidth
                onChange={onChangeKeyword}
                onKeyUp={onKeyUp}
                InputProps={{
                    startAdornment: <SearchIcon style={{ marginRight: '8px' }} />
                }}
                sx={{
                    ...(isMobile ? {
                        mt: 1,
                        '& input': {
                            p: '8.5px 14px'
                        }
                    } : {
                        ml: 1,
                    }),
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: color.primary,
                        },
                    },
                }}
            />
            {statusFilter && statusFilter}
            <Button
                variant={isMobile? "outlined" : "contained"}
                onClick={onClickResetButton}
                fontWeight="700"
                {...isMobile ? { 
                    marginTop: '24px',
                    height: '48px'
                } : {
                    width: "160px",
                    ml: 1,
                    mr: 2,
                    flexShrink: '0'
                }}
            >
                {t('Reset.reset')}
            </Button>
        </Box>
    );
});