import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import {initReactI18next} from 'react-i18next'

const languageCode = navigator.language.split('-')[0];
i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        // 브라우저 언어에서 추출 TODO: v2 처럼 변경되어야 함.
        lng: languageCode === 'ko' ? languageCode : 'en',
        backend: {
            // public 경로 
            loadPath: '/assets/i18n/{{lng}}.json'
        },
        fallbackLng: 'en',
        debug: false,
        react: {
            useSuspense: false
        }
    })
export default i18n
