import { New_Product_Status, ProductStatus } from "generated/graphql";
import { atom } from "recoil";
import { STATUS_DRAFT } from "../constants";
import { initCopyright } from "./constants";
import {
  ApplicationType,
  BrandType,
  CommentType,
  CopyrightType,
  DisplayType,
  FileConfigType,
  FundingType,
  GodoGoodsNoType,
  MainImageType,
  ModelType,
  PackageType,
  ProductNewStatusType,
  RelatedType,
  SalePriceType,
  SubImageType,
  UpdateHistoryType,
} from "./types";

export const displayState = atom<DisplayType>({
  key: "display",
  default: {
    isDisp: false,
    lang: "",
    isEdit: false,
    hasOpened: false,
    isModified: false,
  },
});

export const docIdState = atom<string>({
  key: "docId",
  default: "",
});

export const titleState = atom<string>({
  key: "title",
  default: "",
});

export const descriptionState = atom<string>({
  key: "description",
  default: "",
});

export const brandState = atom<BrandType>({
  key: "brand",
  default: {
    brandId: null,
    brandName: "",
  },
});

export const statusState = atom<ProductStatus>({
  key: "statusState",
  default: STATUS_DRAFT,
});

export const newStatusState = atom<ProductNewStatusType>({
  key: "newStatusState",
  default: New_Product_Status.Draft,
});

export const versionState = atom<string>({
  key: "version",
  default: "",
});

export const categoryState = atom<number[]>({
  key: "category",
  default: [],
});

export const commentState = atom<CommentType>({
  key: "comment",
  default: {
    rejectComment: null,
    updateRequest: null,
    updateResponse: null,
  },
});

export const commissionState = atom<number | string>({
  key: "commission",
  default: "",
});

export const contentsState = atom<string>({
  key: "contents",
  default: "",
});

export const copyrightState = atom<CopyrightType>({
  key: "copyright",
  default: {
    isErrorFreeCopyright: false,
    isErrorPayCopyright: false,
    isErrorWarehouseCopyright: false,
    isErrorAgree: false,
    copyright: initCopyright,
  },
});

export const dynamicItemState = atom<string>({
  key: "dynamicItem",
  default: "",
});

export const extensionState = atom<number[]>({
  key: "extension",
  default: [],
});

export const fileConfigState = atom<FileConfigType[]>({
  key: "fileConfig",
  default: [],
});

export const modelState = atom<ModelType>({
  key: "model",
  default: {
    modelFileId: null,
    modelKey: "",
    modelName: "",
    modelConfig: [],
  },
});

export const godoGoodsNoState = atom<GodoGoodsNoType>({
  key: "godoGoodsNo",
  default: {
    originGodoGoodsNo: null,
    godoGoodsNo: null,
  },
});

export const keywordsState = atom<string>({
  key: "keywords",
  default: "",
});

export const languagesState = atom<string[]>({
  key: "languages",
  default: [],
});

export const mainImageState = atom<MainImageType>({
  key: "mainImage",
  default: {
    mainImage: {
      imagePath: "",
      imageName: "",
      cropInfo: null,
    },
    thumbnail: {
      imagePath: "",
      imageName: "",
      cropInfo: null,
    },
  },
});

export const subImageState = atom<SubImageType>({
  key: "subImage",
  default: {
    subImage1: {
      imagePath: "",
      imageName: "",
      cropInfo: null,
    },
    subImage2: {
      imagePath: "",
      imageName: "",
      cropInfo: null,
    },
    subImage3: {
      imagePath: "",
      imageName: "",
      cropInfo: null,
    },
  },
});

export const updateHistoryState = atom<UpdateHistoryType[]>({
  key: "updateHistory",
  default: [],
});

export const salePriceState = atom<SalePriceType>({
  key: "salePrice",
  default: {
    isFree: false,
    salePrice: "",
    price: "0",
  },
});

export const relatedState = atom<RelatedType[]>({
  key: "related",
  default: [],
});

export const packageState = atom<PackageType>({
  key: "package",
  default: null,
});

export const applicationsState = atom<ApplicationType[]>({
  key: "applications",
  default: [],
});

export const fundingState = atom<FundingType>({
  key: "funding",
  default: null,
});
