import { selector } from "recoil";
import { keywordsState } from "..";

const keywordsSelector = selector<string>({
  key: 'keywordsSelector',
  get:({get}) => get(keywordsState),
  set: ({set}, val) => set(keywordsState, val),
});

export default keywordsSelector;
