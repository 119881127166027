import { godoGoodsNoSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import ACON from 'lib/global';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Item, Title, Input, Value } from '../../style';

export default (() => {
    // 번역도구 
    const { t } = useTranslation();
    const [godoGoodsNo, setGodoGoodsNo] = useRecoilState(godoGoodsNoSelector);

    // input 태그 변경 이벤트 처리기 메소드 
    const onChange = (e) => {
        // 숫자만 입력가능 
        setGodoGoodsNo({
            ...godoGoodsNo,
            godoGoodsNo: e.target.value ? ACON.OnlyNumber(e.target.value) : null
        });
    };
    return (
        <Item>
            <Title admin={true}>{t("detailBoard.godoItemNum")}</Title>
            <Value>
                <Input
                    maxLength={10}
                    placeholder={t("input.godoItemNum")}
                    value={godoGoodsNo.godoGoodsNo}
                    onChange={onChange}
                />
            </Value>
        </Item>
    );
});