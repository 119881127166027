import { Box, Button, Link, Typography } from '@mui/material';
import { AppContext } from 'app';
import { brandSelector, godoGoodsNoSelector, packageSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import { PackageProductType } from 'boards/DetailBoardWrite/recoil/types';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Item, PackageSwitch, Title, Box as Container } from '../../style';
import EditModal from './EditModal';
import PackageTable from './PackageTable';
import { PackageSetButtonStyle } from './style';

export default(() => {
  const { userInfo } = useContext(AppContext);
  const { isAdmin } = userInfo;
  const { lang } = useParams<{ lang?: string }>();
  const inputRef = useRef<HTMLInputElement>();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [brand] = useRecoilState(brandSelector);
  const [godoGoodsNo] = useRecoilState(godoGoodsNoSelector);
  const [packageState, setPackageState] = useRecoilState(packageSelector);
  // 갱신 이전 세트 상품의 작가 id
  const [currentPackageBrandId, setCurrentPackageBrandId] = useState(brand.brandId);
  const [initialChecked, setChecked] = useState(false);


  const handleSwitch = e => {
    if (e.currentTarget.checked) {
      setPackageState({
        type: 'parent',
        children: [],
      });
      setChecked(true);
      return;
    }
    setPackageState(null);
    setChecked(false);
    return;
  };

  useEffect(() => {
    // 처음 렌더링 될 때 정보가 있을 때
    if (inputRef && inputRef.current && packageState && packageState.type === 'parent') {
      setChecked(true);
    }
  }, [inputRef, packageState]);

  useEffect(() => {
    // 작가 바뀔 때마다 초기화
    if (currentPackageBrandId && currentPackageBrandId !== brand.brandId) {
      // 이렇게 설정 안하면 토글 체크된 상태 안 풀림.
      if (inputRef && inputRef.current && packageState && packageState.type === 'parent') {
        setChecked(false);
      }
      setPackageState(null);
    }
    setCurrentPackageBrandId(brand.brandId);
  }, [brand]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title>세트 설정</Title>
        {isAdmin && (
          <PackageSwitch 
            sx={{ 
              marginLeft: 'auto',
              ...(packageState && packageState.type === 'child' && {
                display: 'none'
              })
            }} 
            inputRef={inputRef}
            checked={initialChecked}
            disabled={!brand.brandId || !godoGoodsNo.godoGoodsNo}
            onChange={handleSwitch}
          />
        )}
      </div>
      <Container>
        <Item>
          {packageState && packageState.type === 'parent' && (
            <>
              <Box display="flex" alignItems="center">
                <Box 
                  width="4px" 
                  height="4px" 
                  borderRadius="2px" 
                  bgcolor="#FF00C3"
                />
                <Typography
                  ml="10px"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="20px"
                  color="#333"
                >
                  세트 구성 상품
                </Typography>
              </Box>
              <PackageTable 
                products={packageState.children}
                style={{ marginTop: '24px' }} 
              />
              <Button 
                onClick={() => setModalOpen(true)} 
                disableRipple
                disabled={!brand.brandId}
                sx={{ ...PackageSetButtonStyle, marginTop: '24px' }}
              >
                구성 설정
              </Button>
            </>
          )}
          {packageState && packageState.type === 'child' && (
            <Box display="flex">
              <Typography
                fontSize="14px"
                fontWeight="400"
                lineHeight="20px"
                color="#333"
              >
                연결되어 있는 세트 상품 번호&#32;:&#32;
              </Typography>
              {packageState?.parents && packageState?.parents.length > 0 && packageState?.parents.map(
                (product: PackageProductType, idx: number) => (
                  <Fragment key={product.docId}>
                    <Link
                      href={`/goods/write/${lang}/${product.docId}`}
                      marginLeft="4px"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="20px"
                      color="#333"
                      sx={{
                        textDecoration: 'underline',
                        '&:hover': {
                          color: "#333",
                        },
                        '&:foucs': {
                          color: "#333",
                        },
                        '&:visited': {
                          color: "#333",
                        }
                      }}
                    >
                      {product.id}
                    </Link>
                  </Fragment>
                )
              )}
            </Box>
          )}
        </Item>
        <EditModal 
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)} 
        />
      </Container>
    </>
  );
});
