import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Icon, RadioGroup, Radio } from 'rsuite';
import * as Styled from './styled';

// 언어 선택 모달 
export default (({ setCookie }) => {
    const { lang } = useParams();

    const history = useHistory();

    const [isChecked, setIsChecked] = useState(false);
    const [isShow, setIsShow] = useState(true);
    const [checkedLang, setCheckedLang] = useState(lang);

    const onClose = () => {
        setIsShow(false);
    };
    const onChange = () => {
        setIsChecked(!isChecked);
    };
    // 확인 버튼 클릭 이벤트 처리기 메소드 
    const onClickConfirmButton = () => {
        if (isChecked)
            setCookie && setCookie('rememberLanguage', checkedLang);

        if (lang !== checkedLang)
            history.push(`/goods/write/${checkedLang}`);
        // 모달 종료 
        onClose();
    };

    return (
        <Styled.SelectLanguageModal show={isShow} onHide={onClose} >
            <Styled.Header>
                <Styled.EmptyButton onClick={onClose}>
                    <Icon icon="close" />
                </Styled.EmptyButton>
            </Styled.Header>
            <Modal.Body>
                <Styled.Title>Select project Language</Styled.Title>
                <Styled.Contents>
                    <div style={{ marginBottom: `20px` }}>
                        Please select the language of your project page.
                    </div>
                    <div>
                        <RadioGroup
                            inline
                            value={checkedLang}
                            onChange={lang => {
                                setCheckedLang(lang);
                            }}
                        >
                            <Radio value="ko">한국어</Radio>
                            <Radio value="en">English</Radio>
                            <Radio value="cn">中文</Radio>
                            <Radio value="jp">日本語</Radio>
                        </RadioGroup>
                    </div>
                </Styled.Contents>
                <Styled.Footer>
                    <div style={{ display: `flex`, alignItems: `center`, marginRight: `20px` }}>
                        <input type="checkbox" onChange={onChange} checked={isChecked} id="rememberLanguage" style={{ marginRight: `12px`, width: `16px`, height: `16px` }} />
                        <label htmlFor="rememberLanguage" style={{ fontSize: `12px`, color: `#7C7C7C`, marginBottom: 0 }}>Remember</label>
                    </div>
                    <Styled.EmptyButton onClick={onClickConfirmButton} style={{}}>
                        <span style={{ color: `#F300BA` }}>OK</span>
                    </Styled.EmptyButton>
                </Styled.Footer>
            </Modal.Body>
        </Styled.SelectLanguageModal>
    )
})