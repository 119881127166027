import { Box, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, Container } from 'acon-mui/components';
import { color } from 'acon-mui/style';
import dayjs from 'dayjs';
import { displayMoneyByLang } from 'lib/multiLocale';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from './style';
import Chevron from 'acon-mui/icons/icon-chevron.svg';
import { useGetSettleQuery } from 'generated/graphql';
import queryString from "query-string";
import { AppContext } from 'app';

export default function (props) {
  const { history, match: { params } } = props;
  const { userInfo } = useContext(AppContext);
  const { data, loading } = useGetSettleQuery({ variables: {
    goodsNo: Number(params.goodsNo),
    orderNo: params.orderNo,
  }});
  const settleData = (data && data.getSettleV2) ? data.getSettleV2 : undefined; 
  const { t, i18n } = useTranslation('common');

  const handleClickReturnButton = () => {
    const params = localStorage.getItem('settle_url_params');
    if (params) {
      const obj = JSON.parse(params);
      for (let key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === undefined) delete obj[key];
      }
      localStorage.removeItem('settle_url_params');
      history.push({
        pathname: '/manager/settle',
        search: queryString.stringify(obj)
      })
      return;
    }
    window.location.href = '/manager/settle';
    return;
  };

  if (loading) return <></>;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Page className="settle_page" isAdmin={userInfo.isAdmin}>
        <Typography
          fontSize="24px"
          lineHeight="1.5"
          fontWeight='700'
          color={color.text.primary}
        >
          {t('settleList')}
        </Typography>

        <Button
          variant="text" 
          colorTheme="normal" 
          startIcon={<img alt="chevron" src={Chevron} style={{ transform: 'rotate(90deg)', marginRight: '10px', width: '14px', height: 'auto' }} />}
          width="fit-content"
          marginTop="40px"
          onClick={handleClickReturnButton}
        >
          <>{t('backToList')}</>
        </Button>

        {settleData && (
          <Container 
            marginTop="24px"
            fontSize="14px"
            lineHeight="22px"
            fontWeight="400"
          >
            <Typography color={color.text.secondary}>
              {t('settleTable.projectTitle')}
            </Typography>
            <Typography color={color.text.primary} mt='8px'>
              {settleData.goodsNm}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.buyername')}
            </Typography>
            <Typography color={color.text.primary} mt='8px'>
              {settleData.orderName}
            </Typography>


            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.penName')}
            </Typography>
            <Typography color={color.text.primary} mt='8px'>
              {settleData.licenseName || '-'}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.nation')}
            </Typography>
            <Typography color={color.text.primary} mt='8px'>
              {settleData.country || '-'}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.appliedWork')}
            </Typography>
            <Typography color={color.text.primary} mt='8px'>
              {settleData.licenseType === "개인" 
                || settleData.licenseType === "personal" ? (
                <>{t("personalLicense")}</>
              ) : settleData.licenseType ? (
                <>{t("companyLicense")}</>
              ) : (
                <>{t("settleTable.noAppliedWork")}</>
              )}
            </Typography>
              
            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.workUsedThisProject')}
            </Typography>
            <Typography color={color.text.primary} mt='8px'>
              {settleData.projectName || '-'}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.orderDate')}
            </Typography>
            <Typography color={color.text.primary} mt='8px'>
              {dayjs(settleData.paymentDt).format("YY-MM-DD\u00a0\u00a0HH:mm")}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.settlementDate')}
            </Typography>
            <Typography color={color.text.primary} mt='8px'>
              {dayjs(settleData.settleDt).format("YY-MM-DD")}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.normalPrice')}
            </Typography>
            <Typography color={color.text.primary} mt='8px'>
              {(() => {
                const value = displayMoneyByLang(Number(settleData.goodsPrice),i18n.language);
                return <>{value}</>;
              })()}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.creatorDiscount')}
            </Typography>
            <Typography color={color.text.primary} mt='8px'>
              · {t('settleTable.benefitDiscount')}:&nbsp;
              {(() => {
                const value = displayMoneyByLang(Number(settleData.goodsDcPrice),i18n.language);
                return <>{value}</>;
              })()}
            </Typography>
            <Typography color={color.text.primary}>
              · {t('settleTable.couponDiscount')}:&nbsp;
              {(() => {
                const value = displayMoneyByLang(Number(settleData.couponGoodsDcPrice),i18n.language);
                return <>{value}</>;
              })()}
            </Typography>

            <Typography color={color.text.secondary} mt="24px">
              {t('settleTable.settlePrice')} &#40;{t('settleTable.normalPrice')} -  {t('settleTable.creatorDiscount')}&#41;
            </Typography>
            <Typography color={color.text.primary} mt='8px'>
              {(() => {
                const value = displayMoneyByLang(
                  Number(settleData.goodsPrice) -
                    Number(settleData.goodsDcPrice) -
                    Number(settleData.couponGoodsDcPrice),
                  i18n.language
                );
                return <>{value}</>;
              })()}
            </Typography>
          </Container>
        )}
        <Box pt="100px" />
      </Page>
    </LocalizationProvider>
  );
};
