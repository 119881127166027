import { LanguageCodeEnum, useTranslateProductContentMutation, useTranslateProductFileMutation } from 'generated/graphql';
import React, { useState, useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { AppContext } from 'app';
import { Icon, Checkbox } from 'rsuite';
import * as Styled from './styled';
import { useRecoilState } from 'recoil';
import { productAllInfoSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import convertLanguageCode from "utils/convertLanguageCode";
import { LanguageEnum } from 'boards/DetailBoardWrite/recoil/types';
import { TranslateModalType } from 'boards/DetailBoardWrite/constants';

// 메일발송 모달 
export default (({ isAdmin, isShow, type, onClose, onSave }) => {
    const { lang, docId } = useParams<{ docId?: string; lang?: LanguageEnum;  }>();
    const language = convertLanguageCode(lang);
    const langs = ['ko', 'en', 'zh', 'ja'];
    const langName = {
        'ko': '한국어',
        'en': 'English',
        'zh': '中文',
        'ja': '日本語',
    };
    const [targetLangs, setTargetLangs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [productData, setProductData] = useRecoilState(productAllInfoSelector);
    const { showAlertMessage } = useContext(AppContext);

    useEffect(() => {
        setTargetLangs(langs.filter(x => x !== language));
    }, [lang]);

    // 번역
    const [translateProductContent] = useTranslateProductContentMutation();
    const [translateProductFile] = useTranslateProductFileMutation();

    const onChangeCheckboxItem = (langCode) => {
        if (targetLangs.includes(langCode)) {
            setTargetLangs(targetLangs.filter(x => x !== langCode));
            return 
        }

        setTargetLangs([...targetLangs, langCode]);
    };

    const onClickTranslate = async () => {
        setIsLoading(true);
        const result = await onSave();

        if (result) {
            setTimeout(() => {
                let translate: (options?: {
                    variables?: {
                        input: {
                            docId: string;
                            departure: LanguageCodeEnum;
                            destinations: LanguageCodeEnum[];
                        }
                    }
                }) => Promise<any> = translateProductContent;
                if (type === TranslateModalType.FILE) {
                    translate = translateProductFile;
                }

                translate({
                    variables: {
                        input: {
                            docId,
                            departure: language,
                            destinations: targetLangs
                        }
                    }
                }).then(result => {
                    if (result) {
                        // 데이터 조립
                        setProductData({languages: ['ko', 'en', 'cn', 'jp']});
                        // 안내 메세지 표시
                        showAlertMessage(
                            "작업이 완료되었습니다.",
                            "언어를 선택할 수 있습니다."
                        );
                        
                        setIsLoading(false);
                        onClose && onClose();
                    }
                }).catch(e => {
                    translate({
                        variables: {
                            input: {
                                docId,
                                departure: language,
                                destinations: targetLangs
                            }
                        }
                    }).then(result => {
                        if (result) {
                            // 데이터 조립
                            setProductData({languages: ['ko', 'en', 'cn', 'jp']});
                            // 안내 메세지 표시
                            showAlertMessage(
                                "작업이 완료되었습니다.",
                                "언어를 선택할 수 있습니다."
                            );
                            
                            setIsLoading(false);
                            onClose && onClose();
                        }
                    }).catch(() => {
                        alert('번역 중 오류가 발생하였습니다.');
                        onClose && onClose();
                    });
                });
            }, 1000);
        } else {
            alert('저장 중 오류가 발생하였습니다.');
            onClose();
        }
    };

    return (
        <Styled.SelectLanguageModal show={isShow} onHide={onClose} >
            <Styled.Header>
                <Styled.EmptyButton onClick={onClose}>
                    <Icon icon="close" />
                </Styled.EmptyButton>
            </Styled.Header>
            <Modal.Body>
                <div>
                    <Styled.Field>
                        <Styled.Title>출발언어</Styled.Title>
                        <Checkbox
                            isAdmin={isAdmin}
                            disabled={true}
                            checked={true}
                            onChange={onChangeCheckboxItem}
                        >
                            {langName[language]}
                        </Checkbox>
                    </Styled.Field>
                    
                    <Styled.Field>
                        <Styled.Title>도착언어</Styled.Title>
                        {langs.filter(x => x !== language).map((lang, i) => {
                            return (
                                <Checkbox
                                    key={i}
                                    isAdmin={isAdmin}
                                    checked={targetLangs.includes(lang)}
                                    value={lang}
                                    onChange={onChangeCheckboxItem}
                                >
                                    {langName[lang]}
                                </Checkbox>    
                            )
                        })}
                    </Styled.Field>
                </div>
            </Modal.Body>

            <Styled.ConfirmButton disabled={isLoading || targetLangs.length === 0} onClick={onClickTranslate} style={{ minHeight: '44px' }}>
                {isLoading ? <Styled.Loader /> : '저장 후 번역'}
            </Styled.ConfirmButton>
        </Styled.SelectLanguageModal>
    )
})