import styled from 'styled-components';

export const Box = styled.div`
    ${(props: { image?: boolean|string; drag?: boolean|string; crop?: any; }) => props.image ? `
        & > div {
            width: 100%;
            height: 100%;
        }
    ` : ``}
    ${(props: { image?: boolean|string; drag?: boolean|string; crop?: any; }) => props.drag ?
        `border: 2px solid #0094FF;` :
        `border: 1px solid #CDCDCD;`
    }
    img {
        ${(props: { image?: boolean|string; drag?: boolean|string; crop?: any; }) => props.drag ? `opacity: 0.1;` : ``}
    }
    
    width: 100%;
    height: 302px;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    .cropper-point.point-nw {
        left: -6px;
        top: -6px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        opacity: 1;
        display: block !important;
    }
    .cropper-point.point-se {
        bottom: -6px;
        right: -6px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        opacity: 1;
        display: block !important;
    }
    .cropper-line,
    .cropper-point {
        background-color: #c7c7c7;
        display: none;
    }
    .cropper-view-box {
        outline: 3px solid #c7c7c7;
        outline-color: #c7c7c7;
    }
    .cropper-disabled {
        .cropper-view-box {
            outline: 3px solid #19B400;
            outline-color: #19B400;
        }
        .cropper-line,
        .cropper-point {
            background-color: #19B400;
        }
    }
`;
export const Wrap = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 3.5px;
    padding-right: 3.5px;
    ${(props: { active?: boolean }) => !props.active ? 'display: none;' : ''}
`;

export const Container = styled.div`
    width: 255px;
`;
export const ImageUploadLogo = styled.img`
    width: 75px;
`;
export const Ul = styled.ul`
    list-style-type: disc;
    margin-left: 40px; 
    padding-top: 5px;
`;
export const Li = styled.li``;
export const Wording = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 50px;
    position: absolute;
    left: 0%;
`;
export const File = styled.input.attrs(props => ({
    type: 'file'
}))`
    display: none !important;
`;

export const DragAndDrop = styled.div`
    width: 100%;
    height: 302px;
    position: absolute;
    z-index: 1;
    opacity: 0;
`;