import { salePriceSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Item, Title, Value, Input } from '../../style';

export default (() => {
    // 번역도구 
    const { t } = useTranslation();
    const [price, setPrice] = useRecoilState(salePriceSelector);

    return (
        <Item>
            <Title admin={true}>
                {t("ListPrice.label")}
            </Title>
            <Value>
                <Input
                    maxLength={13}
                    type="text"
                    value={price.price}
                    onChange={(e) => {
                        setPrice({ price: e.target.value, });
                    }}
                />
            </Value>
        </Item>
    );
});