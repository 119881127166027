import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { Item, Title } from "../../style";
import { GET_IS_VALID_GODO_GOODS_NO } from "api/quries";
import { useQuery } from "@apollo/client";
import { AppContext } from "app";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { docIdSelector, relatedSelector } from "boards/DetailBoardWrite/recoil/selectors";

export default function Related() {
  const { t } = useTranslation();
	const [docId] = useRecoilState(docIdSelector);
	const [related, setRelated] = useRecoilState(relatedSelector);

  const { showAlertMessage } = useContext(AppContext);
	const [products, setProductsValue] = useState([]);
	const [productsValueInput, setProductsValueInput] = useState("");

	const getIsValidGodoGoodsNo = useQuery(GET_IS_VALID_GODO_GOODS_NO, {
		skip: true,
	});

	// 표시위치 입력시 리턴되는 array 값을 productsValue 변수에 지정해줍니다.
	// products 변수는 <CreatableSelect />의 prop으로 사용됩니다.
	const onChangeProducts = (value) => {
		setProductsValue(value);
	};

	// 표시위치 입력시 리턴되는 string 값을 productsValueInput 변수에 지정해줍니다.
	// productsValueInput 변수는 <CreatableSelect />의 prop으로 사용됩니다.
	const onChangeProductsInput = (inputValue) => {
		setProductsValueInput(inputValue);
	};

	// productsValueInput 변수 값(string)으로부터 productsValue 변수 array의 값으로 formatting해주는 함수입니다.
	const createOption = (label) => ({ label, value: label });

	// 표시위치 입력 중 Enter, Tab 키를 누를 때 작동하는 함수입니다.
	// productsValueInput(string)으로부터 products(array)에 들어갈 객체를 오브젝트 포맷으로 만들어 입력합니다.
	// products 값 입력 후에는 productsValueInput 값을 빈 string으로 바꿔줍니다.
	// input DOM 객체의 값이 사라지고 기존에 입력했던 값이 태그 모양으로 바뀌어 추가되는 로직에 해당합니다.
	const onKeyDownProducts = async (event) => {
		if (!productsValueInput) return;

		switch (event.key) {
			case "Enter":
			case "Tab":
				// 고도몰 번호 여부 체크
				const isValidGodoGoodsNo = await getIsValidGodoGoodsNo.refetch({
					docId,
					godoGoodsNo: +productsValueInput,
				});

				if (!isValidGodoGoodsNo.data.isValidGodoGoodsNo) {
					// 해당 상품 제외 일치하는 고도몰아이디가 없을 경우
					showAlertMessage(
						t("requestError"),
						"해당 고도몰 번호가 존재하지 않습니다.<br>고도몰 번호를 확인해주세요"
					);
					return false;
				}

				if (products) {
					setProductsValue([
						...products,
						createOption(productsValueInput),
					]);
				} else {
					setProductsValue([createOption(productsValueInput)]);
				}
				setProductsValueInput("");
				event.preventDefault();
		}
	};

	useEffect(() => {
		if (related?.length && products?.length < 1) {
			const newProducts = related.map((prod) => createOption(prod));

			if (products !== newProducts) {
				setProductsValue(newProducts);
			}
		};
	}, [related]);

	useEffect(() => {
		setRelated(products?.map(x => parseInt(x.value, 10)));
	}, [products])

	return (
		<Item>
			<Title admin>다른 확장자 상품</Title>
			<CreatableSelect
				components={{ DropdownIndicator: null }}
				inputValue={productsValueInput}
				isClearable
				isMulti
				menuIsOpen={false}
				onChange={onChangeProducts}
				onInputChange={onChangeProductsInput}
				onKeyDown={onKeyDownProducts}
				placeholder={
					"동일 디자인의 다른 확장자 상품 번호를 입력합니다. (Tab, Enter)"
				}
				value={products}
			/>
		</Item>
	);
}
