import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "logo/Logo";
import { Box, Stack, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import GnbTab from "./GnbTab";
import AccountPopover from "./AccountPopover";
import useAuth from "hooks/useAuth";
import { PATH_GUEST, PATH_PAGE, PATH_SETTLE } from "routes/paths";
import useResponsive from "hooks/useResponsive";
import { SIZE } from "../constants";
import AdminGnb from "./AdminGnb";

const HeaderWrapper = styled("div")`
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 102;
  top: 0;
  background-color: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
`;

const HeaderContainer = styled("div")<{ maxWidth?: string }>`
  width: 1092px;
  max-width: ${(props) => props.maxWidth ? props.maxWidth : '100%'};
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
`;

export default function Header() {
  const { pathname } = useLocation();
  const { user, isAuthenticated } = useAuth();
  const { t, i18n } = useTranslation();
  const isDesktop = useResponsive("up", SIZE.DESKTOP);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const MENUS = (isAuthenticated: boolean) => {
    if (!isAuthenticated)
      return [
        {
          text: t("signin"),
          link: PATH_GUEST.signin,
        },
        {
          text: t("gnbSignup"),
          link: PATH_GUEST.signup(i18n.language),
        },
      ];
    else
      return [
        {
          text: t("projectList"),
          link: PATH_PAGE.root,
        },
        {
          text: t("settleList"),
          link: PATH_SETTLE.root,
        },
      ];
  };

  return (
    <HeaderWrapper>
      <Box
        sx={{
          height: "60px",
        }}
      >
        <HeaderContainer>
          <Logo subText="PARTNER HUB" />
          <Stack direction="row" spacing={5} alignItems={"center"}>
            {(pathname.includes('signup') || pathname.includes('signin') || (user &&
              user.acceptStatus !== -1 &&
              (isDesktop || !isAuthenticated))) && (
                <Stack direction="row" spacing={4.5} height={"100%"}>
                  {MENUS(isAuthenticated).map((x, i) => (
                    <NavLink 
                      key={`gnb_${i}`} 
                      to={x.link} 
                      {...(
                        (x.text === t("signin") && pathname.includes('signin'))
                        || (x.text === t("gnbSignup") && pathname.includes('signup'))
                      ) ? { style: { paddingBottom: '2px', borderBottom: '2px solid #333333' } } 
                        : { cursor: 'pointer' }}
                      >
                      <GnbTab text={x.text} />
                    </NavLink>
                  ))}
                </Stack>
              )}
            {isAuthenticated && (
              <>
                <button
                  type="button"
                  onClick={handleClick}
                  style={{ background: "none" }}
                >
                  <Stack direction="row" alignItems={"center"}>
                    <Stack
                      sx={{
                        backgroundColor: "#E2E2E2",
                        borderRadius: "80px",
                      }}
                      width={"32px"}
                      height={"32px"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img src="/assets/icon/ic_person.svg" />
                    </Stack>
                    <img src="/assets/icon/ic_caret_down.svg" />
                  </Stack>
                </button>
                <AccountPopover
                  open={open}
                  onClose={handleClose}
                  anchorEl={anchorEl}
                />
              </>
            )}
          </Stack>
        </HeaderContainer>
      </Box>
      {isAuthenticated && user.isAdmin && (
        <Box
          sx={{
            width: '100%',
            height: "50px",
            backgroundColor: "#222",
            overflowX: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none',
            }
          }}
        >
          <HeaderContainer maxWidth="1092px">
            <AdminGnb />
          </HeaderContainer>
        </Box>
      )}
    </HeaderWrapper>
  );
}
