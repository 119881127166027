import { ProductStatus } from "generated/graphql";
import { selector } from "recoil";
import { statusState } from "..";

const statusSelector = selector<ProductStatus>({
  key: 'statusSelector',
  get: ({ get }) => get(statusState),
  set: ({ set }, val) => set(statusState, val),
});

export default statusSelector;
