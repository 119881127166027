import { selector } from "recoil";
import { categoryState } from "..";

const categorySelector = selector<number[]>({
  key: 'categorySelector',
  get:({get}) => get(categoryState),
  set: ({set}, val) => set(categoryState, val),
});

export default categorySelector;
