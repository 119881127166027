import styled from "styled-components";

export const Span = styled.span`
  padding-right: 5px;
`;

export const NameInputField = styled.input.attrs((props) => ({
  type: "text",
}))`
  font-size: 12px;
  width: 60%;
  margin-top: 5px;
  line-height: 100%;
  padding-right: 10px;
  word-break: break-word;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
  top: 13px;
`;

export const ButtonWrapper2 = styled.div`
  display: flex;
  position: absolute;
  margin-top: 5px;
  right: 20px;
  top: 13px;
`;

export const Box = styled.div`
  ${(props) =>
    props.p &&
    `
        padding: ${props.p}px;
    `}
`;
