import { UnderButton } from "../../component/form";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import context from "../../provider/product";
import { Description, Flex, Item, Title, Value, ProjectFilesButtom } from "../../style";
import { File, ModelInput } from "./style";
import Progress from "./progress";
import { downloadFile, fileController } from "api";
import Loading from "common/editor/Loading";
import JSZip from "jszip";
import { useQuery } from "@apollo/client";
import { GET_EXTENSION_CONFIGS } from "../../../../api/quries";

export default ({ setIsInProgress, isAccept, isAdmin }) => {
  // 번역도구
  const { t } = useTranslation();

  const { state, dispatch } = useContext(context);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const { data: extensionConfigData, loading } = useQuery(GET_EXTENSION_CONFIGS, { skip: !isAdmin });

  // 확장자 리스트 정보 로딩여부 판단.
  const isLoading = !(!loading && extensionConfigData && extensionConfigData.getExtensionConfigs);
  const extensions = (() => {
    if (isLoading) return [];
    return extensionConfigData.getExtensionConfigs.map(x => {
      return {
        value: x.id,
        label: x.name,
      }
    });
  })();

  // 업로더
  const uploader = new fileController();
  // 파일 태그
  const fileTag = useRef(null);

  const onClick = () => {
    // 파일 태그 클릭
    fileTag.current.click();
  };
  const onClickCancelButtonTag = () => {
    const wrap = document.getElementById("goods-write-progress");
    wrap.style.visibility = "hidden";

    dispatch({ key: "modelFileName", type: "input", value: "" });
    dispatch({ key: "modelFileId", type: "input", value: "" });
    setIsInProgress(false);
    // 업로드 취소
    uploader.cancel();
  };
  // 파일 변경 이벤트 처리기 메소드
  const onChangeFile = async () => {
    // 파일
    const file = fileTag.current.files[0];

    dispatch({ key: "modelFileName", type: "input", value: file.name });
    // 진행중
    setIsInProgress(true);

    const { key } = await uploader.upload(file, (count) => {
      const wrap = document.getElementById("goods-write-progress");
      const inner = document.getElementById("goods-write-progress-value");

      wrap.style.visibility = "visible";
      inner.style.width = `${count}%`;

      // 완료된 경우
      if (count === 100) {
        wrap.style.visibility = "hidden";
        // 진행 종료
        setIsInProgress(false);
      }
    });
    const fileName = file.name.split(".")[0];
    dispatch({ key: "modelKey", type: "input", value: key });
    dispatch({ key: "modelName", type: "input", value: fileName });

    if (isAdmin) {
      await setModelFilesInZip(file, extensions);
    }
  };

  const setModelFilesInZip = async (file, extensionList) => {
    if (!file) return;
    else if (file.type.indexOf("zip") === -1) return;

    setIsShowLoading(true);

    const fileConfigs = [];
    const extensions = [];

    const zipFile = await JSZip.loadAsync(file);
    for (const fileName in zipFile.files) {
      if (zipFile.files[fileName].dir) continue;
      // 확장자 리스트에 포함되어있는지 체크하여 파일구성 불러오도록.
      const extension = "." + fileName.split(".").pop().toLowerCase();
      const extensionId = extensionList.find(x => x.label === extension)?.value;
      if (!extensionId) { continue; }

      // 폴더 경로 제거하고 파일명만 불러오도록 처리.
      // 확장자 소문자로 불러와야하여 확장자 제거 후 파일명 수집
      const fileNameWithoutExtension = fileName
        .split("/")
        .pop()
        .split(".")
        .shift();
      const fileArrayBuffer = await zipFile.file(fileName).async("ArrayBuffer");
      const fileSize =
        Math.round((fileArrayBuffer.byteLength / 1024 / 1000) * 10) / 10;
      fileConfigs.push({
        id: -1,
        name: fileNameWithoutExtension + extension,
        size: fileSize < 1 ? 1 : fileSize,
      });

      extensions.push(extensionId);
    }

    dispatch({
      key: "fileConfig",
      type: "input",
      value: fileConfigs,
    });
    dispatch({
      key: "extension",
      type: "input",
      value: [...new Set(extensions)],
    });

    setIsShowLoading(false);
  };

  // 다운로드 버튼 클릭 이벤트 처리기 메소드
  const onClickDownloadButtonTag = async () => {
    setIsShowLoading(true);
    console.log("start");
    // 모델 파일 다운로드
    await downloadFile(state.modelFileId, state.modelFileName);
    console.log("end");
    setIsShowLoading(false);
  };

  return (
    <>
      {isShowLoading && <Loading />}
      <Item>
        <File
          onChange={onChangeFile}
          ref={fileTag}
          accept="zip, application/octet-stream, application/zip, application/x-zip, application/x-zip-compressed"
        />
        <Title>
          <span>{t("goods.model")}</span>
          {state.modelFileId ? (
            <UnderButton
              style={{ pointerEvents: "all" }}
              onClick={onClickDownloadButtonTag}
            >
              {t("download")}
            </UnderButton>
          ) : (
            <></>
          )}
        </Title>
        {isAccept || (
          <Title warning>
            작가님의 승인이 완료된 후 작품 파일을 업로드 할 수 있습니다.
          </Title>
        )}
        <Value>
          <Flex>
            <ModelInput
              value={state.modelFileName}
              disabled={true}
              placeholder={t("goods.model")}
              onClick={onClick}
            />
            {!state.isDisp && (
              <ProjectFilesButtom onClick={onClick} disabled={!isAccept} fontSize={state.modelFileName ? 10 : 14}>
                  {state.modelFileName ? t("goods.retry") : t("goods.add")}
              </ProjectFilesButtom>
            )}
          </Flex>
        </Value>
        <Description>{t("goods.modelDescription")}</Description>
        <Progress onClick={onClickCancelButtonTag} />
      </Item>
    </>
  );
};
