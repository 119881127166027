import React from "react";
import { TextField } from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { Item, Input, Title, Value } from "../../style";
import { FundingAmountWrapper, FieldWrapper, LabelText } from "./styled";
import FundingSelector from "boards/DetailBoardWrite/recoil/selectors/funding";
import { useRecoilState } from "recoil";
import ACON from "lib/global";

export default () => {
  const { t } = useTranslation();
  const [funding, setFunding] = useRecoilState(FundingSelector);

  const handleGoalAmountChange = (amount: string) => {
    const parseAmount = amount.replace(/\,/g, "");
    setFunding({ ...funding, goalAmount: Number(parseAmount) });
  };

  const handleStartDateChange = (date: Date) => {
    setFunding({ ...funding, startDate: date });
  };

  const handleEndDateChange = (date: Date) => {
    setFunding({ ...funding, endDate: date });
  };

  const handleDeliveryDateChange = (date: Date) => {
    setFunding({ ...funding, deliveryDate: date });
  };

  return (
    <>
      <Item>
        <Title admin={true}>{t("funding.amount")}</Title>
        <Value>
          <FundingAmountWrapper>
            <Input
              disabled={false}
              placeholder={t("goods.fundingAmountPlaceholder")}
              value={ACON.AddCommas(funding.goalAmount)}
              onChange={(e) => {
                handleGoalAmountChange(e.target.value);
              }}
            />
            원
          </FundingAmountWrapper>
        </Value>
      </Item>
      <Item>
        <Title admin={true}>{t("funding.duration")}</Title>
        <FieldWrapper>
          <LabelText>시작일</LabelText>
          <DateTimePicker
            label="날짜 및 시간"
            value={funding.startDate}
            maxDate={funding.endDate || funding.deliveryDate}
            onChange={(date) => {
              handleStartDateChange(date);
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth size={"small"} />
            )}
          />
        </FieldWrapper>
        <FieldWrapper>
          <LabelText>종료일</LabelText>
          <DateTimePicker
            label="날짜 및 시간"
            value={funding.endDate}
            minDate={funding.startDate}
            maxDate={funding.deliveryDate}
            onChange={(date) => {
              handleEndDateChange(date);
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth size={"small"} />
            )}
          />
        </FieldWrapper>
      </Item>
      <Item>
        <Title admin={true}>{t("funding.deliveryDate")}</Title>
        <Value>
          <DateTimePicker
            label="날짜 및 시간"
            value={funding.deliveryDate}
            minDate={funding.endDate || funding.startDate}
            onChange={(date) => {
              handleDeliveryDateChange(date);
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth size={"small"} />
            )}
          />
        </Value>
      </Item>
    </>
  );
};
