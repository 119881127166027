import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../app';
import { Page, Wrap, TabContainer, Tab } from './style';
import CosmicDeal from './cosmicDeal';
import CometDeal from './cometDeal';

export default ((props) => {
    const { match } = props;
    // 배경색 변경하기 메소드 
    const { setBackgroundColor } = useContext(AppContext);
    // 현재 페이지 타입을 가져옵니다.
    const promotionDisplayLocation = match.params.displayLocation || "cosmic-deal";

    // 색상변경 
    useEffect(() => {
        // 배경 흰색으로 설정 
        setBackgroundColor('white')

        return () => {
            setBackgroundColor('')
        }
    }, [setBackgroundColor]);

    // 페이지 탭 이동
    const onClickTabSelector = (e) => {
        const selectorLocation = e.target.dataset.name;
        props.history.push(`/manager/promotion/${selectorLocation}`);
    }
    
    // 페이지 탭
    const PromotionTab = ({ children, pageType }) => {
        const isActive = promotionDisplayLocation === pageType;
        return (
            <Tab 
                isActive={isActive}
                onClick={onClickTabSelector}
                data-name={pageType}
            >
                {children}
            </Tab>
        );
    };
    
    return (
        <>
            <Page>
                <TabContainer>
                    <PromotionTab pageType={'cosmic-deal'}>우주최저가</PromotionTab>
                    <PromotionTab pageType={'comet-deal'}>혜성특가</PromotionTab>
                </TabContainer>
                <Wrap>
                    {
                        promotionDisplayLocation === 'cosmic-deal' &&
                        <CosmicDeal {...props} />
                    }
                    {
                        promotionDisplayLocation === 'comet-deal' &&
                        <CometDeal {...props} />
                    }
                </Wrap>
            </Page>
        </>
    );
});