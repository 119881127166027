import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Icon, RadioGroup, Radio } from 'rsuite';
import * as Styled from './styled';

// 메일발송 모달 
export default (({ isShow, onClose, sendMail }) => {
    const { lang } = useParams();

    const [checkedLang, setCheckedLang] = useState(lang);

    // 확인 버튼 클릭 이벤트 처리기 메소드 
    const onClickConfirmButton = () => {
        sendMail(checkedLang);
        onClose();
    };

    return (
        <Styled.SelectLanguageModal show={isShow} onHide={onClose} >
            <Styled.Header>
                <Styled.EmptyButton onClick={onClose}>
                    <Icon icon="close" />
                </Styled.EmptyButton>
            </Styled.Header>
            <Modal.Body>
                <Styled.Title>발송할 메일 언어를 선택하세요.</Styled.Title>
                <Styled.Contents>
                    <div style={{ display: `flex`, justifyContent: `space-between` }}>
                        <RadioGroup
                            inline
                            value={checkedLang}
                            onChange={lang => {
                                setCheckedLang(lang);
                            }}
                        >
                            <Radio value="ko">한국어</Radio>
                            <Radio value="en">영어</Radio>
                        </RadioGroup>
                    </div>
                    <Styled.ConfirmButton onClick={onClickConfirmButton}>
                        메일 발송
                    </Styled.ConfirmButton>
                </Styled.Contents>
            </Modal.Body>
        </Styled.SelectLanguageModal>
    )
})