import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Editor from 'common/editor';
import { Field, Title, Description, Value } from '../../style';
import parse from 'html-react-parser';
import { AppContext } from 'app';
import { HtmlView } from './style';
import { useRecoilState } from 'recoil';
import { contentsSelector, displaySelector } from 'boards/DetailBoardWrite/recoil/selectors';

export default (() => {
    // 번역도구 
    const { t, ready } = useTranslation();
    const { userInfo } = useContext(AppContext)
    const [display] = useRecoilState(displaySelector);
    const [contents, setContents] = useRecoilState(contentsSelector);

    if (!ready) return <div></div>;

    return (
        <Field>
            <Title>{t("EditorContents")}</Title>
            {!display.isDisp && <Description>
                {t("editorContentsDiscription")}
                <br />
                {parse(t("editorContentsMainDeiscription"))}
                &nbsp;&nbsp;
                <b>
                    <a
                        href={t('projectUploadGuideLink')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >{t("goToContentsGuide")}</a>
                </b>
            </Description>}
            <Value>
                {!display.isDisp && <Editor
                    key={display.lang}
                    isAdmin={userInfo.isAdmin}
                    isDisp={display.isDisp}
                    docId={''}
                    contents={contents ? contents : t('basicDetailBody')}
                    onChange={(value) => {
                        setContents(value);
                    }}
                    showAlertMessage={() => { }}
                />}
                {display.isDisp && <HtmlView>
                    {parse(contents)}
                </HtmlView>}
            </Value>
        </Field>
    );
});