import React from 'react';
import styled from 'styled-components'

export const Ul = styled.ul`
  font-size: 14px;
`;

export const Li = styled.li``;

export const DisplayCheckbox = styled.span`
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-left: 8px;
  background: linear - gradient(180deg, rgba(255, 255, 255, 0.04) 3.12 %, rgba(97, 121, 133, 0.04) 100 %), #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
  border-radius: 50%;
`;

export const Item = (({ text, onChange, name, isChecked, defaultValue }) => {
  let Div = styled.div`
    width: 100 %;
    float: left;
    padding-top: 10px;
  `;

  return (
    <Div>
      <div>{text}</div>
      <Radio
        name={name}
        onChange={onChange}
        checked={isChecked}
        value={defaultValue}
      />
      <DisplayCheckbox />
    </Div>
  );
});

export const Question = styled.div`
  width: 100%;
  float: left;
  font-size: 14px;
  font-weight: 700;
  padding-top: 10px;

  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
    background-color: white;
    border: 1px solid black;
  }
  .rs-radio-wrapper::before {
    border: 1px solid black;
  }
  .rs-radio-wrapper .rs-radio-inner::after {
    width: 8px;
    height: 8px;
    background: black;
    margin-top: 4px;
    margin-left: 4px;
  }
`;

export const CheckItem = styled.label`

`;

export const Radio = styled.input.attrs(props => ({
  type: 'radio'
}))`
  display: none;
`;

export const box = styled.div`
  height: auto;
  padding: 10px 20px 10px 20px;
  border: 2px solid #000;
  margin-top: 5px;
  float: left;

  & __agree {
    margin - top: 15px;
    display: flex;

    input[type = "checkbox"] {
      margin - right: 5px;
    }
  }
  ul {
    padding - left: 30px;
    &.head {
      padding - left: 0;
    }
    li {
      width: 100 %;
      float: left;
    }
  }
  label {
    font - weight: normal;
  }
}
.copyright__group__question {
  width: 100 %;
  float: left;
  padding - top: 10px;
}
.copyright__group__answer__item {
  width: 120px;
  float: left;
  display: block;
  position: relative;
  padding - left: 33px;
  font - size: 16px;
  margin - bottom: 12px;
  cursor: pointer;
  font - weight: normal;
  -webkit - user - select: none;
  -moz - user - select: none;
  -ms - user - select: none;
  user - select: none;
}

.copyright__group__answer__item div {
  margin - top: -2px;
}
.copyright__group__answer__item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.copyright__group__answer__item__checkbox {
 
}

.copyright__group__answer__item: hover input ~ .copyright__group__answer__item__checkbox {
  background - color: #ccc;
}

.copyright__group__answer__item input: checked ~ .copyright__group__answer__item__checkbox {
  background - color: inherit;
}

.copyright__group__answer__item__checkbox: after {
  content: "";
  position: absolute;
  display: none;
}

.copyright__group__answer__item input: checked ~ .copyright__group__answer__item__checkbox: after {
  display: block;
}

.copyright__group__answer__item.copyright__group__answer__item__checkbox: after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border - radius: 50 %;
  background: $color - vivid;
}
`;
export const Div = styled.div`
  margin-top: 21px;
  margin-left: -9px;
`;
export const to = styled.div`
  width: 100 %;
  float: left;
  margin-bottom: 10px;
`;
export const Flex = styled.div`
  display: flex; 
  justify-content: space-between;
`;
export const from = styled.div`
  width: 100 %;
  float: left;
  margin-left: 5px;
  margin-bottom: 10px;
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${(props: { isDisp?: boolean }) => props.isDisp ? `pointer-events: none;` : ``}
`;

export const CheckboxText = styled.div`
  font-size: 14px;
  width: calc(100% - 30px);
  line-height: 100%;
  margin-top: 8px;
`;

export const RequireText = styled.div`
  display: flex;
  align-items: center;
  line-height: 100%;
  color: #808080;
  font-size: 12px;
  padding: 1px 0px;
`;