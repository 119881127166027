import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { AppContext } from '../../../app';
import { GET_BRANDS } from '../../../api/quries';
import Item from './item';
import { STATUS_APPROVED, STATUS_COMPLETE_ON_OPEN, STATUS_DRAFT, STATUS_INPROGRESS, STATUS_IN_REVIEW_ON_OPEN, STATUS_OPEN, STATUS_REJECT, STATUS_REQUEST, STATUS_REQUEST_ON_OPEN } from 'boards/DetailBoardWrite/constants';
import Search from '../search';
import { Container, DateRangePicker, PageNavigation, SelectBox } from 'acon-mui/components';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import palette from 'theme/palette';
import { isMobile } from 'react-device-detect';
import { color } from 'acon-mui/style';
import dayjs from 'dayjs';
import { HubProductStatus, New_Product_Status, useGetDisplayProductsQuery } from 'generated/graphql';

const viewCountList = [
    { id: 1, label: '20' },
    { id: 2, label: '50' },
    { id: 3, label: '100' },
    { id: 4, label: '200' }
];

interface Props {
    createStartDate?, 
    createEndDate?, 
    requestStartDate?, 
    requestEndDate?, 
    title, 
    contents, 
    godoGoodsNo?, 
    limit, 
    page, 
    match, 
    history, 
    onChangeCheckbox, 
    selectedItemObjArr, 
    setSelectedItemObjArr    
}
// 키워드, 유형, 페이지, 브랜드 ID들에 대해서는 URL에서 확보함 
export default (({ 
    createStartDate, 
    createEndDate, 
    requestStartDate, 
    requestEndDate, 
    title, 
    contents, 
    godoGoodsNo, 
    limit, 
    page, 
    match, 
    history, 
    onChangeCheckbox, 
    selectedItemObjArr, 
    setSelectedItemObjArr 
}: Props) => {
    // 번역도구 
    const { t, i18n } = useTranslation();
    const {
        // 모달창 표시하기 메소드
        showConfirmMessage,
        // 사용자 정보
        userInfo
    } = useContext(AppContext);

    // url 객체 
    const urlParamObj: {
        limit?: number;
        page?: string;
        requestStartDate?: string;
        requestEndDate?: string;
        createStartDate?: string;
        createEndDate?: string;
    } = queryString.parse(history.location.search);

    // 관리자 여부 
    const isAdmin = userInfo && userInfo.isAdmin;

    // 검토요청일 필터 표시여부 
    const [isShowRequestDateFilter, setIsShowRequestDateFilter] = useState(false);
    // 작성일 필터 표시여부
    const [isShowCreateDateFilter, setIsShowCreateDateFilter] = useState(false);

    // 브랜드 쿠키; 
    const [cookie, setCookie] = useCookies(['hubBrandCookie', 'hubStatusCookie']);

    const [isCheckedAllCheck, setIsCheckedAllCheck] = useState(false);

    // 선택된 브랜드 ID들 
    const [selectedBrandIds, setSelectedBrandIds] = useState(cookie['hubBrandCookie'] || []);
    // 선택된 상태들
    const [selectedStatus, setSelectedStatus] = useState(cookie['hubStatusCookie'] || []);

    // 브랜드 가져오기 
    const getBrandItemsObj = useQuery(GET_BRANDS, {
        variables: {
            lang: i18n.language
        }
    });
    // 제품정보 확보 
    const { loading, error, data, refetch } = useGetDisplayProductsQuery({
        fetchPolicy: "no-cache",
        variables: {
            page: page ? page : 1,
            limit,
            createStartDate,
            createEndDate,
            requestStartDate,
            requestEndDate,
            title,
            contents,
            godoGoodsNo,
            brandIds: selectedBrandIds.length > 0 ? selectedBrandIds : undefined,
            // '수정 검토 중'일 경우에는 status를 그대로 호출하면 안 됨
            status: selectedStatus.length > 0 ? selectedStatus : undefined,
        }
    });
    
    // 검토요청일 필터 설정하기 메소드 
    const setRequestDateFilter = (rangeDate, type) => {
        const urlParamObj = queryString.parse(history.location.search);
        // 페이지 1페이지로 변경 
        urlParamObj.page = '1';
        // 기간이 지정된 경우 
        if (type === 'start') {
            urlParamObj.requestStartDate = dayjs(rangeDate).format("YYYY-MM-DD");
        }
        if (type === 'end') {
            urlParamObj.requestEndDate = dayjs(rangeDate).format("YYYY-MM-DD");
        }
        // 선택 항목 초기화
        setIsCheckedAllCheck(false);
        setSelectedItemObjArr([]);
        // 요청일 필터 숨김 
        setIsShowRequestDateFilter(false);
        // 페이지 이동 
        history.push({
            pathname: '/',
            search: queryString.stringify(urlParamObj)
        });
    };
    // 작성일 필터 설정하기 메소드 
    const setCreateDateFilter = (rangeDate, type) => {
        const urlParamObj = queryString.parse(history.location.search);
        // 기간이 지정된 경우 
        if (type === 'start') {
            urlParamObj.createStartDate = dayjs(rangeDate).format("YYYY-MM-DD");
        }
        if (type === 'end') {
            urlParamObj.createEndDate = dayjs(rangeDate).format("YYYY-MM-DD");
        }
        // 페이지 1페이지로 변경 
        urlParamObj.page = '1';

        // 선택 항목 초기화
        setIsCheckedAllCheck(false);
        setSelectedItemObjArr([]);
        // 작성일 필터 숨김 
        setIsShowCreateDateFilter(false);
        history.push({
            pathname: '/',
            search: queryString.stringify(urlParamObj)
        });
    };
    // 상태 id 설정하기 메소드 
    const setStatusFilter = (status) => {
        // 선택 항목 초기화
        setIsCheckedAllCheck(false);
        setSelectedItemObjArr([]);
        // 상태 쿠키 설정 
        setCookie('hubStatusCookie', status);
        // 제거된 배열 삽입 
        setSelectedStatus(status);
    };
    // 브랜드 필터 id 설정하기 메소드 
    const setBrandFilterId = (value) => {
        const brands = value.map(x => x.id);
        // 선택 항목 초기화
        setIsCheckedAllCheck(false);
        setSelectedItemObjArr([]);
        // 브랜드 쿠키 설정 
        setCookie('hubBrandCookie', brands);
        // 제거된 배열 삽입 
        setSelectedBrandIds(brands);
    };
    // 리뷰 상태 가져오기 메소드 
    const getReviewStatusText = (reviewStatus) => {
        switch (reviewStatus) {
            case STATUS_DRAFT:
                return t('reviewStatus.none')
            case STATUS_REQUEST:
                return t('reviewStatus.request')
            case STATUS_REQUEST_ON_OPEN:
                return t('reviewStatus.editRequest')
            case STATUS_INPROGRESS:
                return t('reviewStatus.review')
            case STATUS_IN_REVIEW_ON_OPEN:
                return t('reviewStatus.editReview')
            case STATUS_OPEN:
                return t('reviewStatus.open')
            case STATUS_REJECT:
                return t('reviewStatus.reject')
            case STATUS_APPROVED:
                return t('reviewStatus.complete')
            case STATUS_COMPLETE_ON_OPEN:
                return t('reviewStatus.completeOnOpen')
            default:
                return t('reviewStatus.empty')
        }
    };
    // 새로고침 클릭 이벤트 처리기 메소드
    const onClickReloadButton = (e) => {
        try {
            // 삭제여부 모달창 표시 
            showConfirmMessage(
                t("initFilter"),
                t("toInitFilter"),
                () => {
                    // 상태 쿠키 설정 
                    setCookie('hubStatusCookie', '');
                    // 브랜드 쿠키 설정 
                    setCookie('hubBrandCookie', '');

                    // 선택 항목 초기화
                    setIsCheckedAllCheck(false);
                    setSelectedItemObjArr([]);
                    setSelectedBrandIds([]);
                    setSelectedStatus([]);
                    // 루트로 이동하기 
                    history.push({
                        pathname: '/',
                        search: ''
                    });
                }
            )
        }
        catch (errObj) {
            // 실패 메세지 조립 
            errObj.message = `상품 삭제버튼 이벤트 실패(${errObj.message})`;
            // 로그 기록(TODO)
            console.log(errObj);
            // 사용자 안내 메세지 표시
            alert('관리자에게 문의해주세요.');
        }
    };

    // 항목 보기 개수 select 태그 변경 이벤트 처리기 메소드
    const onChangeViewCountSelectTag = (e) => {
        // 검색 쿼리
        const query = queryString.stringify({
            ...urlParamObj,
            limit: viewCountList.find(x => x.id === e.target.value).label,
        });
        // 검색페이지로 이동
        history.push({
        pathname: history.location.pathname,
        search: query,
        });
    };

    const statusFilter = (
        <Box {...(!isMobile && !isAdmin) && { width: '100%' }} {...isMobile && { mt: 3 }}>
            <Typography 
                color={color.text.secondary} 
                fontWeight="600" 
                fontSize="14px" 
                lineHeight="22px" 
                mb={1}
            >
                {t('detailBoard.status')}
            </Typography>
            <SelectBox
                defaultValues={selectedStatus.map(x => {
                    switch (x) {
                        case New_Product_Status.Draft: return 1;
                        case New_Product_Status.Requested: return 2;
                        case New_Product_Status.RequestedOnOpen: return 3;
                        case HubProductStatus.InReview: return 4;
                        case New_Product_Status.InReviewOnOpen: return 5;
                        case New_Product_Status.Rejected: return 6;
                        case New_Product_Status.Complete: return 7;
                        case New_Product_Status.CompleteOnOpen: return 8;
                    }
                })}
                options={[
                    { id: 1, value: New_Product_Status.Draft, label: getReviewStatusText(STATUS_DRAFT) },
                    { id: 2, value: New_Product_Status.Requested, label: getReviewStatusText(STATUS_REQUEST) },
                    { id: 3, value: New_Product_Status.RequestedOnOpen, label: getReviewStatusText(STATUS_REQUEST_ON_OPEN) },
                    { id: 4, value: HubProductStatus.InReview, label: getReviewStatusText(STATUS_INPROGRESS) },
                    { id: 5, value: New_Product_Status.InReviewOnOpen, label: getReviewStatusText(STATUS_IN_REVIEW_ON_OPEN) },
                    { id: 6, value: New_Product_Status.Rejected, label: getReviewStatusText(STATUS_REJECT) },
                    { id: 7, value: New_Product_Status.Complete, label: getReviewStatusText(STATUS_APPROVED) },
                    { id: 8, value: New_Product_Status.CompleteOnOpen, label: getReviewStatusText(STATUS_COMPLETE_ON_OPEN) }
                ]}
                placeholder={t('detailBoard.allStatus')}
                inputLabelVisible={false}
                onSelect={(e, val) => { setStatusFilter(val.map(x => x.value)); }}
                isMultiple
                width={(isAdmin && !isMobile) ? '328px' : "100%"}
                {...{
                    '& .MuiOutlinedInput-notchedOutline': {
                        marginTop: '5px',
                        height: isMobile ? '40px' : '56px'
                    }
                }}
            />
        </Box>
    );

    const render = () => {
        try {
            // 현재 페이지가 1페이지 이상인데, 데이터가 존재하지 않은 경우, 1페이지로 돌아감 
            if (Number(urlParamObj.page) > 1 && !loading && !error && data?.getDisplayProducts && data.getDisplayProducts.length === 0) {
                // url 파라메터 객체 
                const urlParamObj = queryString.parse(history.location.search);
                // 페이지 1로 전환 
                urlParamObj.page = '1';
                // 페이지 이동 
                history.push({
                    pathname: '/',
                    search: queryString.stringify(urlParamObj)
                });
            }

            return (
                <Container padding="0">
                    <Search history={history} {...isMobile && { statusFilter }} onClickResetButton={onClickReloadButton} />
                    {!isMobile && (
                        <Box px={2} display="flex" flexDirection={isAdmin ? 'column' : 'row'}>
                            <Box mt={3} display="inline-flex">
                                <Box pr={2} borderRight="1px solid rgba(145, 158, 171, 0.24)">
                                    <Typography 
                                        color={color.text.secondary} 
                                        fontWeight="600" 
                                        fontSize="14px" 
                                        lineHeight="22px" 
                                        mb={1}
                                    >
                                        {t('detailBoard.requestDate')}
                                    </Typography>
                                    <DateRangePicker 
                                        startDate={urlParamObj.requestStartDate ? new Date(urlParamObj.requestStartDate) : null}
                                        endDate={urlParamObj.requestEndDate ? new Date(urlParamObj.requestEndDate) : null}
                                        setStartDate={(val) => {
                                            setRequestDateFilter(val, 'start');
                                        }}
                                        setEndDate={(val) => {
                                            setRequestDateFilter(val, 'end');
                                        }}
                                    />
                                </Box>
                                <Box px={2} mr={2} borderRight={isAdmin ? "none" : "1px solid rgba(145, 158, 171, 0.24)"}>
                                    <Typography 
                                        color={color.text.secondary} 
                                        fontWeight="600" 
                                        fontSize="14px" 
                                        lineHeight="22px" 
                                        mb={1}
                                    >
                                        {t('detailBoard.writeDate')}
                                    </Typography>
                                    <DateRangePicker 
                                        startDate={urlParamObj.createStartDate ? new Date(urlParamObj.createStartDate) : null}
                                        endDate={urlParamObj.createEndDate ? new Date(urlParamObj.createEndDate) : null}
                                        setStartDate={(val) => {
                                            setCreateDateFilter(val, 'start');
                                        }}
                                        setEndDate={(val) => {
                                            setCreateDateFilter(val, 'end');
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box mt={3} display="flex" {...(!isMobile && !isAdmin) && { flex: 'auto' }}>
                                {statusFilter}
                                {(isAdmin && !getBrandItemsObj.loading && getBrandItemsObj.data) && (
                                    <Box ml={2} px={2} borderLeft="1px solid rgba(145, 158, 171, 0.24)">
                                        <Typography 
                                            color={color.text.secondary} 
                                            fontWeight="600" 
                                            fontSize="14px" 
                                            lineHeight="22px" 
                                            mb={1}
                                        >
                                            {t('detailBoard.brand')}
                                        </Typography>
                                        <SelectBox
                                            defaultValues={selectedBrandIds}
                                            options={getBrandItemsObj.data.getBrands.map(x => ({ id: x.id, label: x.name })) }
                                            placeholder={t('detailBoard.allBrands')}
                                            inputLabelVisible={false}
                                            onSelect={(e, val) => { setBrandFilterId(val); }}
                                            isMultiple
                                            isKeyboardAvailable
                                            width="328px"
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                    <Box sx={{ overflowX: "scroll", '&::-webkit-scrollbar': { display: 'none' } }}>
                        <Table 
                            sx={{ 
                                mt: 3, 
                                width: '1060px', 
                                '& tr': { 
                                    transition: '0.5s',
                                    borderBottom: '1px solid rgba(145, 158, 171, 0.24)' 
                                },
                                '& tr:hover': { background: palette.light.grey[100] } 
                            }}>
                        {loading &&
                            // 스켈레톤 UI 구현 위치
                            <>
                                <TableHead sx={{height: '56.5px', '& th': { p: '16px !important', boxShadow: 'none !important', borderRadius: '0 !important' } }}>
                                    <TableRow>
                                        <TableCell variant='head'/>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ '& td': { p: '16px !important', textAlign: 'left' } }}>
                                    <TableRow>
                                        <TableCell sx={{ height: `${3 * 169}px` }} />
                                    </TableRow>
                                </TableBody>
                            </>
                        }
                        {!loading &&
                            (<>
                                <TableHead sx={{'& th': { p: '16px !important', fontSize: '13px !important', lineHeight: '24px', boxShadow: 'none !important', borderRadius: '0 !important' } }}>
                                    <TableRow>
                                        {/* {isAdmin ? adminThs : userThs} */}
                                        <TableCell variant='head'/>
                                        <TableCell variant='head'>
                                            {t('Home.Detail')}
                                        </TableCell>
                                        <TableCell variant='head'>
                                            {t('detailBoard.requestDate')}
                                        </TableCell>
                                        <TableCell variant='head'>
                                            {t('detailBoard.writeDate')}
                                        </TableCell>
                                        {isAdmin && (
                                            <TableCell variant='head'>
                                                {t('detailBoard.brand')}
                                            </TableCell>
                                        )}
                                        <TableCell variant='head' sx={{ textAlign: 'center' }}>
                                            {t('detailBoard.status')}
                                        </TableCell>
                                        <TableCell variant='head'/>
                                        <TableCell variant='head'/>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ '& td': { p: '16px !important', textAlign: 'left' } }}>
                                    {!loading && !error && data?.getDisplayProducts &&
                                        data.getDisplayProducts.length !== 0 &&
                                        data.getDisplayProducts.map(x => {
                                            let itemObj = { ...x };
                                            const isChecked = selectedItemObjArr.filter(x => x.id === itemObj.id).length > 0;
                                            const itemTitles = (isAdmin ? ['ko', 'en', 'cn', 'jp'] : [x.originLanguage]).map(y => {
                                                const item = itemObj.titles.find(z => z.lang.toUpperCase() === y.toUpperCase());
                                                const title = item && item.title ? item.title : '-';
                                                return { title: title, lang: y.toLowerCase(), isLink: item && item.title ? true : false }
                                            });
                                            itemObj.titles = itemTitles;
                                            // 항목 프로퍼티 조립 후, 전달 
                                            const itemProps = {
                                                ...itemObj, isAdmin, onChangeCheckbox, isChecked, refetch
                                            };

                                            return (<Item key={x.id} {...itemProps} titles={itemTitles} />);
                                        })
                                    }
                                </TableBody>
                            </>)
                        }
                        </Table>
                    </Box>
                    <Box display="flex" alignItems="center" px="24px">
                        {!isMobile && (
                            <Typography ml="auto" mr="20px">
                                {t('settle.perPage')}:
                            </Typography>
                        )}
                        <SelectBox
                            defaultValue={1}
                            options={viewCountList}
                            onSelect={onChangeViewCountSelectTag}
                            isBorder={false}
                            width="80px"
                            padding="0"
                            marginRight="16px"
                            {...isMobile && { marginLeft: 'auto' }}
                        /> 
                        {!loading && data && data.getDisplayProductsTotal !== undefined && (
                        <PageNavigation
                            totalCnt={data.getDisplayProductsTotal}
                            limit={urlParamObj.limit || 20}
                            pathName={"/"}
                            history={history}
                            buttonVisible
                        />
                        )}
                    </Box>
                </Container>
            )
        }
        catch (errObj) {
            // 실패 메세지
            const failMessage = `상품 리스트 렌더링하는 도중, 예상치 못한 예외 발생(${errObj.message})`;
            // 실패 메세지 기록(TODO)
            console.log(failMessage);
        }
    };

    return render();
});