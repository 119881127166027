import { selector } from "recoil";
import { commissionState } from "..";

const commissionSelector = selector<number|string>({
  key: 'commissionSelector',
  get:({get}) => get(commissionState),
  set: ({set}, val) => set(commissionState, val),
});

export default commissionSelector;
