import { selector } from "recoil";
import { descriptionState } from "..";

const descriptionSelector = selector<string>({
  key: 'descriptionSelector',
  get:({get}) => get(descriptionState),
  set: ({set}, val) => set(descriptionState, val),
});

export default descriptionSelector;
