import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './ImageDialog.scss';

// 요청하기 위한 모달입니다.
export default function (props) {
    const { close, isShow, uploadImages, insertImage } = props;
    const [url, setUrl] = useState('');
    const { t } = useTranslation();

    const onFileChange = (e) => {
        const fileList = e.target.files;

        if (!fileList || !fileList.length) {
            return;
        }

        uploadImages(fileList);
    }

    const onClickConfirmButton = (e) => {
        const result = insertImage(url);

        if (result) {
            close();
        }
    }

    return (
        <Modal className="requestDialog" show={isShow} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('imageDialog.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="requestDialog__description">
                    {t('imageDialog.file')}
                </div>
                <input type="file" onChange={onFileChange} multiple accept="image/*"/>
                {props.showUrlInput && (
                    <>
                        <div className="requestDialog__description">
                            {t('imageDialog.url')}
                        </div>
                        <input
                            type="text"
                            onChange={(e) => setUrl(e.target.value)}
                            placeholder={t('imageDialogDescription')}
                        />
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button className="requestBtn" onClick={onClickConfirmButton}>
                    {t('imageDialog.button')}
                </button>
            </Modal.Footer>
        </Modal>
    )
};