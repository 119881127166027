import { DefaultValue, selector } from "recoil";
import { mainImageState } from "..";
import { MainImageType } from "../types";

const mainImageSelector = selector<MainImageType>({
  key: 'mainImageSelector',
  get:({get}) => get(mainImageState),
  set: ({get, set}, val) => {
    if (!(val instanceof DefaultValue)) {
      const { mainImage, thumbnail } = val;
      const main = get(mainImageState);
      return set(mainImageState, {
        mainImage: {
          ...main.mainImage,
          ...mainImage,
          ...(thumbnail && thumbnail?.imageName && thumbnail?.imagePath && {
            imageName: thumbnail.imageName,
            imagePath: thumbnail.imagePath
          })
        },
        thumbnail: {
          ...main.thumbnail,
          ...thumbnail,
          ...(mainImage && mainImage?.imageName && mainImage?.imagePath && {
            imageName: mainImage.imageName,
            imagePath: mainImage.imagePath
          })
        }
      });
    }
  },
});

export default mainImageSelector;
