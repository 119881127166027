import { BlackButton } from '../../component/form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Input, Item, Title, Value, Description } from '../../style';
import List from './list'
import { useRecoilState } from 'recoil';
import { displaySelector, fileConfigSelector } from 'boards/DetailBoardWrite/recoil/selectors';

export default (() => {
    // 번역도구 
    const { t } = useTranslation();
    const [display] = useRecoilState(displaySelector);
    const [fileConfig, setFileConfig] = useRecoilState(fileConfigSelector);

    // 파일 명 
    const [name, setName] = useState('');
    // 파일 크기 
    const [size, setSize] = useState('');

    // 숫자만 가져오기 메소드
    const parseNumber = (n) => {
        let value = parseFloat(n);

        if (Number.isNaN(value)) return '';

        return Number(value.toFixed(2));
    };
    // 파일 크기 변경 이벤트 처리기 메소드
    const onChangeFileSizeInputTag = (e) => {
        let { value } = e.target;
        setSize(value);
    };
    // blur 이벤트
    const onBlur = (e) => {
        let { value } = e.target;
        value = parseNumber(value);
        setSize(value);
    };
    // 추가 버튼 클릭 이벤트 처리기 메소드 
    const onClick = () => {
        // 입력할 데이터 객체 
        const dataObj = { id: -1, name, size };
        // 데이터 배열 
        const dataArr = fileConfig.concat(dataObj);

        // 파일명 초기화 
        setName('');
        // 파일 크기 초기화
        setSize('');

        setFileConfig(dataArr);
    };

    // 저장 버튼 태그 클릭 이벤트 처리기 메소드 
    const setItem = (itemObj, name, size) => {
        itemObj.name = name;
        itemObj.size = size;

        setFileConfig(fileConfig);
    };
    // 삭제 버튼 클릭 이벤트 처리기 메소드
    const onClickDeleteButtonTag = itemObj => {
        itemObj.id = 0;

        setFileConfig(fileConfig.filter(x => x.id !== 0));
    };
    // 여기부터 시작하면 됌 
    return (
        <Item>
            <Title>{t("goods.fileConfig")}</Title>
            {!display.isDisp &&
                <>
                    <Value>
                        <Flex>
                            <Input
                                value={name}
                                onChange={(e) => { setName(e.target.value); }}
                                placeholder={t("goods.fileName")}
                                disabled={display.isDisp}
                            />
                            <Input
                                value={size}
                                onChange={onChangeFileSizeInputTag}
                                onBlur={onBlur}
                                placeholder={t("goods.fileSize")}
                                maxLength={8}
                                disabled={display.isDisp}
                            />
                            <BlackButton disabled={!name || !size} onClick={onClick}>{t('goods.add')}</BlackButton>
                        </Flex>
                    </Value>
                    <Description>{t("goods.fileConfigDescription")}</Description>
                </>
            }
            <List setItem={setItem} items={fileConfig} onClick={onClickDeleteButtonTag} />
        </Item>
    );
});
