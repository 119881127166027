import { ProductStatus } from "generated/graphql";

// 상태 - 임시저장됨
export const STATUS_DRAFT = ProductStatus.Draft;
// 상태 - 요청됨
export const STATUS_REQUEST = ProductStatus.Request;
// 상태 - 진행중
export const STATUS_INPROGRESS = ProductStatus.Inprogress;
// 상태 - 반려됨
export const STATUS_REJECT = ProductStatus.Reject;
// 상태 - 검토완료됨
export const STATUS_APPROVED = ProductStatus.Complete;
// 상태 - 오픈
export const STATUS_OPEN = ProductStatus.Open;
// 상태 - 수정요청
export const STATUS_REQUEST_ON_OPEN = 'REQUESTED_ON_OPEN';
// 상태 - 수정검토중
export const STATUS_IN_REVIEW_ON_OPEN = 'IN_REVIEW_ON_OPEN';
// 상태 - 수정완료
export const STATUS_COMPLETE_ON_OPEN = 'COMPLETE_ON_OPEN';

export const GET_GOODS_VIEW_PREVIEW_PAGE_URL = (godoGoodsNo, lang) => {
    return `https://www.acon3d.com/goods/goods_view.php?goodsNo=${godoGoodsNo}&review=true&lang=${lang}`;
};

export enum TranslateModalType {
    CONTENT='content',
    FILE='file'
};
