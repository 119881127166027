import { selector } from "recoil";
import { languagesState } from "..";

const languagesSelector = selector<string[]>({
  key: 'languagesSelector',
  get:({get}) => get(languagesState),
  set: ({set}, val) => set(languagesState, val),
});

export default languagesSelector;
