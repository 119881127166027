export const TableHeadStyle = (isEditable: boolean) => ({ 
  padding: `0px 0px ${isEditable ? "1px": "8px"} 0px !important`, 
  background: '#fff', 
  color: '#333',
  fontSize: '14px !important',
  lineHeight: '100%',
  fontWeight: '500'
});

export const TableCellStyle = (isEditable: boolean) => ({
  padding: `${isEditable ? "18px": "8px"} 0px 0px 0px !important`, 
  color: '#333',
  fontSize: '14px !important',
  lineHeight: '100%',
  fontWeight: '400',
  '& a': {
    color: '#333',
  },
  '& a:visited': {
    color: '#333',
  },
});

export const PackageSetButtonStyle = {
  border: '1px solid #7c7c7c',
  borderRadius: '4px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  color: '#333',
  fontSize: '14px',
  lineHeight: '100%',
  width: '100%',
  height: '40px',

  '&:hover': {
    background: '#fff',
    border: '1px solid #7c7c7c',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    color: '#333',
  },
  '&:active': {
    background: '#fff',
    border: '1px solid #7c7c7c',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    color: '#333',
  },
  '&:focus': {
    background: '#fff',
    border: '1px solid #7c7c7c',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    color: '#333',
  },
  '&:disabled': {
    border: '1px solid rgba(145, 158, 171, 0.5)',
  }
};

export const DropdownButtonStyle = {
  padding: '13px 16px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  color: '#000',
  background: 'transparent',
  border: 'none',
  borderRadius: '0',

  '&:hover': {
    background: 'transparent',
  },
  '&:active': {
    background: 'transparent',
  },
  '&:focus': {
    background: 'transparent',
  }
}

export const CheckButtonStyle = (isChecked: boolean) => ({
  padding: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '16px',
  height: '16px',
  borderRadius: '2px',
  ...isChecked ? ({
      background: '#F300BA',
    }) : ({
      background: "#fff",
      border: '1px solid #cdcdcd'
    })
});
