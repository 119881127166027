import { selector } from "recoil";
import { relatedState } from "..";
import { RelatedType } from "../types";

const relatedSelector = selector<RelatedType[]>({
  key: 'relatedSelector',
  get:({get}) => get(relatedState),
  set: ({set}, val) => set(relatedState, val),
});

export default relatedSelector;
