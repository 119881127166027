import { selector } from "recoil";
import { fundingState } from "..";
import { FundingType } from "../types";

const FundingSelector = selector<FundingType>({
  key: 'fundingSelector',
  get:({get}) => get(fundingState),
  set: ({set}, val) => set(fundingState, val),
});

export default FundingSelector;
