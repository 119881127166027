import React from 'react';
import { STATUS_APPROVED, STATUS_DRAFT, STATUS_INPROGRESS, STATUS_OPEN, STATUS_REJECT, STATUS_REQUEST } from 'boards/DetailBoardWrite/constants';
import { useTranslation } from 'react-i18next';
import { Progress, Column } from './style';

export default (({ isEdit, status }) => {
    const { t } = useTranslation();
    const process = [
        t("reviewStatus.empty"),
        t("reviewStatus.request"),
        t("UnderReview.label"),
        status === STATUS_REJECT ? t('reviewStatus.reject') : t('reviewStatus.complete')
    ];

    const editProcess = [
        t('reviewStatus.editing'),
        t('reviewStatus.editRequest'),
        t('reviewStatus.editReview'),
        status === STATUS_REJECT ? t('reviewStatus.reject') : t('reviewStatus.complete')
    ];
    const progressLevel = (() => {
        switch (status) {
            case STATUS_DRAFT:
                return 0;
            case STATUS_REQUEST:
                return 1;
            case STATUS_INPROGRESS:
                return 2;
            case STATUS_REJECT:
            case STATUS_APPROVED:
            case STATUS_OPEN:
                return 3;
        }
    })();

    return (
        <Progress status={progressLevel}>
            {(isEdit ? editProcess : process).map((name, idx) => {
                let type = false;

                if (idx === progressLevel) {
                    type = 'active';
                } else if (idx < progressLevel) {
                    type = 'done';
                }
                if (progressLevel === process.length - 1 && idx === process.length - 1) {
                    type = status === STATUS_REJECT ? 'warn' : 'done';
                }

                return <Column key={name} className={type}>{t(name)}</Column>
            })}

        </Progress>
    );
});