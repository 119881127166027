import { BlackButton, WhiteButton } from '../../component/form';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Input, Item, Title, Value } from '../../style';
import List from './list';
import Dialog from './Dialog';
import { useRecoilState } from 'recoil';
import { productAllInfoSelector } from 'boards/DetailBoardWrite/recoil/selectors';

export default (() => {
    const [ isDialogShow, setIsDialogShow ] = useState(false);
    const handleDialogShow = () => {
        const now = new Date().toISOString().slice(0, 10);
        const hasUpdateHistory = productData.updateHistory.some(
            (history) => typeof history.date === 'string' && new Date(history.date).toISOString().slice(0, 10) === now
        );

        if (!hasUpdateHistory) {
            alert('발송할 업데이트 내역이 없습니다.\r\n*발송 당일에 작성된 내역만 메일에 포함됩니다.')
            return false;
        }

        setIsDialogShow(true);
    }
    const handleDialogClose = () => setIsDialogShow(false);
    // 번역도구 
    const { t } = useTranslation();
    const [productData, setProductData] = useRecoilState(productAllInfoSelector);
    // 업데이트 내역 
    const [name, setName] = useState('');

    // 항목 삭제 버튼 클릭 이벤트 처리기 메소드 
    const onClickDeleteButtonTag = itemObj => {
        setProductData({ 
            updateHistory: productData.updateHistory.filter(x => {
                return x.id !== itemObj.id;
            })
        });
    };
    // 추가 버튼 클릭 이벤트 처리기 메소드 
    const onClick = () => {
        // 입력할 데이터 객체  (TODO : 서버 시간으로 조립)
        const dataObj = {
            id: (() => {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
                    return v.toString(16);
                });
            })(),
            name,
            date: new Date()
        };

        let dataArr = [...productData.updateHistory];
        dataArr.push(dataObj);

        // 업데이트 내역 초기화 
        setName('');
        setProductData({ updateHistory: dataArr });
    };

    // 저장 버튼 태그 클릭 이벤트 처리기 메소드 
    const setItem = (itemObj, name, size) => {
        setProductData({ 
            updateHistory: productData.updateHistory.map(x => {
                if (itemObj.id === x.id) return { ...x, name };
                return x;
            })
        });
    };

    const layout = useMemo(() => {
        return (
            <>
                <Item>
                    <Title admin={true}>
                        {t("goods.updateHistory")}
                        &nbsp;&nbsp;&nbsp;
                        <WhiteButton onClick={handleDialogShow}>메일 발송</WhiteButton>
                    </Title>
                    <Value>
                        <Flex>
                            <Input
                                value={name}
                                placeholder={t("goods.fileNameUpdateHistory")}
                                onChange={(e) => { setName(e.target.value); }}
                            />
                            <BlackButton disabled={!name} onClick={onClick}>{t('goods.add')}</BlackButton>
                        </Flex>
                    </Value>
                </Item>
                <List setItem={setItem} items={productData.updateHistory} onClick={onClickDeleteButtonTag} />
            </>
        );
    }, [name, productData.updateHistory, isDialogShow]);
    
    return (
        <>
            { (productData.docId && productData.godoGoodsNo && productData.updateHistory && productData.title) &&
                <Dialog 
                    show={isDialogShow} 
                    docId={productData.docId}
                    goodsNo={productData.godoGoodsNo} 
                    productName={productData.title} 
                    brandName={productData.brandName}
                    handleClose={handleDialogClose}
                />
            }
            { layout }
        </>
    )
});
