import { selector } from "recoil";
import { extensionState } from "..";

const extensionSelector = selector<number[]>({
  key: 'extensionSelector',
  get:({get}) => get(extensionState),
  set: ({set}, val) => set(extensionState, val),
});

export default extensionSelector;
