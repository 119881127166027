import React from "react";
import { useMutation } from "@apollo/client";
import { Input, Item, Title, Value } from "boards/DetailBoardWrite/style";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Flex, Wrap, List } from "./style";
import { BlackButton } from "boards/DetailBoardWrite/component/form";
import { CREATE_APPLICATION, UPDATE_APPLICATION } from "api/mutations";
import ApplicationListItem from "./applicationListItem";

/** 응용프로그램 리스트 추가를 위한 모달입니다. */
export default ({ allApplications, onClose, isShown, refetch }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [isError, setIsError] = useState(false);

  const [createApplication] = useMutation(CREATE_APPLICATION);
  const [updateApplication] = useMutation(UPDATE_APPLICATION);

  // 추가 버튼 클릭
  const handleAddButtonClick = async () => {
    // 이름이 중복되는 항목이 존재할 경우 오류
    if (allApplications.find((t) => t.label === name)) {
      setIsError(true);
      return;
    }
    // 새 응용프로그램 필드 생성
    await createApplication({
      variables: { CreateApplicationInput: { name } },
    });
    refetch(); // 데이터 재확보
    setName(""); // 이름 초기화
    setIsError(false); // 오류 숨김
  };

  // 항목 수정 버튼 클릭
  const handleUpdateButtonClick = async (id, name) => {
    await updateApplication({
      variables: { UpdateApplicationInput: { id, name } },
    });
    refetch(); // 데이터 재확보
  };

  return (
    <Wrap show={isShown} onHide={onClose}>
      <Modal.Body>
        <Item>
          <Title>{t("goods.addProductApplication")}</Title>
          <Value>
            <Flex>
              <Input
                value={name}
                placeholder={"응용프로그램 이름을 적어주세요!"}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleAddButtonClick();
                  }
                }}
              />
              <BlackButton disabled={!name} onClick={handleAddButtonClick}>
                {t("goods.add")}
              </BlackButton>
            </Flex>

            <Flex>
              <ErrorMessage show={isError}>
                이미 존재하는 응용프로그램 이름입니다!
              </ErrorMessage>
            </Flex>
          </Value>
        </Item>

        <List>
          {allApplications &&
            (allApplications || []).map((app) => (
              <ApplicationListItem
                appId={app.value}
                appName={app.label}
                onUpdate={handleUpdateButtonClick}
              />
            ))}
        </List>
      </Modal.Body>
    </Wrap>
  );
};
