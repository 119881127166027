import { Box } from '@mui/material';
import { AppContext } from 'app';
import { BlackButton } from 'boards/DetailBoardWrite/component/form';
import { STATUS_INPROGRESS } from 'boards/DetailBoardWrite/constants';
import { displaySelector, statusSelector, titleSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Field, Title, DivInput } from '../../style';

export default (({ isInProgress, onClickTranslateButton }) => {
    const { userInfo } = useContext(AppContext);
    const { isAdmin } = userInfo;
    const { docId } = useParams<{ docId?: string;  }>();
    const { t } = useTranslation();
    const [display] = useRecoilState(displaySelector);
    const [title, setTitle] = useRecoilState(titleSelector);
    const [status] = useRecoilState(statusSelector);

    // input 태그 변경 이벤트 처리기 메소드 
    const onChangeInput = e => {
        if (e.target.value.indexOf('\n') > -1)
            return false;

        setTitle(e.target.value);
    }
    const onKeyDown = e => {
        if (e.key === 'Enter') {
            return false;
        }
    };

    return (
        <Field>
            <Box display="flex" alignItems="center">
                <Title>{t("Title.label")}</Title>
                <Box ml="auto">
                    {isAdmin && 
                        <BlackButton 
                            disabled={!docId || status !== STATUS_INPROGRESS || isInProgress} 
                            onClick={onClickTranslateButton}
                            style={{
                                width: '100px',
                                height: '28px',
                                fontWeight: '400',
                            }}
                        >
                            컨텐츠 번역
                        </BlackButton>
                    }
                </Box>
            </Box>
            <DivInput
                maxLength={255}
                onKeyDown={onKeyDown}
                disabled={display.isDisp}
                placeholder={t('subject.placeholder')}
                onChange={onChangeInput}
                value={title}
            />
        </Field>
    );
});