import { selector } from "recoil";
import { dynamicItemState } from "..";

const dynamicItemSelector = selector<string>({
  key: 'dynamicItemSelector',
  get:({get}) => get(dynamicItemState),
  set: ({set}, val) => set(dynamicItemState, val),
});

export default dynamicItemSelector;
