import { selector } from "recoil";
import { contentsState } from "..";

const contentsSelector = selector<string>({
  key: 'contentsSelector',
  get:({get}) => get(contentsState),
  set: ({set}, val) => set(contentsState, val),
});

export default contentsSelector;
