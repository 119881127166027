import { displaySelector, dynamicItemSelector } from 'boards/DetailBoardWrite/recoil/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Item, Title, Input, Value } from '../../style';

export default (() => {
    // 번역도구 
    const { t } = useTranslation();
    const [display] = useRecoilState(displaySelector);
    const [dynamicItem, setDynamicItem] = useRecoilState(dynamicItemSelector);

    return (
        <Item>
            <Title>{t("goods.dynamics")}</Title>
            <Value>
                <Input
                    disabled={display.isDisp}
                    placeholder={t('goods.dynamicsPlaceholder')}
                    value={dynamicItem}
                    onChange={(e) => {
                        setDynamicItem(e.target.value);
                    }}
                />
            </Value>
        </Item>
    );
});