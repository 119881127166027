import { selector } from "recoil";
import { displayState } from "..";
import { DisplayType } from "../types";

const displaySelector = selector<DisplayType>({
  key: 'displaySelector',
  get:({get}) => get(displayState),
  set: ({set}, val) => set(displayState, val),
});

export default displaySelector;
