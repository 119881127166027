import { DefaultValue, selector } from "recoil";
import { subImageState } from "..";
import { SubImageType } from "../types";

const subImageSelector = selector<SubImageType>({
  key: 'subImageSelector',
  get:({get}) => get(subImageState),
  set: ({get, set}, val) => {
    if (!(val instanceof DefaultValue)) {
      const { subImage1, subImage2, subImage3 } = val;
      const image = get(subImageState);
      return set(subImageState, {
        subImage1: {
          ...image.subImage1,
          ...subImage1,
        },
        subImage2: {
          ...image.subImage2,
          ...subImage2,
        },
        subImage3: {
          ...image.subImage3,
          ...subImage3,
        },
      })
    }
  },
});

export default subImageSelector;
