import React from 'react';
import { Modal } from 'react-bootstrap';
import parse from 'html-react-parser';
import './ConfirmAlertModal.scss';
import { useTranslation } from 'react-i18next';

export default React.memo(function (props) {
    let {
        cancelText,
        confirmText,
        title,
        contents,
        onClose,
        onConfirm,
        show,
        btnType,
        alignCenter,
        textType,
        customBody,
        popupId,
        showButtons
    } = props

    const { t } = useTranslation()

    const Body = customBody || ( () => { return (<>{parse(contents)}</>) } )

    const cancelBtn = cancelText? (
        <button className="cancelBtn" onClick={onClose}>
            {t(cancelText)}
        </button>
    ) : null

    const onClickConfirmButton = () => {
        if (inputElement) inputElement.checked = false
        onConfirm()
    }

    const confirmBtn = confirmText? (
        <button className="confirmBtn" onClick={onClickConfirmButton}>
            {t(confirmText)}
        </button>
    ) : null

    alignCenter = alignCenter? "alignCenter" : ""

    textType = textType || ""

    const className = "confirmAlertModal " + alignCenter + " " + textType

    const onClickHidePopup = (event) => {
        if (popupId) {
            console.log(popupId)
            const popupCache = JSON.parse(localStorage.getItem("popups_hide_cache")) || {}
            if (event.target.checked) {
                popupCache[popupId] = new Date().getTime()
                localStorage.setItem("popups_hide_cache", JSON.stringify(popupCache))
            } else {
                popupCache[popupId] = null
                localStorage.setItem("popups_hide_cache", JSON.stringify(popupCache))
            }
        }
    }

    let inputElement
    
    const allowHide = popupId? (
        <div className="allowHide-container">
            <span>{t('allowHide')}</span>
            <input type="checkbox" onClick={onClickHidePopup} ref={(e) => { inputElement = e }}/>
        </div>
    ) : null

    return (
        <Modal className={className} show={show} onHide={onClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Body />
            </Modal.Body>
            {
                !showButtons ? null :
                <Modal.Footer className={btnType}>
                    {cancelBtn}
                    {confirmBtn}
                    {allowHide}
                </Modal.Footer>
            }
        </Modal>
    )
})