import React from 'react';
import CosmicDealWrite from '../cosmicDeal/write';
import CometDealWrite from '../cometDeal/write';

export default ((props) => {
    const { match } = props;
    // 현재 페이지 타입을 가져옵니다.
    const promotionDisplayLocation = match.params.displayLocation || "cosmic-deal";

    return (
        <>
            {
                promotionDisplayLocation === 'cosmic-deal' &&
                <CosmicDealWrite {...props} />
            }
            {
                promotionDisplayLocation === 'comet-deal' &&
                <CometDealWrite {...props} />
            }
        </>
    );
});